import { mutatorAction } from 'satcheljs';
import type {
    AccountSource,
    AdditionalAccountInfo,
    M365UserMailboxAccountSource,
    LoadState,
} from '../store/schema/AccountSourceList';
import setM365UserMailboxAdditionalSources from '../utils/setM365UserMailboxAdditionalSources';

function getSharedInfo(account: M365UserMailboxAccountSource): AdditionalAccountInfo {
    return account.sharedSources;
}

function updateSharedInfo(
    account: M365UserMailboxAccountSource,
    loadState: LoadState,
    sources: AccountSource[]
) {
    account.sharedSources.loadState = loadState;
    account.sharedSources.sources = sources;
}

export default mutatorAction(
    'SET_SHARED_SOURCES',
    function (coprincipalSourceId: string, sharedAccountInfo: AdditionalAccountInfo): void {
        setM365UserMailboxAdditionalSources(
            'Shared Mailbox',
            coprincipalSourceId,
            sharedAccountInfo,
            getSharedInfo,
            updateSharedInfo
        );
    }
);
