import type { AppInsightsCore, IExtendedTelemetryItem } from '@microsoft/1ds-core-js';
import { hasQueryStringParameter } from 'owa-querystring';
import { isGulpOrBranching } from 'owa-config';

const datapointQueryStringParam = 'dp';
const logToAriaSqueryStringParam = 'teAnLoc';

export function shouldLogToAria() {
    return !isGulpOrBranching() || hasQueryStringParameter(logToAriaSqueryStringParam);
}

export function shouldLogToConsole() {
    return hasQueryStringParameter(datapointQueryStringParam);
}

export function logToTenant(
    appInsightsCore: AppInsightsCore | undefined,
    event: IExtendedTelemetryItem
) {
    if (!appInsightsCore) {
        throw new Error('AppInsightsCore is not initialized in OWA Analytics Lite');
    }

    if (shouldLogToAria()) {
        appInsightsCore.track(event);
    }

    if (shouldLogToConsole()) {
        logEventToConsole(event);
    }
}

function logEventToConsole(event: IExtendedTelemetryItem) {
    /* eslint-disable-next-line no-console  -- (https://aka.ms/OWALintWiki)
     * This is a debugging tool that developers use to test if there datapoints are getting logged */
    console.group('AnalyticsLite Event:' + event.name);
    /* eslint-disable-next-line no-console  -- (https://aka.ms/OWALintWiki)
     * This is a debugging tool that developers use to test if there datapoints are getting logged */
    console.log('Properties:' + JSON.stringify(event.data));
    /* eslint-disable-next-line no-console  -- (https://aka.ms/OWALintWiki)
     * This is a debugging tool that developers use to test if there datapoints are getting logged  */
    console.groupEnd();
}
