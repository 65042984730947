import type { RequestOptions } from './RequestOptions';
import type MailboxRequestOptions from './MailboxRequestOptions';
import isExplicitLogonRequest from './isExplicitLogonRequest';

/**
 * Returns true if the request is explicit logon request, false otherwise.
 */
export default function isAuthRetriableForRequest(
    requestOptions: RequestOptions | MailboxRequestOptions | undefined
) {
    if (isExplicitLogonRequest(requestOptions)) {
        // only retry auth for explicit logon if the option is set in the request options
        return !!requestOptions?.retryAuthForExplicitLogon;
    }

    return true;
}
