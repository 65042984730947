const WindowHeightBuffer = 250;
const ReadingPaneOffHeighToMessageRatio = 29;
const MinimumMessageCount = 10;
const MaximumMessageCount = 25; // keep in sync with TableLoadingConstants.ts

export function getMessageCount(windowHeight: number): number {
    const messageCount = Math.ceil(
        (windowHeight + WindowHeightBuffer) / ReadingPaneOffHeighToMessageRatio
    );
    return Math.min(Math.max(messageCount, MinimumMessageCount), MaximumMessageCount);
}
