import type { BootResult } from 'owa-shared-start-types';
import { getConfig } from 'owa-service/lib/config';
import { type ErrorRetryStrategy } from './interfaces/ErrorRetryStrategy';
import type RetryStrategy from './interfaces/RetryStrategy';
import { TEN_SECONDS_IN_MS } from './timeConstants';
import { languagePostOnBootError } from './languagePostOnBootError';

export function getStartupDataRetryStrategy(bootResult: BootResult): RetryStrategy | null {
    switch (bootResult) {
        case 'langtz':
            return {
                strategy: '1',
                waitFor: languagePostOnBootError(),
            };
        case 'auth':
            return getConfig().getAuthToken ? { strategy: '1' } : null;
        case 'throttle': // If the user is being throttled so we will not retry to help with the throttling
        case 'errorfe': // If there is a redirect url, we  will redirect instead of retrying
        case 'accessDenied': // If the user does not have access to this mailbox, he will not be able to try later
        case 'configuration': // If the user has a configuration problem, then retrying won't help
            // if the user returned an error we know, we don't need to retry
            return null;
        case 'transient':
            return {
                strategy: '1',
                timeout: TEN_SECONDS_IN_MS,
            };
        default:
            return null;
    }
}
