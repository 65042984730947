/* eslint-disable no-restricted-properties -- (https://aka.ms/OWALintWiki)
 * This wrapper is provider so everyone has to come through owa-local-storage */

export function setItem(windowObj: Window, key: string, value: string): void {
    if (sessionStorageExists(windowObj)) {
        try {
            windowObj.sessionStorage.setItem(key, value);
        } catch (e) {
            // suppress quota exception
        }
    }
}

export function getItem(windowObj: Window, key: string): string | null {
    if (sessionStorageExists(windowObj)) {
        return windowObj.sessionStorage.getItem(key);
    }
    return null;
}

export function removeItem(windowObj: Window, key: string): void {
    if (sessionStorageExists(windowObj)) {
        return windowObj.sessionStorage.removeItem(key);
    }
}

export function clearSessionStorage(windowObj: Window): void {
    if (sessionStorageExists(windowObj)) {
        return windowObj.sessionStorage.clear();
    }
}

export function getSessionStorage(windowObj: Window): Storage {
    return windowObj.sessionStorage;
}

export function sessionStorageExists(windowObj: Window): boolean {
    try {
        return windowObj && !!windowObj.sessionStorage;
    } catch {
        return false;
    }
}

/* eslint-enable no-restricted-properties */
