import getGlobalSettingsAccountKey from '../selectors/getGlobalSettingsAccountKey';
import { isSameCoprincipalAccountByAccountKeys } from 'owa-client-types';
import type { AccountKey } from 'owa-client-types';

/**
 * Helps to tell if the accountKey is from the global settings account
 * @param thorwIfNotInitialized Set to true if this function should throw if the global settings account key cannot be obtained
 * @returns false if not from the same account or if there is no global settings account.
 */
export default function IsKeySameAccountAsGlobalSettingsAccount(
    accountKey: AccountKey,
    throwIfNotInitialized: boolean = false
): boolean {
    return isSameCoprincipalAccountByAccountKeys(
        accountKey,
        getGlobalSettingsAccountKey(throwIfNotInitialized)
    );
}
