import type { BootError, ErrorSource } from 'owa-shared-start-types';

export default function createBootError(
    input: BootError | Response | string,
    source: ErrorSource,
    url?: string,
    status?: number
): BootError {
    const error: BootError = !!(window.Response && input instanceof window.Response)
        ? createBootErrorFromResponse(input)
        : typeof input === 'string'
        ? /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
           * Error constructor names can only be a string literals.
           *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
          new Error(input)
        : (input as BootError);

    // don't override the source if we already have one
    addIfNotNull(error, 'source', source);
    addIfNotNull(error, 'url', url);
    addIfNotNull(error, 'status', status);
    return error;
}

function createBootErrorFromResponse(response: Response): BootError {
    const e: BootError = new Error('BootErrorFromResponse');
    e.response = response;
    return e;
}

function addIfNotNull<TKey extends keyof BootError, TValue extends BootError[TKey]>(
    error: BootError,
    column: TKey,
    value: TValue
) {
    if (error[column] === undefined) {
        error[column] = value;
    }
}
