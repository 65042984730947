import type { ServiceRequestConfig } from './config';
import type { MailboxInfo } from 'owa-client-types';
import { logStartGreyError } from 'owa-analytics-start/lib/logStartUsage';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Deprecating getPhysicalRing.ts
 *	> 'isDogfoodEnv' import from 'owa-metatags' is restricted. This value is resolved in ECS as a filter. Please create a feature flight if possible. */
import { isDogfoodEnv } from 'owa-metatags';

/**
 * Logs information about the MailboxInfo provided, and if it has non empty values of for
 * the required MailboxInfo properties and if it is a MailboxInfo from the account source
 * list store.
 *
 * @param config Service configuration used
 * @param name Name of the usage event
 * @param mailboxInfo MailboxInfo for which information is to be logged
 */
export default function checkAndLogMailboxInfo(
    config: ServiceRequestConfig,
    name: string,
    mailboxInfo?: MailboxInfo
) {
    // This telemetry is intended to provide verbose logging for all requests which will generate a large number
    // of events, this must not happen outside of the dogfood environment

    // Supporting multiple accounts requires that all of the service configuration requests include MailboxInfo
    // to make sure they are correctly routed.
    if (isDogfoodEnv() && config.isFeatureEnabled?.('acct-mailboxinfo-innerring-telemetry')) {
        const isMailboxInfoProvided =
            !!mailboxInfo?.type && !!mailboxInfo?.userIdentity && !!mailboxInfo?.mailboxSmtpAddress;
        const isFromStore =
            isMailboxInfoProvided && !!mailboxInfo?.sourceId && !!mailboxInfo?.mailboxRank;

        if (!isMailboxInfoProvided || !isFromStore) {
            /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
             * Error constructor names can only be a string literals.
             *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
            const error = new Error(name);
            /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
             * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
             *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
            logStartGreyError(name, error, {
                provided: isMailboxInfoProvided,
                store: isFromStore,
                type: mailboxInfo?.type,
                userId: typeof mailboxInfo?.userIdentity,
                smtp: typeof mailboxInfo?.mailboxSmtpAddress,
                sourceId: typeof mailboxInfo?.sourceId,
                rank: typeof mailboxInfo?.mailboxRank,
            });
        }
    }
}
