// Identifies the type of data that is associated with the boot state
export enum BootStateDataType {
    None = 'None',
    PreRenderData = 'PreRenderData',
    PreRenderError = 'PreRenderError',
    StartupData = 'StartupData',
}

// Base interface for all BootStateData structures
export interface BootStateData {
    type: BootStateDataType;
}
