import { addToTimingMap } from './utils/timingMap';

let eventTimeToSourceMapping: Map<number, string> = new Map();

export function getEventTimeMapping(startTime: number): string | undefined {
    return eventTimeToSourceMapping.get(startTime);
}

export function resetEventTimeToSourceMapping() {
    eventTimeToSourceMapping = new Map();
}

export function addEventTimeToSourceMapping(evt: Event, source: string) {
    const value = `${evt.type}:${source}`;
    addToTimingMap('eventListener', value);
    eventTimeToSourceMapping.set(evt.timeStamp, value);
}
