import { mutatorAction } from 'satcheljs';
import getAccountBySourceId from '../selectors/getAccountBySourceId';
import { accountRankTypeChecker } from '../store/schema/AccountSourceList';

export default mutatorAction(
    'removeEasidAsLicensingMailboxInfo',
    function (sourceId: string): void {
        const accountSource = getAccountBySourceId(sourceId);
        if (accountRankTypeChecker.isCoprincipal(accountSource)) {
            accountSource.licensingMailboxInfo = undefined;
        }
    }
);
