import type { IExtendedTelemetryItem, AppInsightsCore } from '@microsoft/1ds-core-js';
import { initializeOneDSCore, shouldLogToAria, shouldLogToConsole } from 'owa-analytics-lite';

const THREE_SECONDS_IN_MS = 3000;
let ariaTenantToken: string | undefined;

export let appInsightsCore: AppInsightsCore | undefined;

export function setAriaTenantToken(token: string | undefined) {
    ariaTenantToken = token;
}

export function initializeAppInsights() {
    if (ariaTenantToken) {
        appInsightsCore = initializeOneDSCore(ariaTenantToken);
    }
}

export function getAppInsightsCore() {
    return appInsightsCore;
}

function logEventToConsole(event: IExtendedTelemetryItem) {
    // This is a debugging tool that developers use to test if there datapoints are getting logged
    const eventGroupDescription =
        `${event.name}, ` +
        `${safeStringify(event?.data?.ErrorSource || '')}, ` +
        `${safeStringify(event?.data?.ErrorType || '')}`;
    /* eslint-disable-next-line no-console  -- (https://aka.ms/OWALintWiki)
     * Baseline legacy exception from before rule was added. Do not copy/paste this justification.
     *	> Unexpected console statement. */
    console.group('BootEvent: ' + eventGroupDescription);
    /* eslint-disable-next-line no-console  -- (https://aka.ms/OWALintWiki)
     * Baseline legacy exception from before rule was added. Do not copy/paste this justification.
     *	> Unexpected console statement. */
    console.log('Properties:' + safeStringify(event.data));
    /* eslint-disable-next-line no-console  -- (https://aka.ms/OWALintWiki)
     * Baseline legacy exception from before rule was added. Do not copy/paste this justification.
     *	> Unexpected console statement. */
    console.groupEnd();
}

export function postSignal(
    lazyEvents: Promise<IExtendedTelemetryItem[]>,
    customTimeout?: number
): Promise<any> {
    if (!ariaTenantToken) {
        return Promise.resolve();
    }

    return new Promise<void>(async resolve => {
        const postTimeout = window.setTimeout(resolve, customTimeout || THREE_SECONDS_IN_MS);

        try {
            const events = await lazyEvents;
            if (!getAppInsightsCore()) {
                initializeAppInsights();
            }

            if (events) {
                for (const event of events) {
                    /*
                     * It's important to log to console after event is sent because `logEvent` modifies
                     * properties of the event.
                     * Logging after sending will allow to see exact data how it was sent to Aria
                     */
                    if (shouldLogToAria()) {
                        getAppInsightsCore()?.track(event);
                    }
                    if (shouldLogToConsole()) {
                        logEventToConsole(event);
                    }
                }
            }
        } catch {
            // drop instrumentation errors
        } finally {
            window.clearTimeout(postTimeout);
            resolve();
        }
    });
}

function safeStringify(input: any): string {
    try {
        return JSON.stringify(input);
    } catch (e) {
        return e.message;
    }
}

export function resetAppInsightsCoreForTests() {
    appInsightsCore = undefined;
}
