export function shouldOverrideCDN(attempt: number, maxAttempts: number): boolean {
    return maxAttempts - attempt < Math.floor(maxAttempts / 2);
}

/**
 * This function is used to determine if we should override the query string when retrying a request.
 * We should override the query string if the attempt is greater than 1 and the number of remaining attempts is not equal to half of the total attempts.
 * This is to prevent the query string from being overridden on the first attempt on a backup CDN.
 * @param attempt
 * @param maxAttempts
 */
export function shouldOverrideQuery(attempt: number, maxAttempts: number): boolean {
    return attempt > 1 && maxAttempts - attempt + 1 !== Math.floor(maxAttempts / 2);
}
