import getRootVdirName from 'owa-url/lib/getRootVdirName';
import { isBusinessDomain } from '../isMsalEnabledForBusiness';
import { OWA_BASE_PATH, CONSUMER_OWA_BASE_PATH } from './constants';
import { getHostLocation } from 'owa-url/lib/hostLocation';
import { getQueryStringParameters, stringify } from 'owa-querystring';

let loginHint: string | undefined = undefined;

export function getAadOrMsaRedirectUri() {
    const origin = isBusinessDomain() ? CONSUMER_OWA_BASE_PATH : OWA_BASE_PATH;
    const loc = getHostLocation();
    const query = getQueryStringParameters(loc);
    const redirectUri = `${origin}/${getRootVdirName()}/`;

    if (loginHint) {
        query.login_hint = loginHint;
    }

    const queryString = Object.keys(query).length > 0 ? '?' + stringify(query) : '';
    return redirectUri + queryString;
}

export function setLoginHintForAadOrMsaRedirect(msalLoginHint?: string) {
    loginHint = msalLoginHint;
}
