import { getOwaCanaryCookie, getOwaCanaryDebugCookie } from './canary';
import { getConfig } from './config';
import type { HeadersWithoutIterator } from './RequestOptions';

export function setCanaryHeader(headers: HeadersWithoutIterator) {
    const canaryHeaders = getCanaryHeaders();
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    Object.keys(canaryHeaders).forEach(key => {
        headers.set(key, canaryHeaders[key]);
    });
}

export function getCanaryHeaders(): {
    [key: string]: string;
} {
    // the worker provides an abstraction over getCanaryHeaders that immediately returns the current impl, but also refreshes.
    return getConfig().getCanaryHeaders?.() || getCanaryHeadersImpl();
}

// the only caller(s) of this should be from implementations of the service config abstraction
export function getCanaryHeadersImpl(): {
    [key: string]: string;
} {
    const headers: Record<string, string> = {
        'X-OWA-CANARY': getOwaCanaryCookie(true),
    };

    const owaCanaryDebugCookie = getOwaCanaryDebugCookie();
    if (owaCanaryDebugCookie) {
        headers['X-OWA-CANARY-DEBUG'] = owaCanaryDebugCookie;
    }

    return headers;
}
