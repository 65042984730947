// import { Client } from 'oribuild/client';

// const client = new Client();

export function logOriUserMetric(_opts: { metric: 'plt' | 'tti'; value: number }) {
    // client.logUserMetric(opts);
}

export function logOriError(_opts: { message: string; stack: string }) {
    // client.logRuntimeError(opts);
}
