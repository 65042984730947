export enum DatapointStatus {
    Success = 'Success',
    ServerError = 'ServerError',
    UserError = 'UserError',
    UserCancelled = 'UserCancelled',
    ServerExpectedError = 'ServerExpectedError',
    ClientError = 'ClientError',
    RequestNotComplete = 'RequestNotComplete',
    Timeout = 'Timeout',
    BackgroundSuccess = 'BackgroundSuccess',
    AuthError = 'AuthError',
    PartnerError = 'PartnerError',
}
