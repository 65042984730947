import type Capability from '../schema/Capability';
import type { MailboxInfo } from 'owa-client-types';

export function isCapabilityEnabled(capability: Capability, mailboxInfo?: MailboxInfo): boolean {
    const isSupported = capability.isSupported(mailboxInfo);

    return isSupported && capability.isEnabled(mailboxInfo);
}

export function isCapabilitySupported(capability: Capability, mailboxInfo?: MailboxInfo): boolean {
    return capability.isSupported(mailboxInfo);
}
