export default function getUrlWithAddedQueryParameters(
    url: string,
    params: {
        [key: string]: string;
    },
    ignoreIfAlreadyPresent?: boolean
) {
    params = params || {};
    const keys = ignoreIfAlreadyPresent
        ? Object.keys(params).filter(p => url.indexOf(`&${p}=`) < 0 && url.indexOf(`?${p}=`) < 0)
        : Object.keys(params);

    if (keys.length == 0) {
        return url;
    }

    const hasExistingQuery = url.indexOf('?') !== -1;
    url += hasExistingQuery ? '&' : '?';

    const encodedParams = keys
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&');

    return url + encodedParams;
}
