const SWHeaderPrefix = 'x-sw-';

export const VersionHeader = `${SWHeaderPrefix}version` as const;
export const CacheHeader = `${SWHeaderPrefix}cache` as const;
export const ActiveCacheHeader = `${SWHeaderPrefix}active-cache` as const;
export const CacheResultHeader = `${SWHeaderPrefix}cache-result` as const;
export const CacheVersionHeader = `${SWHeaderPrefix}cache-version` as const;
export const CacheAgeHeader = `${SWHeaderPrefix}cache-age` as const;
export const CacheHostHeader = `${SWHeaderPrefix}cache-host` as const;
export const ProxyTypeHeader = `${SWHeaderPrefix}proxy-type` as const;
