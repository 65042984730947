import {
    getApp,
    getClientId,
    getClientVersion,
    getLogicalRing,
    getSessionId,
    getLayout,
    /* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
     * Remove reference to getHostValue
     *	> 'getHostValue' import from 'owa-config' is restricted. Please use isFeatureEnabled/isBootFeatureEnabled instead. Host value is checked with ECS/CT as a filter. */
    getHostValue,
    scrubForPii,
    /* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
Suppressed as a legitimate use case for getting the native host version*/
    getNativeHostVersion,
} from 'owa-config';
import {
    getBackend,
    getDag,
    getForest,
    getFrontend,
    getServerVersion,
    getThroughEdge,
} from 'owa-config/lib/envDiagnostics';
import getRefUrl from './utils/getRefUrl';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Deprecating getPhysicalRing.ts
 *	> 'getPhysicalRing' import from 'owa-metatags' is restricted. This value is resolved in ECS as a filter. Please create a feature flight if possible.
 *	> 'getVariantEnvironment' import from 'owa-metatags' is restricted. This value is resolved in ECS as a filter. Please create a feature flight if possible. */
import { getPhysicalRing, getVariantEnvironment } from 'owa-metatags';
import { isExplicitLogonSession } from 'owa-config/lib/isExplicitLogonSession';
import type { IExtendedTelemetryItem } from '@microsoft/1ds-core-js';
import { getBrowserInfo, getOsInfoWithoutHints } from 'owa-user-agent';
import {
    getPuid,
    getTenantGuid,
    getMailboxGuid,
    getPremiumUser,
    isConsumer,
} from './sessionDiagnostics';
import type ErrorDiagnostics from './interfaces/ErrorDiagnostics';
import { getQueryStringParameter } from 'owa-querystring';
import { getCores } from './utils/getCores';

export function createBaseEvent(
    name: string,
    errorDiagnostics: ErrorDiagnostics | undefined,
    appOverride?: string
): IExtendedTelemetryItem {
    const browserInfo = getBrowserInfo();
    const osInfo = getOsInfoWithoutHints();
    const deviceInfo: Record<string, string | number> = { cores: getCores() };
    const nav = <any>navigator;
    const connection = nav.connection || nav.mozConnection || nav.webkitConnection;
    if (connection) {
        deviceInfo.effectiveType = connection.effectiveType;
        deviceInfo.downlink = connection.downlink;
        deviceInfo.rtt = connection.rtt;
    }

    const data: {
        [index: string]: string | boolean | number | undefined | null;
    } = {
        AppName: appOverride ?? getApp(),
        UserAgent: window.navigator.userAgent,
        ExplicitLogon: isExplicitLogonSession(window),
        Puid: getPuid(),
        TenantGuid: getTenantGuid(),
        MBXGuid: getMailboxGuid(),
        IsPremium: getPremiumUser(),
        ClientId: getClientId(),
        RefUrl: getRefUrl(),
        ClientVersion: getClientVersion(),
        BEServer: errorDiagnostics?.ebe || getBackend(),
        FEServer: errorDiagnostics?.efe || getFrontend(),
        ThroughEdge: getThroughEdge(),
        ServiceVersion: getServerVersion(),
        Dag: getDag(),
        Forest: getForest(),
        DeployRing: getLogicalRing(),
        'Session.Id': getSessionId(),
        PhysicalRing: getPhysicalRing(),
        VariantEnv: getVariantEnvironment(),
        BrowserName: browserInfo.browser,
        Host: getHostValue(),
        HostVersion: getNativeHostVersion(),
        BrowserVersion: browserInfo.browserVersion.toString(),
        OsName: osInfo.os,
        OsVersion: osInfo.osVersion,
        RetryStrategy: getQueryStringParameter('bO'),
        IsConsumer: isConsumer(),
        Layout: getLayout(),
        DeviceInfo: JSON.stringify(deviceInfo),
    };

    if (errorDiagnostics) {
        data.ErrorType = errorDiagnostics.et;
        data.ErrorSource = errorDiagnostics.esrc;
        data.Error = errorDiagnostics.err;
        data.ExtraErrorInfo = scrubForPii(errorDiagnostics.estack);
        data.StatusCode = errorDiagnostics.st;
        data.RequestId = errorDiagnostics.reqid;
    }

    return { name, data: <
            {
                [index: string]: string | boolean;
            }
        >data };
}
