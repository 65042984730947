import type { CustomDataMap } from 'owa-analytics-types';

type BootCustomDataMap = {
    SessionDataNetworkRace: CustomDataMap;
    HubLoadData: {
        userClickTime: number;
    };
    MetaOsAppLoaded: number;
    getCalendarViewParams: string;
    MetaOSAppCached: boolean;
    MetaOsBackgroundLoad: boolean;
    MetaOsAppRingId: string;
    MetaOsAppHostName: string;
    HostSessionId: string;
    MetaOsUserInfoUPNDiffLH: boolean;
    OfflineSessionDataHasEventsRange: boolean;
    OfflineSessionDataEventsRangeMatchesRequested: boolean;
    MetaOsAppHostType: string;
    OWAAuthFailedFromMetaOsHub: boolean;
};

type BootCustomDataMapKeys = keyof BootCustomDataMap;
const bootCustomData: Partial<BootCustomDataMap> = {};

export function addBootCustomData<K extends BootCustomDataMapKeys>(
    key: K,
    data: BootCustomDataMap[K]
): void {
    bootCustomData[key] = data;
}

export function getBootCustomData(): Partial<BootCustomDataMap> {
    return bootCustomData;
}
