import { isBootFeatureEnabled } from 'owa-metatags/lib/isBootFeatureEnabled';
import { setDisableAllRequests } from './disableRequestsWhenOffline';

export function isOfflineBootEnabled() {
    return isBootFeatureEnabled('offlineWeb') || isNetworkBlocked();
}

export let isNetworkManuallyBlocked: boolean | undefined;

// This allows us to force the app offline for testability
export function setIsNetworkManuallyBlocked(value: boolean) {
    isNetworkManuallyBlocked = value;
    setDisableAllRequests(value);
}

export function isNetworkBlocked() {
    if (isNetworkManuallyBlocked === undefined) {
        isNetworkManuallyBlocked = isBootFeatureEnabled('dev-bootOffline');
    }

    return isNetworkManuallyBlocked;
}
