import { markFunction } from 'owa-performance';
import { LazyBootModule, LazyAction } from 'owa-bundling-light';

const lazyModule = new LazyBootModule(
    () => import(/* webpackChunkName: "AppBootMetaOs" */ './lazyFunctions')
);

export const lazyInitializeMetaOsAppSdkProxy = new LazyAction(lazyModule, m =>
    markFunction(
        m.lazyInitializeMetaOsAppSdk.importAndExecute,
        'MetaOSPlatformAppBootImportAndExecuteScript'
    )
);

export const lazyGetOwaAuthTokenFromMetaOsHubProxy = new LazyAction(
    lazyModule,
    m => m.lazyGetOwaAuthTokenFromMetaOsHub.importAndExecute
);

export const lazyInitializeMetaOsCapabilitiesProxy = new LazyAction(
    lazyModule,
    m => m.lazyInitializeMetaOsCapabilities.importAndExecute
);

export const lazyGetContextProxy = new LazyAction(
    lazyModule,
    m => m.lazyGetContext.importAndExecute
);

export const lazyRegisterOnLoadHandlerProxy = new LazyAction(
    lazyModule,
    m => m.lazyRegisterOnLoadHandler.importAndExecute
);

export const lazyRegisterBeforeUnloadHandlerProxy = new LazyAction(
    lazyModule,
    m => m.lazyRegisterBeforeUnloadHandler.importAndExecute
);

export const lazyGetOwaDecodedAuthTokenFromMetaOsHubProxy = new LazyAction(
    lazyModule,
    m => m.lazyGetOwaDecodedAuthTokenFromMetaOsHub.importAndExecute
);

export const lazyPreInitOwaAccountSourceListStoreProxy = new LazyAction(
    lazyModule,
    m => m.lazyPreInitOwaAccountSourceListStore.importAndExecute
);

export const lazyGetAccountServiceConfigProxy = new LazyAction(
    lazyModule,
    m => m.lazyGetAccountServiceConfig.importAndExecute
);

export const lazyNotifyAppLoadedProxy = new LazyAction(
    lazyModule,
    m => m.lazyNotifyAppLoaded.importAndExecute
);

export const lazyIsNAAChannelRecommendedProxy = new LazyAction(
    lazyModule,
    m => m.lazyIsNAAChannelRecommended.importAndExecute
);
