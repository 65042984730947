import getAccountSourceListStore from '../store/accountSourceListStore';

/**
 * The account source list store is considered initialized if: there is at least one
 * account in the store (that is we are not in the zero account state) and there is
 * a selected sourceId for the store. (Note, the second criteria will be dropped when
 * we move from Reflow to Respond)
 * @returns True if the store is initialized, false otherwise
 */
export default function isAccountSourceListStoreInitialized(): boolean {
    const store = getAccountSourceListStore();
    const hasSources = store.sources.length > 0;
    const hasGlobalAccount = !!getAccountSourceListStore().globalSettingsSourceId;
    return hasSources && hasGlobalAccount;
}
