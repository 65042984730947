import { mutatorAction } from 'satcheljs';
import type {
    AccountSource,
    AdditionalAccountInfo,
    M365PublicFolderMailboxAccountSource,
    M365UserMailboxAccountSource,
    LoadState,
} from '../store/schema/AccountSourceList';
import setM365UserMailboxAdditionalSources from '../utils/setM365UserMailboxAdditionalSources';

function getPublicFolderSources(account: M365UserMailboxAccountSource): AdditionalAccountInfo {
    return account.publicFolderSources;
}

function updatePublicFolderSources(
    account: M365UserMailboxAccountSource,
    loadState: LoadState,
    sources: AccountSource[]
) {
    account.publicFolderSources.loadState = loadState;
    account.publicFolderSources.sources = sources;
}

export default mutatorAction(
    'SET_PUBLIC_FOLDER_ACCOUNTS',
    function (
        coprincipalSourceId: string,
        loadState: LoadState,
        sources: M365PublicFolderMailboxAccountSource[]
    ): void {
        setM365UserMailboxAdditionalSources(
            'Public Folder',
            coprincipalSourceId,
            {
                coprincipalSourceId,
                loadState,
                sources,
            },
            getPublicFolderSources,
            updatePublicFolderSources
        );
    }
);
