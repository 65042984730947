import type { PerformanceDatapointType } from 'owa-analytics-types';

const pendingDatapoints: PerformanceDatapointType[] = [];

export function resolvePendingDatapoints() {
    for (const datapoint of pendingDatapoints) {
        datapoint.resolvePendingCallbacks();
    }
}

export function addPendingDatapoint(datapoint: PerformanceDatapointType) {
    pendingDatapoints.push(datapoint);
}
