/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * Make sure this isn't imported in the analytics worker
 *	> 'mobx' import is restricted from being used. Mobx is not allowed in the analytics worker [analytics-worker-lint] */
import { intercept } from 'mobx';
import { default as getAccountBySourceId } from '../selectors/getAccountBySourceId';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * Make sure this isn't imported in the analytics worker
 *	> 'mobx' import is restricted from being used. Mobx is not allowed in the analytics worker [analytics-worker-lint] */
import type { IValueWillChange } from 'mobx';
import { logGreyErrorFromAccounts } from 'owa-account-analytics';

/**
 * Interceptor handler for changes to the MailboxInfo, this will report that a
 * change happened and stop the change.
 */
function alertOnMailboxInfoModification<T>(
    _change: IValueWillChange<T>
): IValueWillChange<T> | null {
    // TODO: once the cal-event-merge-immutable-mailboxInfo is rolled out make this an alert again.
    // This is tracked by "Task 279110: Make AccountSourceMailboxInfoChanged an alert again"
    // errorThatWillCauseAlert('AccountSourceMailboxInfoChanged');

    const error = new Error('AccountSourceMailboxInfoChanged');
    logGreyErrorFromAccounts('AccountSourceMailboxInfoChanged', error);

    // The MailboxInfo should be immutable, so we should not allow changes to it.
    return null;
}

/**
 * The MailboxInfo of the account source is expected to be immutable, there have been issues
 * with the information in the MailboxInfo of the coprincipal account has been modified. To
 * make detecting these changes eaiser we add intercepts for the MailboxInfo and it's members.
 * If a change is made these will cause an alert to be raised.
 *
 * @param sourceId The sourceId of the coprincipal account to be made immutable
 */
export function interceptAndAlertOnMailboxInfoChanges(sourceId: string) {
    const account = getAccountBySourceId(sourceId);
    if (account) {
        intercept(account, 'mailboxInfo', alertOnMailboxInfoModification);
        intercept(account.mailboxInfo, 'type', alertOnMailboxInfoModification);
        intercept(account.mailboxInfo, 'userIdentity', alertOnMailboxInfoModification);
        intercept(account.mailboxInfo, 'mailboxSmtpAddress', alertOnMailboxInfoModification);
        if (account.mailboxInfo.auxiliaryMailboxGuid) {
            intercept(account.mailboxInfo, 'auxiliaryMailboxGuid', alertOnMailboxInfoModification);
        }

        intercept(account.mailboxInfo, 'sourceId', alertOnMailboxInfoModification);
        intercept(account.mailboxInfo, 'mailboxRank', alertOnMailboxInfoModification);
    }
}
