import {
    OUTLOOK_DESKTOP,
    MAC_OUTLOOK,
    IOS_OUTLOOK,
    ANDROID_OUTLOOK,
    TEAMS,
    TEAMS_HUB,
    O365_SHELL,
    WIDGET,
    WIN32_OUTLOOK_HUB,
    ANDROID_TEAMS,
    IOS_TEAMS,
    ANDROID_OUTLOOK_LITE,
    SHAREPOINT,
    OFFICE_DESKTOP,
    OFFICE_WEB,
} from './types/HostApp';

import { getWindowData } from 'owa-window-data';

import { getHostValue } from './getHostValue';

type SupportedHostApps =
    | typeof OUTLOOK_DESKTOP
    | typeof MAC_OUTLOOK
    | typeof IOS_OUTLOOK
    | typeof ANDROID_OUTLOOK
    | typeof TEAMS
    | typeof TEAMS_HUB
    | typeof O365_SHELL
    | typeof WIDGET
    | typeof WIN32_OUTLOOK_HUB
    | typeof ANDROID_TEAMS
    | typeof IOS_TEAMS
    | typeof ANDROID_OUTLOOK_LITE
    | typeof OFFICE_DESKTOP
    | typeof OFFICE_WEB
    | typeof SHAREPOINT;

const hostToEcsFilterMap = {
    [OUTLOOK_DESKTOP]: 'outlook_win32',
    [WIN32_OUTLOOK_HUB]: 'outlook_win32',
    [IOS_OUTLOOK]: 'outlook_ios',
    [ANDROID_OUTLOOK_LITE]: 'outlook_android',
    [ANDROID_OUTLOOK]: 'outlook_android',
    [MAC_OUTLOOK]: 'outlook_mac',
    [ANDROID_TEAMS]: 'teams_android',
    [IOS_TEAMS]: 'teams_ios',
    [TEAMS]: 'teams_desktop',
    [TEAMS_HUB]: 'teams_desktop',
    [SHAREPOINT]: 'sharepoint',
    [WIDGET]: 'windows',
    [O365_SHELL]: 'o365shell',
    [OFFICE_DESKTOP]: 'office_desktop',
    [OFFICE_WEB]: 'office_web',
};

export function getHostAppEcsFilter(): string {
    const hostApp = getHostValue() as SupportedHostApps;
    return hostToEcsFilterMap[hostApp];
}

export function getHostedUxEcsFilter() {
    const path = getWindowData().location ? getWindowData().location.pathname : '';
    return path.indexOf('/opx') >= 0 || path.indexOf('/hosted') >= 0 || getHostAppEcsFilter()
        ? 'true'
        : 'false';
}
