/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline - satchel stores in the worker will need their data synced
 *	> 'createStore' import from 'satcheljs' is restricted. Satchel stores should not be created in the worker as the data will need to be synced */
import { createStore } from 'satcheljs';
import type { AccountAndCoprincipalSourceId, AccountSourceList } from './schema/AccountSourceList';

export const defaultStore: AccountSourceList = {
    globalSettingsSourceId: '',
    sourcesBySourceId: new Map<string, AccountAndCoprincipalSourceId>(),
    sources: [],
    removedAccounts: [],
    licensingIdentities: [],
};

const store = createStore<AccountSourceList>('AccountSourceList', defaultStore)();
export default () => store;
