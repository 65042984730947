export const BASE_THEME_ID = 'base';
export const BASE_DARK_THEME_ID = `${BASE_THEME_ID}.dark`;
export const BASE_OFFICE_THEME_ID = 'basewithofficethemepreferred';
export const CONTRAST_THEME_ID = 'contrast';
export const LOCAL_STORAGE_KEY = 'UsersNormalizedTheme';
export const LOCAL_IMAGE_STORAGE_KEY = 'UsersNormalizedThemeImage';
export const OFFICE_NEUTRAL_THEME_ID = 'officeneutral';
export const MODERN_MOUNTAIN_THEME_ID = 'modernmountain';
export const MODERN_ARCTIC_SOLITUDE_THEME_ID = 'arcticsolitude';
export const MODERN_OFFICE_NEUTRAL_THEME_ID = 'modern_officeneutral';
export const COPILOT_CUSTOM_THEME_ID = 'copilotcustom';
export const COPILOT_LOCATION_THEME_ID = 'copilotlocation';
export const COPILOT_WEATHER_THEME_ID = 'copilotweather';

export const LEGACY_PRELOADED_THEME_IDS = [BASE_THEME_ID, BASE_DARK_THEME_ID];
