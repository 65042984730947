import type {
    DatapointOptions,
    LogUsageFunction,
    LogGreyErrorFunction,
    CustomDataMap,
    LogCoreUsageFunction,
    LogCoreGreyErrorFunction,
} from 'owa-analytics-types';
import type { AnalyticsCoreEventNames } from 'owa-analytics-events';

export interface StartUsage {
    name: string;
    customData?: CustomDataMap;
    options?: DatapointOptions;
}

export interface StartGreyError extends StartUsage {
    error?: Error;
}

let logUsageFunction: LogUsageFunction | undefined;
let logCoreUsageFunction: LogCoreUsageFunction | undefined;
let logGreyErrorFunction: LogGreyErrorFunction | undefined;
let logCoreGreyErrorFunction: LogCoreGreyErrorFunction | undefined;
const cachedStartUsage: StartUsage[] = [];
const cachedStartCoreUsage: StartUsage[] = [];
const cachedStartGreyError: (StartUsage & {
    error?: Error;
})[] = [];
const cachedStartCoreGreyError: (StartUsage & {
    error?: Error;
})[] = [];

export function logStartUsage(
    name: string,
    customData?: CustomDataMap,
    options?: DatapointOptions
) {
    if (logUsageFunction) {
        logUsageFunction(name, customData, options);
    } else {
        cachedStartUsage.push({ name, customData, options });
    }
}

export function logStartCoreUsage(
    name: AnalyticsCoreEventNames,
    customData?: CustomDataMap,
    options?: DatapointOptions
) {
    if (logCoreUsageFunction) {
        logCoreUsageFunction(name, customData, options);
    } else {
        cachedStartCoreUsage.push({ name, customData, options });
    }
}

export function logStartGreyError(
    name: string,
    error?: Error,
    customData?: CustomDataMap,
    options?: DatapointOptions
) {
    if (logGreyErrorFunction) {
        logGreyErrorFunction(name, error, customData, options);
    } else {
        cachedStartGreyError.push({ name, error, customData, options });
    }
}

export function logStartCoreGreyError(
    name: AnalyticsCoreEventNames,
    error?: Error,
    customData?: CustomDataMap,
    options?: DatapointOptions
) {
    if (logCoreGreyErrorFunction) {
        logCoreGreyErrorFunction(name, error, customData, options);
    } else {
        cachedStartCoreGreyError.push({ name, error, customData, options });
    }
}

export function initLogUsageFunc(func: LogUsageFunction): StartUsage[] {
    logUsageFunction = func;
    return cachedStartUsage;
}

export function initCoreLogUsageFunc(func: LogCoreUsageFunction): StartUsage[] {
    logCoreUsageFunction = func;
    return cachedStartCoreUsage;
}

export function initLogGreyErrorFunc(func: LogGreyErrorFunction): StartGreyError[] {
    logGreyErrorFunction = func;
    return cachedStartGreyError;
}

export function initCoreLogGreyErrorFunc(func: LogCoreGreyErrorFunction): StartGreyError[] {
    logCoreGreyErrorFunction = func;
    return cachedStartCoreGreyError;
}
