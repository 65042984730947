import type { CdnSettings } from './types/CdnSettings';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Deprecating getPhysicalRing.ts
 *	> 'getVariantEnvironment' import from 'owa-metatags' is restricted. This value is resolved in ECS as a filter. Please create a feature flight if possible. */
import { getVariantEnvironment, findMetatag } from 'owa-metatags';
import { isGulpOrBranching, isGulping } from './bootstrapOptions';
import { getQueryStringParameter } from 'owa-querystring';

// this function is used outside of client-web
let defaultCdnContainer = 'owamail/';
export function setCdnContainer(container: string) {
    defaultCdnContainer = container;
}

let cdnSettings: CdnSettings;
export function getDefaultCdnSettings(): CdnSettings {
    if (!cdnSettings) {
        const useBackup = getQueryStringParameter('bO') == '2';
        const cdnBaseUrl = getDefaultCdnUrl();
        let primaryCdnUrl = cdnBaseUrl + defaultCdnContainer;
        let backupUrl = getDefaultCdnUrl(true) + defaultCdnContainer;
        const baseMeta = findMetatag('baseUrl');
        if (isGulpOrBranching() && baseMeta) {
            primaryCdnUrl = backupUrl = baseMeta;
        }
        cdnSettings = {
            PackageBaseUrl: useBackup ? backupUrl : primaryCdnUrl,
            ExtraSettings: {},
            BackupBaseUrl: useBackup ? primaryCdnUrl : backupUrl,
            ResourcesPath: '',
            ScriptPath: isGulping() ? '' : 'scripts/',
        };
    }
    return cdnSettings;
}

export function getDefaultCdnUrl(isBackup?: boolean): string {
    const cdnNumber = isBackup ? 2 : 1;
    let isProdBuild = true;
    const scriptVer = findMetatag('scriptVer');
    if (scriptVer && scriptVer.indexOf('.') === -1) {
        isProdBuild = false;
    }
    const cdnWWName = isProdBuild ? `res-${cdnNumber}` : `res-${cdnNumber}-sdf`;
    return getVariantEnvironment() == 'Gallatin'
        ? `//outlook-${cdnNumber}.cdn.partner.outlook.cn/`
        : `//${cdnWWName}.cdn.office.net/`;
}
