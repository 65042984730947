import type MailboxInfo from './MailboxInfo';
import { isPersistenceIdIndexerEnabled } from './isPersistenceIdIndexerEnabled';
import { tryLookupIndexer } from './tryLookupIndexer';
import { coprincipalAccountIndexerFromSourceId } from './coprincipalAccountIndexerFromSourceId';
import type AccountKey from './AccountKey';
import { defaultAccountKey } from './AccountKey';

// DEPRECATED: Use defaultAccountKey instead
export const defaultIndexerValue: string = defaultAccountKey as string;

/**
 * ********ONLY VALID INSIDE ACCONT CODESPACE***********
 * Use 'getAccountKeyFromMailboxInfo()' when you are outside the account codespace.
 * WHY: We call it the indexer when it is used in the accounts or mailboxes map.
 * But when used to for account operations, we call it the account key.
 *
 *
 * Provides a string that can be used for in-memory indexer of MailboxInfo in a map or other
 * structure where data needs to be indexed based upon the data boundary of the coprincipal account.
 *
 * What is a data boundary and why is it important? When users have multiple accounts configured in
 * Outlook it is important that the information from each account is separated. For example, if a user
 * configured personal@example.com and work@example.com it would be important to keep the data from
 * personal and work seperate in order to avoid data bleed between these coprincipal accounts.
 *
 * In the above example, there are two coprinicpal accounts (personal@example.com and work@example.com)
 * and the code should seperate data that comes from each of these accounts, and any other mailboxes that
 * they may access (such as shared mailboxes or archive mailboxes) seperate in the stores of client-web.
 * Or in other words, if you configured each account in a seperate OWA instance, the data that would be
 * in each individual instance should if the two accounts are run seperately should be seperated using
 * the indexer value of the coprincipal account.
 *
 * The typical pattern for this is to store data using the data boundary indexder of the MailboxInfo as
 * the index value to a Map. This allows the data to be stored in a way that is unique to the coprincipal
 * account that contains the data and prevents data bleed between accounts.
 *
 * The following code for example shows how to store MyStoreData in a Map using the indexer value of the
 * mailboxInfo as the key to prevent data bleed between coprincipal accounts:
 *
 *    export const stores = createStore<MyStore>('myStoreName', {
 *          dataByIndexer: new ObservableMap()
 *       })();
 *
 *    export const getMyStore = (mailboxInfoInDataBoundry: MailboxInfo): MyStoreData => {
 *       const indexer = getIndexerValueForMailboxInfo(mailboxInfoInDataBoundry);
 *       let store = stores.dataByIndexer.get(indexer);
 *       if (!store) {
 *          store = createDefaultMyStoreData();
 *          stores.dataByIndexer.set(indexer, store);
 *       }
 *
 *       return store;
 *   };
 *
 * @param mailboxInfo MailboxInfo for which the data boundry indexer is to be returned
 * @returns Indexer for the data boundary of the MailboxInfo
 */
export default function getIndexerValueForMailboxInfo(
    mailboxInfoInDataBoundry: MailboxInfo
): AccountKey {
    if (isPersistenceIdIndexerEnabled()) {
        const indexerFromSourceId = coprincipalAccountIndexerFromSourceId(
            mailboxInfoInDataBoundry.sourceId
        );

        if (!!indexerFromSourceId) {
            return indexerFromSourceId as AccountKey;
        }

        // the tryLookupIndexer will lookup the indexer based on the user identity if the sourceId is not present
    }

    // We have had issues with MailboxInfo's not using the same userIdentity as the account source
    // list store, the tryLookupIndexer function will get the indexer including checking the aliases
    // of existing accounts. It will return undefined if it cannot determine the correct indexer to use.
    const indexer = tryLookupIndexer(mailboxInfoInDataBoundry);
    return (indexer ??
        (isPersistenceIdIndexerEnabled() ? undefined : mailboxInfoInDataBoundry.userIdentity) ??
        defaultIndexerValue) as AccountKey;
}
