import type { RemovedAccount } from 'owa-account-source-list-types';
import getAccountSourceListStore from '../store/accountSourceListStore';

/**
 * Returns the indexer values that were removed this session
 * @returns Array of indexer values removed this session
 */
export default function getAccountsRemovedThisSession(): RemovedAccount[] {
    return getAccountSourceListStore().removedAccounts;
}
