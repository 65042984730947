import getIndexerValueForMailboxInfo from './getIndexerValueForMailboxInfo';
import type MailboxInfo from './MailboxInfo';
import type AccountKey from './AccountKey';

/**
 * Provides a string that can be used for in-memory account key of MailboxInfo in a map or other
 * structure where data needs to be indexed based upon the data boundary of the coprincipal account.
 *
 * What is a data boundary and why is it important? When users have multiple accounts configured in
 * Outlook it is important that the information from each account is separated. For example, if a user
 * configured personal@example.com and work@example.com it would be important to keep the data from
 * personal and work seperate in order to avoid data bleed between these coprincipal accounts.
 *
 * In the above example, there are two coprinicpal accounts (personal@example.com and work@example.com)
 * and the code should seperate data that comes from each of these accounts, and any other mailboxes that
 * they may access (such as shared mailboxes or archive mailboxes) seperate in the stores of client-web.
 * Or in other words, if you configured each account in a seperate OWA instance, the data that would be
 * in each individual instance should if the two accounts are run seperately should be seperated using
 * the account key of the coprincipal account.
 *
 * The typical pattern for this is to store data using the data boundary indexder of the MailboxInfo as
 * the index value to a Map. This allows the data to be stored in a way that is unique to the coprincipal
 * account that contains the data and prevents data bleed between accounts.
 *
 * The following code for example shows how to store MyStoreData in a Map using the account key of the
 * mailboxInfo as the key to prevent data bleed between coprincipal accounts:
 *
 *    export const stores = createStore<MyStore>('myStoreName', {
 *          dataByAccountKey: new ObservableMap()
 *       })();
 *
 *    export const getMyStore = (mailboxInfoInDataBoundary: MailboxInfo): MyStoreData => {
 *       const accountKey = getAccountKeyFromMailboxInfo(mailboxInfoInDataBoundary);
 *       let store = stores.dataByAccountKey.get(accountKey);
 *       if (!store) {
 *          store = createDefaultMyStoreData();
 *          stores.dataByAccountKey.set(accountKey, store);
 *       }
 *
 *       return store;
 *   };
 *
 * @param mailboxInfo MailboxInfo for which the data boundary accountKey is to be returned
 * @returns AccountKey for the data boundary of the MailboxInfo. (This is the same as the indexer value).
 */
export default function getAccountKeyForMailboxInfo(
    mailboxInfoInDataBoundary: MailboxInfo
): AccountKey {
    return getIndexerValueForMailboxInfo(mailboxInfoInDataBoundary);
}
