import type Getter from './types/Getter';
import { getScriptBackupPath, getScriptPath, isRunningOnWorker } from 'owa-config';
import sleep from 'owa-sleep';
import { type TraceErrorObject } from 'owa-trace';
import setPublicPath from './utils/setPublicPath';
import { getConfig } from './utils/config';
import { runAfterInitialRender } from './utils/delayLoad';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Deprecating getPhysicalRing.ts
 *	> 'isDogfoodEnv' import from 'owa-metatags' is restricted. This value is resolved in ECS as a filter. Please create a feature flight if possible. */
import { isDogfoodEnv } from 'owa-metatags';
import type { TraceBundlingSource } from './types/TraceBundlingSource';
import { patchGetScriptFilename, restoreGetScriptFilename } from './utils/getScriptFileName';
import { shouldOverrideQuery, shouldOverrideCDN } from './utils/shouldOverride';
import createImportId from './utils/createImportId';
import { WaterfallMappings } from 'owa-analytics-types';
import type { CustomCheckpointCode, PerformanceDatapointType } from 'owa-analytics-types';
import type { GovernPriority } from 'owa-client-types/lib/GovernPriority';
import { addToTimingMap } from 'owa-performance/lib/utils/timingMap';
import { yieldNow } from 'owa-task-queue/lib/schedule';

export const MAX_ATTEMPTS = 5;
export const INITIAL_DELAY = 1000;
const PERFORMANCE_DATAPOINT_MAX_WINDOW_MS = 10000;

const SuccessAfterRetryDatapointName = 'LazyLoadSuccessAfterRetry';
const FailureDatapointName = 'LazyLoadFailure';

export interface LazyModuleOptions<TModule> {
    initializer?: Getter<() => void, TModule>;
    runWhen?: (cb: () => void) => void;
    maxFailedRetries?: number;
    name?: string;
    govern?: GovernPriority;
    prioritize?: boolean;
}

// For usage of this API, see the owa-bundling README
export default class LazyModule<TModule> {
    private id: string;
    public promise: Promise<TModule> | undefined;
    private moduleValue: TModule | undefined;
    private attempts = 0;
    private failedRetries = 0;
    private pendingImports = 0;
    private constructionStack: Error;
    private importStack: Error | undefined = undefined;
    private static outstandingImports = 0;
    private static nonObservableImportedLoaded: Set<string> = new Set();
    private static shouldObservableMarkImportAsLoaded: Set<string> = new Set();

    /**
     * Datapoints to track difference in time between lazy module evaluation
     * and evaluation time of each import
     *
     * if null, the datapoint has already been logged
     * (e.g. we have elapsed PERFORMANCE_DATAPOINT_MAX_WINDOW_MS)
     *
     * This does not use PerformanceDatapoint, since PerformanceDatapoint depends on LazyModule,
     * and we need to avoid the cyclic dependency
     */
    private importWaterfallData: {
        [key: string]: number;
    } | null = {};

    constructor(
        private importCallback: () => Promise<TModule>,
        private options?: LazyModuleOptions<TModule>
    ) {
        this.id = createImportId();
        this.constructionStack = new Error('LazyModule constructed');
    }

    public importModule(
        source: string,
        traceSource?: TraceBundlingSource,
        traceName?: string,
        perfDatapoint?: PerformanceDatapointType | CustomCheckpointCode
    ): Promise<TModule> | undefined {
        this.importStack = new Error('Lazy Module import');

        // importModule is called in 2 ways
        // 1. Within LazyImport which means we want it to load the code as fast as possible
        // 2. Directly from code as a way to preload the code
        // This if condition here tries to prevent the second case from happening if it is not
        // within the governor.
        // If this is true, we will log a datapoint. Eventually, we will log an error
        const { getWithinGovernor, isFeatureEnabled, logUsage } = getConfig();
        if (
            isFeatureEnabled('fwk-noop-import-module') &&
            source != 'LazyImport' &&
            source != 'LazyGovernImport' &&
            getWithinGovernor &&
            !getWithinGovernor()
        ) {
            logUsage('LazyImportOutsideGovernor', { source });
            return undefined;
        }

        // Initiate the import, if necessary
        if (!this.promise) {
            this.promise = new Promise<TModule>((resolve, reject) => {
                // Don't load any lazy resources until the initial render is complete
                (this.options?.runWhen || runAfterInitialRender)(
                    async () => {
                        // If defined, limit the number of retries after failling
                        // Useful for scripts being blocked by Ad Blockers
                        const shouldRetry =
                            !this.options?.maxFailedRetries ||
                            this.failedRetries < this.options.maxFailedRetries;
                        // We'll retry several times if the load fails
                        this.attempts = 0;
                        LazyModule.outstandingImports++;
                        const name = this.options?.name;
                        const traceMessage = traceSource
                            ? `becuase of ${traceSource} ${traceName}`
                            : `directly with source ${source}`;
                        getConfig().trace(
                            `${
                                name ? `[${name}]` : ''
                            }LazyModule started to download ${traceMessage}, outstanding imports: ${
                                LazyModule.outstandingImports
                            }`
                        );
                        let shouldRetryLocally = true;
                        while (shouldRetryLocally && this.attempts < MAX_ATTEMPTS && shouldRetry) {
                            try {
                                await this.loadModule(resolve, source, traceName, perfDatapoint);
                                shouldRetryLocally = false;
                            } catch (error) {
                                shouldRetryLocally = await this.onLoadFailed(error, reject);
                            }
                        }
                        LazyModule.outstandingImports--;
                    },
                    this.options?.govern,
                    this.options?.prioritize,
                    this.options?.name
                );
            });
        }

        // Keep track of how many consumers are waiting for this import
        if (!this.getIsLoaded()) {
            this.pendingImports++;
        }

        return this.promise;
    }

    public getIsLoaded(): boolean {
        return LazyModule.nonObservableImportedLoaded.has(this.id);
    }

    public observableGetIsLoaded() {
        const isModuleLoaded = getConfig().isImportLoaded(this.id);
        if (!isModuleLoaded) {
            LazyModule.shouldObservableMarkImportAsLoaded.add(this.id);
        }
        return isModuleLoaded;
    }

    private async loadModule(
        resolve: (value: TModule) => void,
        source: string,
        traceName: string | undefined,
        perfDatapoint?: PerformanceDatapointType | CustomCheckpointCode
    ) {
        if (perfDatapoint && 'eventName' in perfDatapoint) {
            (perfDatapoint as PerformanceDatapointType).addToPredefinedWaterfall('Code_S', true);
        } else if (perfDatapoint) {
            const customDp = perfDatapoint as CustomCheckpointCode;
            customDp.datapoint.addToCustomWaterfall(
                customDp.indexes[WaterfallMappings.CODE_LOADING],
                `Code_S_${customDp.name}`,
                true
            );
        }
        const startTime = self.performance.now();

        this.attempts++;
        const queryString = shouldOverrideQuery(this.attempts, MAX_ATTEMPTS) ? `bO=1` : '';
        patchGetScriptFilename(queryString);

        const url = shouldOverrideCDN(this.attempts, MAX_ATTEMPTS)
            ? getScriptBackupPath()
            : getScriptPath();

        setPublicPath(url);

        const importPromise = this.importCallback();
        const name = this.options?.name;
        Object.values(createBundleLoadCallbacks).map(cb => cb(importPromise, name));

        // restore any changes to the script filename
        restoreGetScriptFilename();
        // we should set the public path after we are done to make sure any eager scripts are loaded from the main url
        setPublicPath(getScriptPath());

        const importValue = await importPromise;
        this.moduleValue = importValue;

        const config = getConfig();

        LazyModule.nonObservableImportedLoaded.add(this.id);
        if (LazyModule.shouldObservableMarkImportAsLoaded.has(this.id)) {
            config.markImportAsLoaded([this.id]);
        }

        let traceMessage = `${
            name ? `[${name}]` : ''
        }LazyModule finished downloading ${traceName} in ${this.attempts} attempts`;

        const duration = startTime && self.performance.now() - startTime;
        if (duration) {
            traceMessage += ` in ${Math.floor(duration)} ms`;
            lazyLoadedModules.push({
                start: startTime,
                duration,
                attemps: this.attempts,
            });
        }

        config.trace(traceMessage);

        if (this.options?.initializer) {
            // Get the value out of the module
            const initializeValue = this.options.initializer(importValue);
            // Call the bundle initializer
            initializeValue();
        }

        if (perfDatapoint && 'eventName' in perfDatapoint) {
            (perfDatapoint as PerformanceDatapointType).addToPredefinedWaterfall('Code_E', true);
        } else if (perfDatapoint) {
            const customDp = perfDatapoint as CustomCheckpointCode;
            customDp.datapoint.addToCustomWaterfall(
                customDp.indexes[WaterfallMappings.CODE_LOADED],
                `Code_E_${customDp.name}`,
                true
            );
        }

        // Only log import evaluation inside of SDFv2
        //
        // We can't use feature flags for this, since LazyModule imports are
        // evaluated before flights are downloaded.
        if (isDogfoodEnv()) {
            this.addWaterfallCheckpoint('module');
            // Only log imports that happen in the first n seconds
            setTimeout(() => {
                getConfig().logUsage('LazyModuleImports', {
                    entryModuleId: this.__getEntryModuleIdForLogging(),
                    ...this.importWaterfallData,
                });
                this.importWaterfallData = null;
            }, PERFORMANCE_DATAPOINT_MAX_WINDOW_MS);
            if (typeof importValue == 'object' && source != 'LazyImport') {
                modulesLoadedDirectly.push(source);
            }
        }

        this.pendingImports = 0;

        // Keep track of cases where retrying actually helps
        if (this.attempts > 1) {
            getConfig().logUsage(SuccessAfterRetryDatapointName, {
                attempts: this.attempts.toString(),
                url,
            });
        }

        // When this flight is on, LazyImport is already splitting every import
        // to a new task so we don't need to do it here as well
        if (!getConfig().isFeatureEnabled('fwk-import-yield')) {
            // we want to split up the evaluation of the module and running the task
            // to avoid blocking the main thread
            await yieldNow();
            addToTimingMap('lazymodule', this.options?.name || 'unknown');
            resolve(importValue);
        } else {
            resolve(importValue);
        }
    }

    private async onLoadFailed(loadingError: any, reject: (reason: any) => void): Promise<boolean> {
        const name = this.options?.name;

        if (!(loadingError instanceof Error)) {
            const caughtError = loadingError;
            loadingError = new Error('Lazy module failed to load');
            try {
                loadingError.additionalInfo = { caughtError };
            } catch {}
        }

        getConfig().trace(
            `${name ? `[${name}]` : ''}LazyModule failed to load ${
                loadingError?.message || loadingError
            }`
        );

        this.failedRetries++;

        if (!isNetworkError(loadingError)) {
            try {
                Object.defineProperty(loadingError, 'scriptEval', { value: true });
            } catch (errorSettingScriptEval) {
                const rethrownError: TraceErrorObject = new Error(
                    'EVAL ERROR: Could not set value on EVAL ERROR for logging'
                );
                rethrownError.scriptEval = true;
                rethrownError.additionalInfo = {
                    loadingError: loadingError.stack,
                    errorSettingScriptEval: errorSettingScriptEval.stack,
                };
                loadingError = rethrownError;
            }

            loadingError.additionalInfo = {
                ...loadingError.additionalInfo,
                constructionStack: this.constructionStack.stack,
                importStack: this.importStack?.stack,
            };

            getConfig().logError(loadingError);

            // We've already logged the error with trace.error so mark it as reported.
            loadingError.reported = true;
            reject(loadingError);

            // The module is loaded but in a bad state (i.e. there's no point in trying to reload
            // it because it's already in Webpack's module cache). We also don't want to retry
            return false;
        } else if (this.attempts >= MAX_ATTEMPTS) {
            // After MAX_ATTEMPTS, just fail
            getConfig().logUsage(FailureDatapointName, {
                message: loadingError.message,
                pendingImports: this.pendingImports,
            });

            // The error from webpack will always be different because it includes the chunk number
            // that failed; we create a new error so our analytics can bucket them together.
            let errorString = 'Failed to load javascript.';
            if (loadingError.httpStatus) {
                errorString += 'Status:' + loadingError.httpStatus;
            }

            /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
             * The error name (message) must be a string literal (no variables in it).
             *	> Error names can only be a string literals. Use the diagnosticInfo to add custom data. */
            const newError: TraceErrorObject = new Error(errorString);
            newError.networkError = true;
            newError.additionalInfo = {
                loadingError: { message: loadingError.message, ...loadingError.additionalInfo },
            };
            newError.request = loadingError.request;
            reject(newError);
            this.pendingImports = 0;

            // Reset the cached promise so that we'll retry again the next time
            // import is attempted
            this.promise = undefined;
        } else if (this.attempts > 1) {
            // Delay before retrying
            await sleep(INITIAL_DELAY * Math.pow(2, this.attempts - 2));
        }
        return true;
    }

    getModuleValue(): TModule | undefined {
        if (this.getIsLoaded() || this.observableGetIsLoaded()) {
            return this.moduleValue;
        }
        return undefined;
    }

    private __getEntryModuleIdForLogging(): string {
        const importString = this.importCallback.toString();
        // Get the last occurrence of .bind in the import callback
        try {
            const CHUNK_ID_REGEX = /.*\.bind\(null,([^)]+)\)/;
            const match = importString.match(CHUNK_ID_REGEX);
            return match && match.length > 1 ? match[1] : importString;
        } catch {
            return importString;
        }
    }

    public addWaterfallCheckpoint(name: string): void {
        if (this.importWaterfallData && this.importWaterfallData[name] === undefined) {
            this.importWaterfallData[name] = self?.performance.now();
        }
    }

    public getModuleName(): string | undefined {
        return this.options?.name;
    }
}

let modulesLoadedDirectly: string[] = [];
export function getModulesLoadedDirectly(): string[] {
    const tempValue = modulesLoadedDirectly;
    modulesLoadedDirectly = [];
    return tempValue;
}

export type LazyLoadedModules = {
    start: number;
    duration: number;
    attemps: number;
};
let lazyLoadedModules: LazyLoadedModules[] = [];

export function getLazyLoadedModulesAndCleanup(start: number | undefined): LazyLoadedModules[] {
    const lazyModulesCopy = [...lazyLoadedModules];
    // Removes old modules we don't need anymore
    if (start) {
        lazyLoadedModules = lazyLoadedModules.filter(
            module => module.start + module.duration >= start
        );
    }
    return lazyModulesCopy;
}

type BundleLoadCallback = (p: Promise<any>, name: string | undefined) => void;
let bundleLoadCallbackNumber = 0;
var createBundleLoadCallbacks: {
    [key: number]: BundleLoadCallback;
} = {};

export function registerBundleLoadCallback(callback: BundleLoadCallback): () => void {
    const id = bundleLoadCallbackNumber++;
    createBundleLoadCallbacks[id] = callback;
    return () => {
        delete createBundleLoadCallbacks[id];
    };
}

function isNetworkError(error: any): boolean {
    if (error.request) {
        // When Webpack fails to load a bundle on the main thread it includes a 'request' property on the error; if
        // that property is absent then this must be an error that happened while evaluating
        // the module.
        return true;
    } else {
        // Webpack doesn't set the request propery on requests from the worker.  But, it does use importScripts
        // to load the code and network errors from there have the name NetworkError and/or code 19
        return isRunningOnWorker() && (error.name === 'NetworkError' || error.code === 19);
    }
}
