/**
 * Returns true if it's a critical Worker error and it has to be stopped
 *
 * example: Uncaught NetworkError: Failed to execute 'importScripts' on 'WorkerGlobalScope'
 * ^ while technically it's a network error, it means we weren't able to load worker scripts (after multiple retries),
 * which renders worker unusable
 */
export function isCriticalWorkerError(e: any) {
    return typeof e?.message === 'string' ? e.message.includes('importScripts') : false;
}
