// We'll assume LTR until the direction is set
let isRtl: boolean | undefined = undefined;

// Keep track of links we need to update once the direction is set
let pendingLinks: HTMLLinkElement[] | undefined;

// Provide the `insertCssLink` that is called by webpack
export function initializeCssInjection() {
    self.insertCssLink = (link: HTMLLinkElement) => {
        if (isRtl === undefined) {
            pendingLinks = pendingLinks || [];
            pendingLinks.push(link);
        } else if (isRtl) {
            updateHrefToRtl(link);
        }

        self.document?.head.appendChild(link);
    };
}

// Set the CSS direction (this is done once, during boot)
export function setCssDirection(newIsRtl: boolean) {
    isRtl = newIsRtl;

    // If necessary, update pending links to RTL
    if (isRtl && pendingLinks) {
        for (const pendingLink of pendingLinks) {
            updateHrefToRtl(pendingLink);
        }
    }

    pendingLinks = undefined;
}

export function updateHrefToRtl(linkTag: HTMLLinkElement) {
    linkTag.href = linkTag.href.replace(/\.css$/, '.rtl.css');
}
