import type { CustomDataMap, CustomDataType, DataPointEventType } from 'owa-analytics-types';
import {
    getApp,
    /* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
     * Remove reference to getHostValue
     *	> 'getHostValue' import from 'owa-config' is restricted. Please use isFeatureEnabled/isBootFeatureEnabled instead. Host value is checked with ECS/CT as a filter. */
    getHostValue,
    getLogicalRing,
    getSessionId,
    getClientId,
    getLayout,
} from 'owa-config';
import { getThreadName } from 'owa-thread-config';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki) Using the value for analytics
import { getPhysicalRing } from 'owa-metatags';
import { getEnhancedHostDeviceId } from 'owa-config/lib/enhancedHost';

export type CustomDataWithoutUndefined = {
    [key: string]: Omit<CustomDataType, 'undefined'>;
};

export interface CustomEvent {
    name: DataPointEventType;
    data: CustomDataWithoutUndefined;
}

export function createEvent(
    table: DataPointEventType,
    eventName: string,
    data?: CustomDataMap,
    customColumns?: CustomDataWithoutUndefined
) {
    const now = self?.performance?.now?.();

    const event: CustomEvent = {
        name: table,
        data: {
            EventName: eventName,
            App: getApp(),
            Host: getHostValue() ?? '',
            Ring: getLogicalRing() ?? '',
            PhysicalRing: getPhysicalRing() ?? '',
            'Session.Id': getSessionId(),
            SessionElapseTime: now ? Math.floor(now) : '',
            MiscData: data ? JSON.stringify(data) : '',
            Thread: getThreadName()?.toString() ?? '',
            ClientId: getClientId() ?? '',
            DeviceId: getEnhancedHostDeviceId() ?? '',
            Layout: getLayout(),
            Sampled: 100,
            ...customColumns,
        },
    };

    return event;
}
