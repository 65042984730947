import getUserAgentString from './getUserAgentString';
export type Browser = any;

interface BrowserInfo {
    browser: Browser;
    browserVersion: number[];
}

let cachedValue: BrowserInfo | undefined;

export default function getBrowserInfo(): BrowserInfo {
    if (!cachedValue) {
        cachedValue = calculateBrowserInfo();
    }

    return cachedValue;
}

export function calculateBrowserInfo(): BrowserInfo {
    let browser: Browser = 'Unknown';
    let browserVersion: number[] | undefined;
    const userAgentString = getUserAgentString();
    if (/OPR\//.test(userAgentString)) {
        browser = 'Opera';
        browserVersion = getNonIEBrowserVersion(userAgentString, 'OPR');
    } else if (/OPiOS/.test(userAgentString)) {
        browser = 'Opera';
        browserVersion = getNonIEBrowserVersion(userAgentString, 'OPiOS');
    } else if (/PhantomJS/.test(userAgentString)) {
        browser = 'PhantomJS';
    } else if (/SamsungBrowser/.test(userAgentString)) {
        browser = 'Samsung Internet';
        browserVersion = getNonIEBrowserVersion(userAgentString, 'SamsungBrowser');
    } else if (/Edge/.test(userAgentString)) {
        browser = 'Edge';
    } else if (/EdgiOS/.test(userAgentString)) {
        browser = 'Edge Anaheim';
        browserVersion = getNonIEBrowserVersion(userAgentString, 'EdgiOS');
    } else if (/EdgA/.test(userAgentString)) {
        browser = 'Edge Anaheim';
        browserVersion = getNonIEBrowserVersion(userAgentString, 'EdgA');
    } else if (
        /Edg/.test(userAgentString) ||
        (typeof navigator != 'undefined' &&
            navigator?.userAgentData?.brands?.some(b => b?.brand == 'Microsoft Edge'))
    ) {
        browser = 'Edge Anaheim';
        browserVersion = getNonIEBrowserVersion(userAgentString, 'Edg');
    } else if (/Electron/.test(userAgentString)) {
        browser = 'Electron';
    } else if (/CriOS/.test(userAgentString)) {
        browser = 'Chrome';
        browserVersion = getNonIEBrowserVersion(userAgentString, 'CriOS');
    } else if (/Chrome/.test(userAgentString)) {
        browser = 'Chrome';
    } else if (/Trident/.test(userAgentString)) {
        browser = 'MSIE';
        browserVersion = getIEBrowserVersion(userAgentString);
    } else if (/FxiOS/.test(userAgentString)) {
        browser = 'Firefox';
        browserVersion = getNonIEBrowserVersion(userAgentString, 'FxiOS');
    } else if (/Firefox/.test(userAgentString)) {
        browser = 'Firefox';
    } else if (/Safari/.test(userAgentString) || /AppleWebKit/.test(userAgentString)) {
        browser = 'Safari';
        browserVersion = getNonIEBrowserVersion(userAgentString, 'Version');
    } else if (/SkypeShell/.test(userAgentString)) {
        browser = 'SkypeShell';
    }

    return {
        browser,
        browserVersion: browserVersion || getNonIEBrowserVersion(userAgentString, browser),
    };
}

function getNonIEBrowserVersion(userAgentString: string, prefix: string): number[] {
    const match = userAgentString.match(new RegExp(prefix + '/([\\d,\\.]+)'));

    return !match ? [] : parseVersionString(match[1]);
}

function getIEBrowserVersion(userAgentString: string): number[] {
    let match = userAgentString.match(/MSIE ([\d,\.]+)/);
    if (match) {
        return parseVersionString(match[1]);
    }
    match = userAgentString.match(/rv:([\d,\.]+)/);
    return !match ? [] : parseVersionString(match[1]);
}

export function test_reset() {
    cachedValue = undefined;
}

function parseVersionString(version: string): number[] {
    return version.split(/[\.,]/).map(num => parseInt(num));
}
