import type { MailboxInfo } from 'owa-client-types';
import { mailboxRankChecker } from 'owa-client-types';
import { isBootFeatureEnabled } from 'owa-metatags';
import { trace } from 'owa-trace';
import { default as getAccountSourceListStore } from '../store/accountSourceListStore';
import { accountRankTypeChecker } from '../store/schema/AccountSourceList';
import getAccountAndCoprincipalSourceId from './getAccountAndCoprincipalSourceId';
import getAccountByMailboxInfo from './getAccountByMailboxInfo';
import getMailboxInfoBySourceId from './getMailboxInfoBySourceId';

/**
 * Returns the Coprincipal account associated with the supplied MailboxInfo. This may be the MailboxInfo
 * passed in, in the case where the MailboxInfo is a Coprincipal MailboxInfo, or it could be the MailboxInfo
 * that the resource MailboxInfo is associated with.
 * @param mailboxInfo Identifies a mailbox source
 * @returns MailboxInfo of the associated Coprincipal account, or undefined
 */
export default function getCoprincipalMailboxInfo(
    mailboxInfo: MailboxInfo
): MailboxInfo | undefined {
    // First we make sure it is a mailbox info the store knows about
    const account = getAccountByMailboxInfo(mailboxInfo);
    if (account) {
        if (mailboxRankChecker.isCoprincipal(mailboxInfo)) {
            return mailboxInfo;
        }

        if (accountRankTypeChecker.isCoprincipal(account)) {
            return account.mailboxInfo;
        }

        const accountAndCoprincipal = getAccountAndCoprincipalSourceId(account.sourceId);
        if (accountAndCoprincipal?.coprincipalSourceId) {
            return getMailboxInfoBySourceId(accountAndCoprincipal?.coprincipalSourceId);
        }
    }

    if (
        !isBootFeatureEnabled('acctNoFallback') &&
        getAccountSourceListStore().globalSettingsSourceId
    ) {
        trace.warn('[source-list-store] failed to find MailboxInfo falling back to selected');
        return getMailboxInfoBySourceId(getAccountSourceListStore().globalSettingsSourceId);
    }

    // failed to find the account, this can happen when the account has been deleted
    return undefined;
}
