import type { TraceErrorObject } from './TraceErrorObject';
import TraceLevel from './TraceLevel';
import { tryGetTraceObjectErrorFromApolloError } from './tryGetTraceObjectErrorFromApolloError';
import type { TraceComponent } from './types/TraceComponent';

type ListenerName = 'GlobalAnalytics' | 'DevTools' | 'UIExceptionHandler' | 'Tests' | 'Oobe';
interface ListenerInfo {
    name: ListenerName;
    listener: TraceListener;
}

const listeners: ListenerInfo[] = [];
let errorListeners: ListenerInfo[] = [];

let bootCache: Parameters<TraceListener>[] | undefined = [];
const processedCache: {
    [_K in ListenerName]: {
        required: boolean;
        registered: boolean;
    };
} = {
    GlobalAnalytics: { required: true, registered: false },
    DevTools: { required: true, registered: false },
    UIExceptionHandler: { required: true, registered: false },
    Tests: { required: false, registered: false },
    Oobe: { required: false, registered: false },
};

export type TraceListener = (
    message: string,
    tracelevel: TraceLevel,
    component?: TraceComponent,
    errorObject?: TraceErrorObject
) => any;

export function registerTracerListener(
    name: ListenerName,
    traceListener: TraceListener,
    listenToErrorsOnly?: boolean
) {
    // Errors shouldn't be logged twice. Disable the OOBE listener once the GlobalAnalytics listener is registered
    if (processedCache.Oobe?.registered && name === 'GlobalAnalytics') {
        processedCache.Oobe.registered = false;
        errorListeners = errorListeners.filter(listener => listener.name !== 'Oobe');
    }

    // Process cache for events for listeners that are required but not registered yet
    if (bootCache && processedCache[name]?.required && !processedCache[name].registered) {
        info(
            `[Trace] Processing boot cache for ${name}. Cache size: ${bootCache.length}`,
            'analytics'
        );

        for (const bootCacheItem of bootCache) {
            traceListener(...bootCacheItem);
        }

        processedCache[name].registered = true;
    }

    (listenToErrorsOnly ? errorListeners : listeners).push({ name, listener: traceListener });

    // Only clear the cache once the conosole & analytics registered their listeners
    if (!shouldAddToCache()) {
        bootCache = undefined;
        info('[Trace] Cleared boot cache', 'analytics');
    }
}

function trace(
    message: string,
    traceLevel: TraceLevel,
    component: TraceComponent | undefined,
    errorObject?: TraceErrorObject
) {
    // During boot, no listeners are registered, so we cache the traces
    if (bootCache) {
        bootCache.push([message, traceLevel, component, errorObject]);
    }

    // we only call the trace listeners if the trace level is an error
    if (traceLevel === TraceLevel.Error || traceLevel === TraceLevel.DebugError) {
        for (const { listener } of errorListeners) {
            listener(message, traceLevel, component, errorObject);
        }
    }

    if (listeners.length > 0) {
        for (const { listener } of listeners) {
            listener(message, traceLevel, component, errorObject);
        }
    }
}

function info(message: string, component?: TraceComponent) {
    trace(message, TraceLevel.Info, component);
}

function warn(message: string, component?: TraceComponent) {
    trace(message, TraceLevel.Warning, component);
}

function verbose(message: string, component?: TraceComponent) {
    trace(message, TraceLevel.Verbose, component);
}

interface InternalTraceErrorObject extends TraceErrorObject {
    error?: Error;
}

/**
 * These errors will
 *     1) show an error popup
 *     2) be logged in traces that are collected
 */
export function debugErrorThatWillShowErrorPopupOnly(
    msg: TraceErrorObject | string,
    error?: TraceErrorObject,
    component?: TraceComponent
) {
    errorInternal(TraceLevel.DebugError, msg, error, component);
}

/**
 * These errors will
 *     1) be logged to the client_error table which is monitored
 *     2) show an error popup within dogfood
 *     3) be logged in traces that are collected
 * The client_error table is monitored and and will stop deployment with stoplight,
 * and alert an OCE if the errors hit a certain threshold
 * Use logUsage in owa-analytics if you want to log an error that should not cause an alert.
 * Use debugErrorThatWillShowErrorPopupOnly if you don't want to log the error but still want to show
 * the popup
 * Use trace.warn if you would like to still see this in the trace logs
 */
export function errorThatWillCauseAlert(
    msg: TraceErrorObject | string,
    error?: TraceErrorObject,
    component?: TraceComponent
) {
    errorInternal(TraceLevel.Error, msg, error, component);
}

/**
 * Version of errorThatWillCauseAlert that also throws the error
 */
export function errorThatWillCauseAlertAndThrow(
    msg: TraceErrorObject | string,
    error?: TraceErrorObject,
    component?: TraceComponent
): never {
    /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
     * The error name (message) must be a string literal (no variables in it).
     *	> Error names can only be a string literals. Use the diagnosticInfo to add custom data. */
    errorThatWillCauseAlert(msg, error, component);
    if (typeof msg === 'string') {
        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * Error constructor names can only be a string literals.
         *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
        throw new Error(msg);
    }

    throw msg || new Error('UnknownError');
}

function errorInternal(
    level: TraceLevel,
    msg: TraceErrorObject | string,
    error: TraceErrorObject | undefined,
    component: TraceComponent | undefined
) {
    let infoObject: InternalTraceErrorObject;
    let message: string;
    if (typeof msg === 'string') {
        message = msg;

        // The exception that bubbles up could be an ApolloError, so try to get the
        // TraceErrorObject embedded in it so our checks for error types work as expected.
        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * Error constructor names can only be a string literals.
         *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
        infoObject = tryGetTraceObjectErrorFromApolloError(error) || new Error(msg);
    } else {
        // The exception that bubbles up could be an ApolloError, so try to get the
        // TraceErrorObject embedded in it so our checks for error types work as expected.
        infoObject = tryGetTraceObjectErrorFromApolloError(msg) || msg || new Error('UnknownError');
        message = infoObject.message;
    }

    if (infoObject.error?.message && infoObject.error.stack) {
        message = infoObject.error.message;
        infoObject.stack = infoObject.error.stack;
        infoObject.name = infoObject.error.name;
    }

    if (infoObject.component) {
        message = 'COMPONENT ERROR: ' + infoObject.message;
    } else if (infoObject.scriptEval) {
        message = 'EVAL ERROR: ' + infoObject.message;
    }

    trace(message || '', level, component, infoObject);
}

/**
 * We can't import this from owa-config and owa-trace shouldn't have any dependencies
 */
function isRunningOnWorker() {
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment  -- (https://aka.ms/OWALintWiki)
     * Acceptable to run within the worker */
    // @ts-ignore - TypeScript treats WorkerGlobalScope as a type only
    return typeof WorkerGlobalScope !== 'undefined' && self instanceof WorkerGlobalScope;
}

function shouldAddToCache() {
    if (isRunningOnWorker()) {
        return !processedCache.DevTools.registered;
    }

    // Continue adding to the cache if a required listener isn't registered yet
    return Object.values(processedCache)
        .filter(listener => listener.required)
        .some(listener => !listener.registered);
}

export default {
    info,
    warn,
    verbose,
};
