declare var __webpack_get_script_filename__: (chunkId: number) => string;

let originalGetScriptFilename: (chunkId: number) => string;
if (process.env.IS_WEBPACK) {
    originalGetScriptFilename = __webpack_get_script_filename__;
}

export function patchGetScriptFilename(queryString: string) {
    if (process.env.IS_WEBPACK && originalGetScriptFilename && queryString) {
        __webpack_get_script_filename__ = function (chunkId: number) {
            const scriptName = originalGetScriptFilename(chunkId) + '?' + queryString;
            return scriptName;
        };
    }
}

export function restoreGetScriptFilename() {
    if (process.env.IS_WEBPACK && originalGetScriptFilename) {
        __webpack_get_script_filename__ = originalGetScriptFilename;
    }
}
