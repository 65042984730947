import type { MailboxInfo } from 'owa-client-types';
import type { CoprincipalAccountSource } from '../store/schema/AccountSourceList';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import getCoprincipalAccountForIndexerValue from './getCoprincipalAccountForIndexerValue';

/**
 * Returns the Coprincipal account associated with the supplied MailboxInfo. This may be the MailboxInfo
 * passed in, in the case where the MailboxInfo is a Coprincipal MailboxInfo, or it could be the MailboxInfo
 * that the resource MailboxInfo is associated with.
 * The lookup is done by indexer value.
 * @param mailboxInfo Identifies a mailbox source, or undefined if the mailbox is not found
 * @returns Coprincipal account, or undefined if the account was not found
 */
export default function getCoprincipalAccountForMailboxInfo(
    mailboxInfo: MailboxInfo
): CoprincipalAccountSource | undefined {
    const indexer = getIndexerValueForMailboxInfo(mailboxInfo);
    return getCoprincipalAccountForIndexerValue(indexer);
}
