import { mutatorAction } from 'satcheljs';
import type {
    AccountSource,
    AdditionalAccountInfo,
    M365ArchiveMailboxAccountSource,
    M365UserMailboxAccountSource,
    LoadState,
} from '../store/schema/AccountSourceList';
import setM365UserMailboxAdditionalSources from '../utils/setM365UserMailboxAdditionalSources';

function getArchiveSources(account: M365UserMailboxAccountSource): AdditionalAccountInfo {
    return account.archiveSources;
}

function updateArchiveSources(
    account: M365UserMailboxAccountSource,
    loadState: LoadState,
    sources: AccountSource[]
) {
    account.archiveSources.loadState = loadState;
    account.archiveSources.sources = sources;
}

export default mutatorAction(
    'SET_ARCHIVE_ACCOUNTS',
    function (
        coprincipalSourceId: string,
        loadState: LoadState,
        accounts: M365ArchiveMailboxAccountSource[] | undefined
    ): void {
        setM365UserMailboxAdditionalSources(
            'Archive',
            coprincipalSourceId,
            {
                coprincipalSourceId,
                loadState,
                sources: accounts ? accounts : [],
            },
            getArchiveSources,
            updateArchiveSources
        );
    }
);
