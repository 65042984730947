/**
 * MailboxInfo structures represent a hierarchy of Mailbox's used within the application. This hierarchy consists
 * of N 'Coprincipal' mailboxes all of which have 0 to M 'Resource' mailboxes and 0 to 1 'Licensing' mailboxes.
 * The hierarchy can be visualized as:
 *
 *  Application
 *  +- A (Coprincipal)
 *  |  + X (Resource)
 *  |  + Y (Resource)
 *  |  + Z (Licensing)...
 *  |  + ...
 *  +- B (Coprincipal)
 *  |  + X (Resource)
 *  |  + Y (Resource)
 *  |  + Z (Licensing)...
 *  |  + ...
 *
 * The conceptual model of adding/removing an account to/from the application is the management of the Coprincipal
 * accounts.
 */
type MailboxRank =
    /**
     * The MailboxInfo instance represents a Coprincipal account
     */
    | 'Coprincipal'

    /**
     * This MailboxInfo is a resource that is being used by a Coprincipal account. For example, a group mailbox is
     * a resource for a UserMailbox which is the Coprincipal account.
     */
    | 'Resource'

    /**
     * This MailboxInfo is a licensing mailbox that is being used by a Coprincipal account. For example, a licensing
     * mailbox is used to determine the licensing status of a Coprincipal account.
     */
    | 'Licensing';

export const CoprincipalMailboxRank: MailboxRank = 'Coprincipal';
export const ResourceMailboxRank: MailboxRank = 'Resource';
export const LicensingMailboxRank: MailboxRank = 'Licensing';

export default MailboxRank;
