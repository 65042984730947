type SimpleHeaders = {
    [key: string]: string;
};

export function addIncludeThirdPartyOnlineMeetingProvidersHeader(headers: Headers | SimpleHeaders) {
    const prefer = buildPreferHeader(headers);
    if (isHeaders(headers)) {
        headers.set('prefer', prefer);
    } else {
        headers['prefer'] = prefer;
    }
}

function buildPreferHeader(headers: Headers | SimpleHeaders) {
    const preferIncludeThirdPartyOnlineMeetingProviders =
        'exchange.behavior="IncludeThirdPartyOnlineMeetingProviders"';
    const existingPrefer = isHeaders(headers) ? headers.get('prefer') : headers['prefer'];
    return existingPrefer
        ? `${existingPrefer}, ${preferIncludeThirdPartyOnlineMeetingProviders}`
        : preferIncludeThirdPartyOnlineMeetingProviders;
}

function isHeaders(headers: Headers | SimpleHeaders): headers is Headers {
    return !!headers.get && !!headers.set;
}
