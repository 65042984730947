import type { SerializationIssue } from '../types/SerializationTypes';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * Make sure this isn't imported in the analytics worker
 *	> 'mobx' import is restricted from being used. Mobx is not allowed in the analytics worker [analytics-worker-lint] */
import { isObservable } from 'mobx';

export function getSerializationIssue(
    parent: any,
    key: string | number
): SerializationIssue | null {
    const value = parent[key];

    return isMobx(value) || isFunction(value) || isPromise(value);
}

function isMobx(value: any): SerializationIssue | null {
    if (isObservable(value)) {
        return {
            type: 'Observable',
            hint: 'This is probably a Mobx observable. Convert it to a plain object.',
        };
    }

    return null;
}

function isFunction(value: any): SerializationIssue | null {
    if (typeof value == 'function') {
        return {
            type: 'Function',
            hint: 'Remove functions when passing data to a Web Worker.',
        };
    }

    return null;
}

function isPromise(value: any): SerializationIssue | null {
    if (typeof value === 'object' && typeof value?.then === 'function') {
        return {
            type: 'Promise',
            hint: 'Remove Promises when passing data to a Web Worker.',
        };
    }

    return null;
}
