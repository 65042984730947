import { trace } from 'owa-trace';
import type { PerformanceWithMemory } from './trackMemory.d';

const isPerformanceSupported = self?.performance;
const bootMemory: {
    [index: string]: {
        size: number;
    };
} = {};

export function addBootMemoryReading(name: string) {
    if (isPerformanceSupported) {
        const performanceWithMemory = self.performance as PerformanceWithMemory;

        if (performanceWithMemory.memory) {
            bootMemory[name] = {
                size: performanceWithMemory.memory.usedJSHeapSize,
            };
        }
    }
}

export function getBootMemory() {
    trace.verbose('[BootMemory] getBootMemory ' + bootMemory);
    return JSON.stringify(bootMemory);
}
