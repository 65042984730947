export function isNetworkAvailable() {
    // navigator.onLine will return true
    // even if you're not connected to the internet
    // ideally we'd need to periodically fetch a known endpoint
    return self.navigator.onLine;
}

export function isOffline() {
    return !isNetworkAvailable();
}
