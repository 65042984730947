import getCoprincipalAccountForIndexerValue from './getCoprincipalAccountForIndexerValue';
import { accountSourceDataTypeChecker } from '../store/schema/AccountSourceList';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { trace } from 'owa-trace';
import type { M365SharedMailboxAccountSource } from '../store/schema/AccountSourceList';
import type { MailboxInfo } from 'owa-client-types';
import {
    type ContractsSupportedFilter,
    filterContractsSupportedPredicates,
} from '../selectors/getCoprincipalAccounts';

/**
 * Returns the shared mailboxes associated with the coprincipal account
 * @param mailboxInfo MailboxInfo for the coprincipal account for which shared mailboxes are to be retrieved
 * @param contracts Contracts supported by the shared mailboxes
 * @returns Array of shared mailbox sources, which will include if the items are automapped.
 */
export default function getSharedAccountSourcesForMailboxInfo(
    mailboxInfo: MailboxInfo,
    contracts: ContractsSupportedFilter
): M365SharedMailboxAccountSource[] {
    const contractFilter = filterContractsSupportedPredicates[contracts];
    const indexer = getIndexerValueForMailboxInfo(mailboxInfo);
    const account = getCoprincipalAccountForIndexerValue(indexer);
    if (!account) {
        trace.warn(
            '[source-list-store] Failed to find account from MailboxInfo can not get shared mailboxes'
        );

        return [];
    }

    if (accountSourceDataTypeChecker.isM365UserMailbox(account)) {
        const sharedMailboxes: M365SharedMailboxAccountSource[] = [];
        account.sharedSources.sources.forEach(item => {
            if (accountSourceDataTypeChecker.isM365SharedMailbox(item) && contractFilter(item)) {
                sharedMailboxes.push(item);
            }
        });

        return sharedMailboxes;
    }

    trace.warn('[source-list-store] Account is not an M365 account can not get shared mailboxes');

    return [];
}
