import type { AccountSource } from '../store/schema/AccountSourceList';
import getAccountSourceListStore from '../store/accountSourceListStore';
import getAccountBySourceId from './getAccountBySourceId';
import logDefaultOrReflowCall from '../utils/logDefaultOrReflowCall';
import { isBootFeatureEnabled } from 'owa-metatags';

// TODO: Technical debt, the selected account call needs to be removed. For now we are
// returning the global settings account.
export default function getSelectedAccount(): AccountSource | undefined {
    if (isBootFeatureEnabled('acctNoFallback')) {
        return undefined;
    }

    logDefaultOrReflowCall('getSelectedAccount');
    return getAccountBySourceId(getAccountSourceListStore().globalSettingsSourceId);
}
