import type { CustomCheckpointNetwork, PerformanceDatapointType } from 'owa-analytics-types';
import { DataSource, WaterfallMappings } from 'owa-analytics-types';
import { addTimingToWaterfall } from './addTimingToWaterfall';

type ArgumentTypes<F extends (...args: any[]) => any> = F extends (...args: infer A) => any
    ? A
    : never;
type Return<F extends (...args: any[]) => any> = F extends (...args: any) => infer A ? A : any;

interface NetworkWaterfallSettings {
    discardIfDefined?: boolean;
}

interface NetworkWaterfallDatapoint {
    datapoint?: PerformanceDatapointType;
    customDatapoint?: CustomCheckpointNetwork;
}

export async function addTimingsToNetworkRequest<NetworkFunction extends (...args: any[]) => any>(
    dp: NetworkWaterfallDatapoint,
    settings: NetworkWaterfallSettings | null,
    requestFunction: NetworkFunction,
    ...args: ArgumentTypes<NetworkFunction>
): Promise<Return<NetworkFunction>> {
    const isPredefined = !!dp.datapoint?.isPerformanceDatapoint;
    const customCheckpoint = dp.customDatapoint;
    const dpOnMainThread = dp.datapoint || dp.customDatapoint?.datapoint;

    addTimingToWaterfall(
        dpOnMainThread || null,
        isPredefined,
        'Network_S',
        customCheckpoint?.indexes?.[WaterfallMappings.NETWORK_REQUEST_STARTING],
        customCheckpoint?.name,
        settings?.discardIfDefined,
        args?.[0] ? (args[0] as string) : undefined /* actionName */
    );

    const response = await requestFunction.apply(null, args);

    const dataSource = DataSource.NETWORK;

    if (dp.datapoint) {
        dp.datapoint.addDataSource(dataSource);
    } else if (dp.customDatapoint) {
        dp.customDatapoint.datapoint.addToCustomWaterfall(
            dp.customDatapoint.indexes?.[WaterfallMappings.DATA_SOURCE],
            `DataSource_${dp.customDatapoint.name}`,
            settings?.discardIfDefined,
            dataSource
        );
    }

    addTimingToWaterfall(
        dpOnMainThread || null,
        isPredefined,
        'Network_E',
        customCheckpoint?.indexes?.[WaterfallMappings.NETWORK_REQUEST_ENDED],
        customCheckpoint?.name,
        settings?.discardIfDefined,
        args?.[0] ? (args[0] as string) : undefined /* actionName */
    );

    return response;
}
