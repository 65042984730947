let fault: Error | undefined;
let callback: ((e: Error | undefined) => void) | undefined;

export function getOfflineDataFault() {
    return fault;
}

export function getIsOfflineDataFaulted() {
    return !!fault;
}

export function setIsOfflineDataFaulted(e: Error | undefined) {
    fault = e;
    if (callback) {
        callback(e);
    }
}

/**
 * Set a callback which will be called whenever the offline data fault changes.
 */
export function setOfflineDataFaultCallback(cb: (e: Error | undefined) => void) {
    callback = cb;
}
