import type { MailboxInfo } from 'owa-client-types';
import type { AccountSource } from '../store/schema/AccountSourceList';

/**
 * Fitlers the list of sources to those that contain mailbox info
 * @param sources List of sources to be filtered
 * @returns MailboxInfo for those sources that have mailbox infos
 */
export function filterToMailboxInfoForAccountSources(sources: AccountSource[]): MailboxInfo[] {
    return sources.map(accountSource => accountSource.mailboxInfo);
}
