import type { MailboxInfo } from 'owa-client-types';
import { trace } from 'owa-trace';
import { default as getAccountBySourceId } from './getAccountBySourceId';
import getAccountSourceListStore from '../store/accountSourceListStore';
import type { AccountSource } from '../store/schema/AccountSourceList';

/**
 * Determines if the two supplies mailbox info are equal
 * @param first First mailbox info to compare
 * @param second Second mailbox info to compare
 * @param skipGuidComparison Optional parameter to skip the auxiliary mailbox guid comparison
 * @returns True if the two mailboxes are equal
 */
export function areMailboxInfoEqual(first: MailboxInfo, second: MailboxInfo): boolean {
    // we want to always start skipping the auxiliary guid comparision, since it is in the email address anyway
    return (
        // We do not compare the indexer value as it is not apart of the MailboxInfo object
        first.type === second.type &&
        first.userIdentity === second.userIdentity &&
        first.mailboxSmtpAddress === second.mailboxSmtpAddress
    );
}

/**
 * Returns the AccountSource associated with the specified MailboxInfo
 * @param mailboxInfo to be used to find the account source
 * @returns The AccountSource associated with the MailboxInfo or undefined if there is no account
 *          source, an undefined it typically returned when the account has been removed
 */
export default function getAccountByMailboxInfo(
    mailboxInfo: MailboxInfo
): AccountSource | undefined {
    if (mailboxInfo.sourceId) {
        // we have a sourceId for the mailbox try it first
        const account = getAccountBySourceId(mailboxInfo.sourceId);
        if (!!account) {
            // We found the account by sourceId return that value
            return account;
        }

        // Fallback to searching based on other MailboxInfo values
    }

    const hasBothValues: boolean = !!mailboxInfo.userIdentity && !!mailboxInfo.mailboxSmtpAddress;
    if (!hasBothValues) {
        trace.info(
            '[source-list-store] MailboxInfo does not have both userIdentity and mailboxSmtpAddress'
        );
    }

    for (const value of getAccountSourceListStore().sourcesBySourceId.values()) {
        if (areMailboxInfoEqual(value.source.mailboxInfo, mailboxInfo)) {
            return value.source;
        }
    }

    return undefined;
}
