import type { StartConfig } from 'owa-shared-start';
import { sharedStart } from 'owa-shared-start';
import { isRunningInMetaOSHub } from 'owa-config';
import { getRunAfterRequests } from './getRunAfterRequests';
import { getRunBeforeStartForHosted } from './getRunBeforeStartForHosted';
import { registerMetaOsAppHandlers } from './registerMetaOsAppHandlers';

export default function start(config: StartConfig): Promise<any> {
    if (isRunningInMetaOSHub()) {
        registerMetaOsAppHandlers();
        config.runBeforeStart = getRunBeforeStartForHosted({ ...config });
    }

    return sharedStart({
        ...config,
        runAfterRequests: getRunAfterRequests(config),
    });
}
