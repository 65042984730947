import type { MailboxInfo } from 'owa-client-types';
import getGlobalSettingsAccount from './getGlobalSettingsAccount';

/**
 * Used to obtain the MailboxInfo of the account where global settings are stored
 * @returns MailboxInfo corresponding to the global settings account
 */
export default function getGlobalSettingsAccountMailboxInfo(): MailboxInfo {
    const account = getGlobalSettingsAccount();
    if (!account.mailboxInfo) {
        throw new Error('Global settings account lacks MailboxInfo');
    }
    return account.mailboxInfo;
}
