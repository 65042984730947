import { safeRequestAnimationFrame } from 'owa-performance';
import { getDelayedReaction } from './delayedReaction';

/**
 * Invoke the provided callback after the next repaint
 *
 * We use both sRAF and setTimeout to ensure the callback is executed after the next repaint.
 * sRAF alone will be invoked before the next repaint and by nesting a setTimeout within it,
 * we'll be invoked after the repaint.
 *
 * When the page is hidden (e.g., in background tab) most browsers stop sending RAF callbacks
 * to improve performance and battery life.  We do not attempt to re-try in this scenario
 * as we would prefer for a datapoint to timeout rather than be executed later and as a conseqeuence,
 * measure the wrong thing.
 *
 * @param callback
 */
export function getNextPaint(callback: (isVisible: boolean) => void) {
    const nextPaintFn = () =>
        safeRequestAnimationFrame((isVisible: boolean) => {
            setTimeout(() => {
                callback(isVisible);
            });
        });

    // If mobx reactions are being delayed, we first need to wait for them to run to trigger render.
    const delayedReaction = getDelayedReaction();
    if (delayedReaction) {
        delayedReaction.then(() => {
            nextPaintFn();
        });
    } else {
        nextPaintFn();
    }
}
