import { getResourcePath } from 'owa-config';
import { addBottleneck } from 'owa-performance';
import type ThemeResources from './types/ThemeResources';
import { LEGACY_PRELOADED_THEME_IDS } from './themeConstants';
import { isTeamsThemeId } from 'owa-teams-theme-utils';

const themeFetchPromises: {
    [themeName: string]: Promise<ThemeResources>;
} = {};

export async function fetchThemeFile(
    themeDataName: string,
    themeSource?: string
): Promise<ThemeResources | null> {
    /*
        We don't want to fetch the theme if
        1. the theme is the base theme, since it is already included with the boot javascript
        2. the theme is teams app theme, since the theme is available in owa-teams-palette package
        3. the theme is copilot theme, since there is not theme resource file for copilot theme
    */
    if (
        themeIsPreloaded(themeDataName) ||
        themeIsCopilot(themeDataName) ||
        isTeamsThemeId(themeDataName)
    ) {
        return null;
    }

    const fetchAndLoad = async () => {
        try {
            const response = await fetch(
                `${getResourcePath()}theme/fabric.color.variables.theme.${themeDataName.toLowerCase()}.json`
            );
            return await response.json();
        } catch (err) {
            return null;
        }
    };

    let resourcePromise = themeFetchPromises[themeDataName];
    if (!resourcePromise) {
        resourcePromise = themeFetchPromises[themeDataName] = fetchAndLoad();
        addBottleneck('tm_s', themeSource || 'n/a');
    }
    return resourcePromise;
}

function themeIsPreloaded(themeDataName: string) {
    return LEGACY_PRELOADED_THEME_IDS.includes(themeDataName);
}

function themeIsCopilot(themeDataName: string) {
    return themeDataName.startsWith('copilot');
}
