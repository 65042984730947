import { setItem, getItem, removeItem } from 'owa-local-storage';

const bootFailureCountKey: string = 'bootFailureCount';
export const bootFailureTimestampKey: string = 'bootFailureTimestamp';

export function getBootFailureCount(): number {
    const bootFailureCount = getItem(window, bootFailureCountKey);
    if (bootFailureCount) {
        return parseInt(bootFailureCount);
    }
    return 0;
}

export function setBootFailureCount(bootCount: number): void {
    setItem(window, bootFailureCountKey, bootCount + '');
    if (bootCount === 0) {
        removeItem(window, bootFailureTimestampKey);
    }
}

export function incrementBootFailureCount() {
    setBootFailureCount(getBootFailureCount() + 1);
    setItem(window, bootFailureTimestampKey, new Date().toUTCString());
}
