import { postMessageWrapper } from 'owa-worker-wrapper';

let isPatched = false;

// weak map from port to the set of listeners so that we only create one listener set per port lifetime
const portListeners: WeakMap<MessagePort, Set<EventListenerOrEventListenerObject>> = new WeakMap();

// effectively a reference count on the message port to keep it in memory if it still has listeners
const retainedPorts: Map<MessagePort, Set<EventListenerOrEventListenerObject>> = new Map();

export function patchComlink() {
    if (!isPatched) {
        isPatched = true;

        const pAddEventListener = MessagePort.prototype.addEventListener;
        const pRemoveEventListener = MessagePort.prototype.removeEventListener;
        const pPostMessage = MessagePort.prototype.postMessage;

        MessagePort.prototype.addEventListener = function (
            type: string,
            listener: EventListenerOrEventListenerObject,
            options?: boolean | AddEventListenerOptions
        ) {
            if (type === 'message') {
                // Comlink Patch for Safari
                const listeners = getListeners(this);
                listeners.add(listener);
            }

            pAddEventListener.call(this, type, listener, options);
        };
        MessagePort.prototype.removeEventListener = function (
            type: string,
            listener: EventListenerOrEventListenerObject,
            options?: boolean | AddEventListenerOptions
        ) {
            if (type === 'message') {
                // Comlink Patch for Safari
                const listeners = getListeners(this);
                listeners.delete(listener);
                if (listeners.size === 0) {
                    retainedPorts.delete(this);
                }
            }

            pRemoveEventListener.call(this, type, listener, options);
        };

        MessagePort.prototype.postMessage = function (
            message: any,
            options?: Transferable[] | StructuredSerializeOptions
        ) {
            postMessageWrapper(this, pPostMessage, message, options);
        };
    }
}

function getListeners(port: MessagePort): Set<EventListenerOrEventListenerObject> {
    let rv = retainedPorts.get(port);
    if (!rv) {
        rv = portListeners.get(port);
        if (!rv) {
            rv = new Set();
            portListeners.set(port, rv);
        }

        retainedPorts.set(port, rv);
    }

    return rv;
}
