import type { RemovedAccount } from 'owa-account-source-list-types';
import type { CoprincipalAccountSource } from '../store/schema/AccountSourceList';

/**
 * Gets the information for the removed account object from the account source and settings for the account
 * @param account The account that is beng removed
 * @param accountSessionSettings
 * @returns
 */
export function toRemovedAccount(account: CoprincipalAccountSource): RemovedAccount {
    return {
        type: 'RemovedAccount',
        sourceType: account.sourceType,
        sourceId: account.sourceId,
        persistenceId: account.persistenceId,
        mailboxInfo: { ...account.mailboxInfo, isRemoved: true },
    };
}
