/*
 * Determine the calendar view type from the pathname to calculate correct date range on the server.
 * If the view type is not mentioned in the URL, or the view type is not one of the supported view types,
 * return 0, which would make substrate use the last used viewtype stored in the calendarViewTypeDesktop.
 * This should be kept in sync with getCalendarViewFromRoute in owa-calendar-router
 */
export function getCalendarViewType(pathname: string): string {
    if (pathname.includes('/day')) {
        return '1'; // CalendarViewTypeEnum.Daily
    } else if (pathname.includes('/week')) {
        return '2'; // CalendarViewTypeEnum.Weekly
    } else if (pathname.includes('/workweek')) {
        return '3'; // CalendarViewTypeEnum.WorkWeek
    } else if (pathname.includes('/month')) {
        return '4'; // CalendarViewTypeEnum.Monthly
    } else {
        return '0';
    }
}
