import type { AccountSource } from '../store/schema/AccountSourceList';
import { BootState } from '../store/schema/BootState';
import getCoprincipalAccountSourcesByBootState from './getCoprincipalAccountSourcesByBootState';
import getAccountSourceListStore from '../store/accountSourceListStore';

export function getCalendarAccountSourcesByBootState(bootState: BootState): AccountSource[] {
    return getCoprincipalAccountSourcesByBootState(bootState).filter(
        accountSource => accountSource.contracts.supportsCalendar
    );
}

export function getCalendarAccountSources(): AccountSource[] {
    return getCalendarAccountSourcesByBootState(BootState.StartupComplete);
}

export function getAllCalendarAccountSources(): AccountSource[] {
    return getAccountSourceListStore().sources.filter(
        accountSource => accountSource.contracts.supportsCalendar
    );
}
