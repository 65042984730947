/**
 * Tracks where the account is in the startip process
 */
export enum BootState {
    /**
     * The startup process has not yet started for the account
     */
    Pending = 'Pending',

    /**
     * The startup process is currently active for the account
     */
    Booting = 'Booting',

    /**
     * This is used in OOBE to indicate that that data has been initialzied but the
     * account is not yet considered to have completed startup
     */
    StartupInitialized = 'StartupInitialized',

    /**
     * The startup data was successfully obtained and processed
     */
    StartupComplete = 'StartupComplete',

    /**
     * An error happened when obtaining or processing the data
     */
    StartupError = 'StartupError',
}
