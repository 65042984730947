import { logStartCoreUsage } from 'owa-analytics-start';

function isISO8859Valid(str: string) {
    return !/[^\u0000-\u00ff]/g.test(str);
}

// Evaluate the header to be ISO-8859-1 char safe before setting it.
// Non-ISO-8859-1 cannot be set on the header. It will cause the headers.Set function to throw the exception.
export function canSetHeaderISO8859Safe(headerName: string, headerValue: string) {
    if (isISO8859Valid(headerValue)) {
        return true;
    } else {
        logStartCoreUsage('ISO8859ValidHeaderForbootFlights', {
            errorHeaderName: headerName,
            errorHeaderValue: headerValue,
        });
        return false;
    }
}
