import { updateServiceConfig } from 'owa-service/lib/config';
import { isNetworkBlocked } from './offlineBoot';

export function setDisableAllRequests(value: boolean) {
    updateServiceConfig({
        disableAllRequests: value,
    });
}

export function disableRequestsWhenOffline() {
    const isBlocked = isNetworkBlocked();
    if (isBlocked) {
        setDisableAllRequests(true);
    }
}
