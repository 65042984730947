import type { MailboxInfo } from 'owa-client-types';
import { isBootFeatureEnabled } from 'owa-metatags';
import type { ApplicationSettingGroup, ApplicationSettings } from 'owa-application-settings';

export function isOwaNetCoreApiEnabled(
    actionName: string,
    getApplicationSettingsCallback?: <TGroup extends ApplicationSettingGroup>(
        group: TGroup,
        mailboxInfo?: MailboxInfo
    ) => ApplicationSettings[TGroup]
) {
    return (
        (isBootFeatureEnabled('fwk-netcore-api') && actionName == 'StartupData') ||
        isNetCoreAPI(actionName, getApplicationSettingsCallback)
    );
}

function isNetCoreAPI(
    actionName: string,
    getApplicationSettingsCallback?: <TGroup extends ApplicationSettingGroup>(
        group: TGroup,
        mailboxInfo?: MailboxInfo
    ) => ApplicationSettings[TGroup]
) {
    const setting = getApplicationSettingsCallback?.('NetCore');
    return (
        !setting?.disabledAPIs.includes(actionName) &&
        (setting?.enabledAPIs.includes(actionName) || setting?.enabledAPIs.includes('*'))
    );
}
