// make sure this is the first import as it is tracking
// the start of the import in webpack
import { getPreImportTime } from './getPreImportTime';

// make sure to have polyfills loaded before anything else
import 'owa-shared-bootstrap/lib/polyfills';

// this is a bit of a hack to fix bundle cycles
// by including this imports here, these files will be in each of the entry points
// mail, native, and oobe. This ensures that
// TODO (OW 260154): Remove this when this entry point is fixed
import 'owa-account-source-list/lib/lazyAppBoot';
import 'owa-client-pie-utils/lib/lazyAppBoot';
import 'one-outlook-suite-header/lib/lazyAppBoot';
import 'owa-freeze-dry/lib/lazyAppBoot';
import 'native-host-push-notifications/lib/lazyAppBoot';
import 'native-local-data-account-support/lib/lazyAppBoot';
import 'native-host-post-boot';

// order matters. Make sure we load owa-start first as it has the polyfills we need for owa-bundling to work
import { start } from 'owa-start';
import type {
    HandleBootErrorFunction,
    StartConfig,
    BootStrategies,
    EditQspFunction,
} from 'owa-shared-start';
import { getBrowserWidth } from 'owa-config';
import { type Module } from 'owa-workloads/lib/store/schema/Module';
import { getServiceWorkerConfig } from 'owa-app-module';
import { lazyMailAppBootstrap } from './lazyFunctions';
import { determineModule } from 'owa-workloads/lib/utils/determineModule';
import type { SessionData } from 'owa-service/lib/types/SessionData';
import getSessionData from 'owa-shared-start/lib/getSessionData';
import {
    getOfflineSessionData,
    lazySaveOfflineSessionData,
    initializeWorker,
} from 'owa-data-worker-client';
import { overrideStartConfig } from './overrideStartConfig';
import { disableRequestsWhenOffline } from 'owa-offline/lib/disableRequestsWhenOffline';
import { type RaceParticipant, networkRace } from 'owa-offline/lib/networkRace';
import { isOfflineBootEnabled } from 'owa-offline/lib/offlineBoot';
import { PassiveMonitoringAriaTenantToken } from 'owa-analytics-start';
import { addBootTiming, addBootCustomData } from 'owa-performance';
import { runAfterInitialRender } from 'owa-bundling-light/lib/utils/delayLoad';
import { GovernPriority } from 'owa-client-types/lib/GovernPriority';
import { type SessionDataWithGraphQL } from 'owa-graph-schema';
import { setSlowNetworkSessionData } from 'owa-network-session-data';

const moduleToAriaToken: {
    [P in Module]?: string;
} = {
    People: '90c31e9f702c4564b120167247d3cd9f-d894a4e5-72f4-4221-ab00-94e1359dfb60-7137',
    FilesHub: '56468f6991c348029c6bba403b444607-7f5d6cd1-7fbe-4ab1-be03-3b2b6aeb3eb4-7696',
};

export async function mailStart(
    runBeforeStart?: (config: StartConfig) => Promise<void>,
    onLoaderRemoved?: () => void,
    handleBootError?: HandleBootErrorFunction,
    extraBootStrategies?: BootStrategies,
    editQsp?: EditQspFunction,
    skipStartupSignal?: boolean
): Promise<void> {
    const mod = determineModule();
    const startConfig: StartConfig = {
        app: mod,
        startupAriaToken: moduleToAriaToken[mod] || PassiveMonitoringAriaTenantToken(),
        runBeforeStart: async config => {
            const preImportTime = getPreImportTime();
            if (preImportTime) {
                addBootTiming('imprt_e');
                addBootTiming('imprt_s', preImportTime);
            }
            disableRequestsWhenOffline();

            if (isOfflineBootEnabled()) {
                initializeWorker();
            }

            return runBeforeStart?.(config);
        },
        overrideBootPromises: () =>
            networkRace(
                'SessionData',
                () => getSessionData(),
                () => getOfflineSessionData(mod) as Promise<SessionData>,
                (networkSD, offlineSD, winner) => {
                    if (winner === 2) {
                        setSlowNetworkSessionData({ networkSD, offlineSD });
                        runAfterInitialRender(
                            () =>
                                lazySaveOfflineSessionData
                                    .importAndExecute(networkSD, offlineSD)
                                    .catch(() => {}),
                            GovernPriority.Notifications
                        );
                    }
                    const offlineData = offlineSD as SessionDataWithGraphQL;
                    if (!!offlineData && !!offlineData.diagnosticData) {
                        if (offlineData.diagnosticData.hasExtractedRange != undefined) {
                            addBootCustomData(
                                'OfflineSessionDataHasEventsRange',
                                !!offlineData.diagnosticData.hasExtractedRange
                            );
                        }
                        if (
                            offlineData.diagnosticData.isExtractedRangeRequestedRange != undefined
                        ) {
                            addBootCustomData(
                                'OfflineSessionDataEventsRangeMatchesRequested',
                                !!offlineData.diagnosticData.isExtractedRangeRequestedRange
                            );
                        }
                        // Other diagnostic data can be addded here
                    }
                }
            ).then(({ value, customData, participants, winner }) => {
                if (winner === 'network' || participants !== 'both') {
                    setSlowNetworkSessionData(null);
                }
                addBootCustomData('SessionDataNetworkRace', customData);
                return value;
            }),
        runAfterRequests,
        loadBpos: true,
        strategies: extraBootStrategies,
        bootstrap: lazyMailAppBootstrap,
        onLoaderRemoved,
        handleBootError,
        cachesToClean: getServiceWorkerConfig(mod)?.app,
        editQsp,
        skipStartupSignal,
    };

    await start({
        ...startConfig,
        ...overrideStartConfig(mod),
    });
}

function setMinWidth() {
    document.body.style.minWidth = '418px';
}

function runAfterRequests() {
    try {
        if (document.readyState != 'loading') {
            setMinWidth();
        } else {
            document.addEventListener('DOMContentLoaded', setMinWidth);
        }

        // Calculating the width of the window now so we can incur the costs of the
        // browser layout in parallel with the requests
        getBrowserWidth();
    } catch {
        // This is an optimization. We don't want boot to fail if this throws an exception
    }
}
