import { setCanaryHeader } from './setCanaryHeader';
import type { HeadersWithoutIterator } from './RequestOptions';
import { correlationVectorHeaderName } from './correlationVector';
import { AuthorizationHeaderName } from './createDefaultHeader';

const ATTEMPT_HEADER: string = 'X-OWA-Attempt';

export default function addFetchRetryHeaders(
    attemptCount: number,
    headers: HeadersWithoutIterator
) {
    // if the headers have a cv header, we should delete it to generate a new one
    if (headers.has(correlationVectorHeaderName)) {
        headers.delete(correlationVectorHeaderName);
    }

    const hasAuthHeader = headers.has(AuthorizationHeaderName);
    if (!hasAuthHeader) {
        setCanaryHeader(headers);
    }

    headers.set(ATTEMPT_HEADER, '' + attemptCount);
}
