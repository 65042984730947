export type ThreadName = 'MAIN_THREAD' | 'ANALYTICS_WORKER_THREAD' | 'DATA_WORKER_THREAD';

let threadName: ThreadName | null = null;

export function setThreadName(name: ThreadName) {
    threadName = name;
}

export function getThreadName() {
    return threadName;
}
