import type { SyncEvent } from 'owa-offline-sync-diagnostics';

let engineState: string | undefined;

let onSyncEvents: ((syncEvents: SyncEvent[]) => unknown) | undefined;

export function recordSyncEvents(syncEvents: SyncEvent[]) {
    // Cache the last reported state so diagnostics can show an accurate
    // state when opened.
    syncEvents.forEach(syncEvent => {
        if (syncEvent.type === 'EngineStateChanged') {
            engineState = syncEvent.data[0];
        }
    });

    if (onSyncEvents) {
        onSyncEvents(syncEvents);
    }
}

export function setOnSyncEvents(value: (syncEvents: SyncEvent[]) => unknown) {
    onSyncEvents = value;
}

export function getLastSyncEngineState(): string | undefined {
    return engineState;
}
