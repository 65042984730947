import type {
    CustomCheckpointNetwork,
    PerformanceDatapointType,
    GQLWaterfallContext,
    GQLWaterfallSettings,
} from 'owa-analytics-types';
import { DataSource } from 'owa-analytics-types';
import { addTimingToWaterfall } from './addTimingToWaterfall';

export async function addTimingsToGQLQuery<GQLQueryReturnType>(
    perfDatapoint: PerformanceDatapointType | CustomCheckpointNetwork | null | undefined,
    settings: GQLWaterfallSettings | null = null,
    gqlCallback: (context: GQLWaterfallContext) => Promise<GQLQueryReturnType>
): Promise<GQLQueryReturnType> {
    if (!perfDatapoint || settings?.isEnabled === false) {
        return gqlCallback({});
    }

    const isPredefined = (perfDatapoint as PerformanceDatapointType)?.isPerformanceDatapoint;
    const customCheckpoint = perfDatapoint as CustomCheckpointNetwork;
    const dp = isPredefined
        ? (perfDatapoint as PerformanceDatapointType)
        : customCheckpoint.datapoint;

    addTimingToWaterfall(
        dp,
        isPredefined,
        'Data_S',
        customCheckpoint?.indexes?.[0],
        customCheckpoint?.name,
        settings?.discardIfDefined
    );

    dp.dataSource = null;

    const context = {
        perfDatapoint: dp,
        waterfallSettings: settings,
    };

    const response = await gqlCallback(context);

    if (dp.dataSource === null && !dp.didExecuteGqlQuery) {
        dp.dataSource = DataSource.NO_QUERY;
    }

    addTimingToWaterfall(
        dp,
        isPredefined,
        'Data_E',
        customCheckpoint?.indexes?.[1],
        customCheckpoint?.name,
        settings?.discardIfDefined
    );

    return response;
}
