import { getWrappedWorker } from '../util/getWrappedWorker';
import { getConfig } from 'owa-service/lib/config';
import type TimeZoneOffsetsType from 'owa-service/lib/contract/TimeZoneOffsetsType';
import type TimeZoneEntry from 'owa-service/lib/contract/TimeZoneEntry';
import { sanitize } from 'owa-data-worker-utils/lib/sanitize';

let offlineTimeZonesPromise: Promise<TimeZoneEntry[] | undefined> | undefined = undefined;
let offlineTimeZoneOffsetsPromise: Promise<TimeZoneOffsetsType[] | undefined> | undefined =
    undefined;

export function initializeOfflineTimezoneData() {
    offlineTimeZonesPromise = getOfflineTimeZones();
    offlineTimeZoneOffsetsPromise = getOfflineTimeZoneOffsets();
}

export function getOfflineTimeZones() {
    if (offlineTimeZonesPromise) {
        const timezonePromise = offlineTimeZonesPromise;
        offlineTimeZonesPromise = undefined;
        return timezonePromise;
    }

    const worker = getWrappedWorker();
    if (!worker?.client) {
        throw new Error('Worker client is not available');
    }

    const config = getConfig();
    const mailboxInfo = config.getGlobalSettingsAccountMailboxInfo?.();
    const databaseId = config.getGlobalSettingsAccountPersistenceId?.();
    if (!mailboxInfo || !databaseId) {
        throw new Error('Can not get a account to resolve database');
    }

    return worker.client.getOfflineTimeZones(sanitize(mailboxInfo), databaseId);
}

export function getOfflineTimeZoneOffsets() {
    if (offlineTimeZoneOffsetsPromise) {
        const timeZoneOffsetsPromise = offlineTimeZoneOffsetsPromise;
        offlineTimeZoneOffsetsPromise = undefined;
        return timeZoneOffsetsPromise;
    }
    const worker = getWrappedWorker();
    if (!worker?.client) {
        throw new Error('Worker client is not available');
    }

    const config = getConfig();
    const mailboxInfo = config.getGlobalSettingsAccountMailboxInfo?.();
    const databaseId = config.getGlobalSettingsAccountPersistenceId?.();
    if (!mailboxInfo || !databaseId) {
        throw new Error('Can not get a account to resolve database');
    }

    return worker.client.getOfflineTimeZoneOffsets(sanitize(mailboxInfo), databaseId);
}
