const EXCHANGE_ONLINE_APP_ID = '00000002-0000-0ff1-ce00-000000000000';

let owaAppId = EXCHANGE_ONLINE_APP_ID;

export function getOwaAppId(): string {
    return owaAppId;
}

export function setOwaAppId(appId: string | null): void {
    owaAppId = appId ?? EXCHANGE_ONLINE_APP_ID;
}
