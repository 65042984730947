import type { MailboxProvider } from 'owa-client-types';
import { resourceTypeLicense } from 'owa-account-source-list-types/lib/utils/sourceIdResourceTypes';
import { SourceIdPartDelimiter } from 'owa-client-types/lib/SourceIdPartDelimiter';

export function getLicensingSourceId(
    accountSourceId: string,
    mailboxProvider: MailboxProvider,
    userPrincipalName: string
): string {
    return [accountSourceId, resourceTypeLicense, mailboxProvider, userPrincipalName].join(
        SourceIdPartDelimiter
    );
}
