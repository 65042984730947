import assert from 'owa-assert/lib/assert';
import { mutatorAction } from 'satcheljs';
import getAccountBySourceId from '../selectors/getAccountBySourceId';
import getAccountSourceListStore from '../store/accountSourceListStore';
import { accountSourceDataTypeChecker } from '../store/schema/AccountSourceList';

/**
 * Update the specified AccountSource in the store
 */
export default mutatorAction(
    'UPDATE_CONNECTED_ACCOUNT_STATE',
    function (sourceId: string, accountState: number): void {
        assert(getAccountBySourceId(sourceId), 'Cannot update account not in store');

        const store = getAccountSourceListStore();
        /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
         * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
         *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
        store.sources.forEach(source => {
            if (
                source.sourceId === sourceId &&
                accountSourceDataTypeChecker.isM365ConnectedMailbox(source)
            ) {
                source.accountState.connectedAccountState = accountState;
            }
        });
    }
);
