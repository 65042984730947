import { LazyBootModule, LazyAction } from 'owa-bundling-light';

const lazyModule = new LazyBootModule(
    () => import(/* webpackChunkName: "AppBootPushNotifications" */ './lazyFunctions')
);

export const lazyUnsubscribeToNativePushNotificationProxy = new LazyAction(
    lazyModule,
    m => m.lazyUnsubscribeToNativePushNotification.importAndExecute
);
