import type { AddonName } from './types/AddonName';
import { trace } from 'owa-trace';
import { logTraceAndEvent } from './logging';

interface Addon {
    name: AddonName;
    execute: (...args: any) => any | Promise<any>;
    isEnabled: boolean;
}

interface AddonQueueItem<ReturnType> {
    name: AddonName;
    args: any[];
    resolve?: (value: ReturnType | PromiseLike<ReturnType>) => void;
    reject?: (e: Error) => void;
    timer?: NodeJS.Timeout;
}

/**
 * Analytics Addons allow to register functionality for each instance of owa-analytics
 */
const Addons = new Map<AddonName, Addon>();

/**
 * Addon Queue is used to store the calls to addons that are not registered yet
 */
const AddonQueue = new Map<AddonName, AddonQueueItem<any>[]>();

/**
 * Keep track of the warning about the addon not being registered. It could be very
 * verbose if we log it every time we try accessing the the addon.
 */
const AddonsAccessedBeforeRegistration = new Set<AddonName>();

/**
 * Keep track of the warning about the addon not being cleared from the queue. It could
 * be very verbose if we log it every time we try accessing the the addon.
 */
const AddonsQueueAccessedAfterRegistration = new Set<AddonName>();

/**
 * Indicates if all the addons are registered.
 */
let isRegistrationComplete: boolean = false;

/**
 * Timeout for the async calls to the addons
 */
const ASYNC_TIMEOUT = 120 * 1000; // 2 minutes

/**
 * Each thread should indicate when they completed registering the addons.
 */
export function setRegistrationCompleted(thread: 'MainThread' | 'DataWorker' | 'AnalyticsWorker') {
    trace.info(
        `[Addon] Analytics Addons registration is complete on the ${thread} thread.`,
        'analytics'
    );
    isRegistrationComplete = true;

    checkIfAddonQueueIsEmpty();
}

/**
 * Register a new Analytics Addon for the current thread
 * @param name
 * @param addon
 */
export function registerAnalyticsAddon(
    name: AddonName,
    addon: (...args: any[]) => any,
    isEnabled?: boolean
): void {
    if (Addons.has(name)) {
        trace.warn(
            `[Addon] Analytics Addon ${name} is already registered in this thread. Ignoring the new registration.`,
            'analytics'
        );
        return;
    }

    Addons.set(name, {
        name,
        execute: addon,
        isEnabled: isEnabled ?? true, // Enable by default
    });

    trace.info(`[Addon] Registered Analytics Addon ${name}.`, 'analytics');

    if (AddonQueue.has(name)) {
        processAddonQueue(name);
        AddonQueue.delete(name);
    }
}

/**
 * Unregister an existing Addon
 * @param name
 */
export function unregisterAnalyticsAddon(name: AddonName): void {
    if (Addons.has(name)) {
        Addons.delete(name);
    }
}

/**
 * Returns the analytics addon for the current thread
 * @param name
 * @param callback
 * @returns
 */
export function getAnalyticsAddon<ReturnType extends unknown>(
    name: AddonName,
    disableQueueRegistrationWarning?: boolean
) {
    return {
        name,
        executeNow: (...args: any[]): ReturnType | Promise<ReturnType> => executeNow(name, args),
        executeWhenReady: (...args: any[]): void =>
            executeWhenReady(name, args, disableQueueRegistrationWarning),
        executeWhenReadyAsync: (...args: any[]): Promise<ReturnType | null> =>
            executeWhenReadyAsync<ReturnType>(name, args, disableQueueRegistrationWarning),
        isRegistered: Addons.has(name),
        isRegistrationComplete,
        isEnabled: Addons.get(name)?.isEnabled,
    };
}

export function getCurrentState() {
    return isRegistrationComplete ? 'RegistrationComplete' : 'RegistrationNotComplete';
}

/**
 * FOR TESTS ONLY
 */
export function testOnly_resetAnalyticsAddons() {
    Addons.clear();
    AddonQueue.clear();
    AddonsAccessedBeforeRegistration.clear();
    AddonsQueueAccessedAfterRegistration.clear();
    isRegistrationComplete = false;
}

/**
 * FOR TESTS ONLY
 */
export function testOnly_getTestValues() {
    return {
        Addons,
        AddonQueue,
        AddonsAccessedBeforeRegistration,
        AddonsQueueAccessedAfterRegistration,
        isRegistrationComplete,
    };
}

function executeNow<ReturnType extends unknown>(name: AddonName, args: any[]) {
    if (Addons.has(name)) {
        return executeAddon(name, args) as ReturnType | Promise<ReturnType>;
    } else {
        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * Error constructor names can only be a string literals.
         *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
        throw new Error(
            `Analytics Addon ${name} is not registered in this thread. You can use 'executeWhenReady' or 'executeWhenReadyAsync' if you call the addon before registring it.`
        );
    }
}

function executeWhenReady(
    name: AddonName,
    args: any[],
    disableQueueRegistrationWarning?: boolean
): void {
    if (Addons.has(name)) {
        executeAddon(name, args);
    } else {
        const list: AddonQueueItem<void>[] = AddonQueue.get(name) || [];
        list.push({ name, args });
        AddonQueue.set(name, list);

        logAddonsQueueAccessedAfterRegistration(name, disableQueueRegistrationWarning);
    }
}

function executeWhenReadyAsync<ReturnType>(
    name: AddonName,
    args: any[],
    disableQueueRegistrationWarning?: boolean
): Promise<ReturnType | null> {
    if (Addons.has(name)) {
        trace.info(
            ` Not adding promise for Analytics Addon ${name} (already registered)`,
            'analytics'
        );

        return executeAddon(name, args);
    } else {
        trace.info(` Adding promise for Analytics Addon ${name}`, 'analytics');

        return new Promise<ReturnType | null>((resolve, reject) => {
            // Set timer to avoid having hagging promises if the addons is never registered
            const timer = setTimeout(() => {
                const timeoutMessage = `Timeout waiting for Analytics Addon ${name} to be registered.`;
                logTraceAndEvent(
                    name,
                    timeoutMessage,
                    'Analytics_Addons_ResgistrationPromiseTimeout',
                    { addon: name }
                );
                resolve(null);
            }, ASYNC_TIMEOUT);

            const list: AddonQueueItem<ReturnType>[] = AddonQueue.get(name) || [];
            list.push({ name, args, resolve, reject, timer });
            AddonQueue.set(name, list);

            logAddonsQueueAccessedAfterRegistration(name, disableQueueRegistrationWarning);
        });
    }
}

function executeAddon(name: AddonName, args: any[]) {
    if (Addons.get(name)?.isEnabled === false) {
        trace.warn(
            `[Addon] Analytics Addon ${name} was called with "executeNow" but is disabled.`,
            'analytics'
        );
        return null;
    }

    return Addons.get(name)?.execute(...args);
}

function logAddonsQueueAccessedAfterRegistration(
    name: AddonName,
    disableQueueRegistrationWarning?: boolean
) {
    /**
     * If the registration is complete, we should not be trying to add items to the queue. This might
     * happen if we forget to register an event. We log a trace and event so we can check if this is
     * the expected result.
     */
    if (
        isRegistrationComplete &&
        !AddonsQueueAccessedAfterRegistration.has(name) &&
        !disableQueueRegistrationWarning
    ) {
        AddonsQueueAccessedAfterRegistration.add(name);
        logTraceAndEvent(
            name,
            `Analytics Addon ${name} was added to a queue after registration.`,
            'Analytics_Addons_QueueAccessedAfterRegistration',
            {
                details:
                    "This might be expected if we voluntarily access an addon that isn't registered in the current thread.",
                state: getCurrentState(),
            }
        );
    }
}

function processAddonQueue(name: AddonName) {
    trace.info(` Processing Queue for Analytics Addon ${name}.`, 'analytics');
    const addonQueueItems = AddonQueue.get(name);

    if (addonQueueItems) {
        for (const item of addonQueueItems) {
            const result = executeNow(name, item.args) as any | Promise<any>;

            if (item?.timer) {
                clearTimeout(item.timer);
            }

            // If it's a promise, try resolving it and calling the promise callbacks
            result
                ?.then?.((value: any) => {
                    trace.info(` Resolving promise for Analytics Addon ${name}.`, 'analytics');
                    item.resolve?.(value);
                })
                ?.catch?.((e: Error) => {
                    item.reject?.(e);
                });
        }
    }
}

function checkIfAddonQueueIsEmpty() {
    for (const [name] of AddonQueue.entries()) {
        logTraceAndEvent(
            name,
            `Analytics Addon ${name} wasn't cleared from queue after registration.`,
            'Analytics_Addons_AddonQueueNotCleared',
            { state: getCurrentState() }
        );

        AddonQueue.delete(name);
    }
}
