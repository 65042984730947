import { getQueryStringParameters } from 'owa-querystring';
import { getWindowData } from 'owa-window-data';
import { scrubForPii } from 'owa-config';

// this returns an encoded version of the current path
// but makes sure it is not longer than 100 characters
export default function getRefUrl(): string | undefined {
    let queryString = '?';
    const windowData = getWindowData();
    const urlParams = getQueryStringParameters(window.location);

    Object.keys(urlParams).forEach((key: string) => {
        switch (key) {
            case 'bO':
            case 'version':
                queryString += `${key}=${urlParams[key]}&`;
                break;
            default:
                queryString += `${key}=val&`;
        }
    });

    let refUrl = scrubForPii(
        windowData.location.host +
            windowData.location.pathname +
            (queryString.length > 1 ? queryString.slice(0, -1) : '')
    );

    if (refUrl && refUrl.length > 100) {
        refUrl = refUrl.substr(0, 100);
    }
    return refUrl ? encodeURIComponent(refUrl) : undefined;
}
