let currentClientType: string | undefined;

/**
 * Sets the client type for the host app.
 * @example Does not include validation for supported client types, each caller will need to do that on their own.
 */
export function setHostClientType(clientType: string) {
    currentClientType = clientType;
}

export function getHostClientType() {
    return currentClientType;
}
