export { addAnalyticsCallbacks, getCachedInfo, stopCachingInfo } from './analyticsCallbacks';
export {
    logStartUsage,
    logStartCoreUsage,
    logStartGreyError,
    logStartCoreGreyError,
} from './logStartUsage';
export { isPageTimingSupported, isResourceTimingSupported } from './performanceTiming';
import { getAriaTenantToken } from 'owa-analytics-shared/lib/settings/getAriaTenantToken';

export const PassiveMonitoringAriaTenantToken = () => getAriaTenantToken(null, 'OutlookWeb');
