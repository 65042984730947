/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * Make sure this isn't imported in the analytics worker
 *	> 'owa-local-storage' import is restricted from being used. localStorage is not available in the analytics worker [analytics-worker-lint] */
import { setItem, getItem } from 'owa-local-storage';
import type { LogicalRingEnum } from 'owa-client-pie/lib/outlookwebcontext.g';

const LogicalRingKey = 'LogicalRing';
export type LogicalRing = keyof typeof LogicalRingEnum;

let logicalRing: LogicalRing | null;
export function setLogicalRing(ring: LogicalRing) {
    if (!!logicalRing && logicalRing === ring) {
        return;
    }
    logicalRing = ring;
    setItem(self, LogicalRingKey, ring);
}

export function getLogicalRing(): LogicalRing | null {
    if (!logicalRing) {
        logicalRing = getItem(self, LogicalRingKey) as LogicalRing | null;
    }

    return logicalRing;
}
