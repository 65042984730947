const isWindowDefined = typeof self === 'object';

/**
 * Returns time elapsed since the beginning of the current session in seconds.
 */
export function getSessionElapseTime() {
    if (isWindowDefined && self.performance && self.performance.now) {
        return Math.floor(self.performance.now() / 1000);
    }

    return null;
}
