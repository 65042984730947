/**
 * Returns a promise that would wait for a given time before resolving a value
 *
 * @param value value to resolve the promise with
 * @param time time in milliseconds to delay fulfillment
 */
export function delay<T>(value: T, time: number): Promise<Awaited<T>> {
    // prevent unhandled rejections for promise values
    if (Promise.resolve(value) === value) {
        // since we're creating a separate delayed promise
        // any unhandled rejection should only come from that promise
        // and not the original one.
        (value as Promise<any>).catch(() => {});
    }
    return new Promise<Awaited<T>>(resolve => {
        setTimeout(resolve, time, value);
    });
}
