import getAccountSourceListStore from '../store/accountSourceListStore';
import type { AccountSource } from '../store/schema/AccountSourceList';
import { BootState } from '../store/schema/BootState';
import getCoprincipalAccountSourcesByBootState from './getCoprincipalAccountSourcesByBootState';

export function getMailAccountSourcesByBootState(bootState: BootState): AccountSource[] {
    return getCoprincipalAccountSourcesByBootState(bootState).filter(
        accountSource => accountSource.contracts.supportsMail
    );
}

export function getMailAccountSources(): AccountSource[] {
    return getMailAccountSourcesByBootState(BootState.StartupComplete);
}

export function getAllMailAccountSources(): AccountSource[] {
    return getAccountSourceListStore().sources;
}
