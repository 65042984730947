import { trace } from 'owa-trace';
import { mutatorAction } from 'satcheljs';
import getAccountSourceListStore from '../store/accountSourceListStore';
import type { BootState } from '../store/schema/BootState';
import type { BootStateData } from '../store/schema/BootStateData';
import { accountRankTypeChecker } from '../store/schema/AccountSourceList';

export default mutatorAction(
    'SET_COPRINCIPAL_ACCOUNT_BOOT_STATE',
    function (sourceId: string, state: BootState, data?: BootStateData): void {
        /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
         * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
         *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
        getAccountSourceListStore().sources.forEach(account => {
            if (accountRankTypeChecker.isCoprincipal(account) && account.sourceId === sourceId) {
                account.bootState = state;
                account.bootStateData = data;

                trace.info(
                    `[source-list-store] Setting coprincipal account boot state to ${account.bootState}`
                );
            }
        });
    }
);
