let hostedScenario = '';

export function getHostedScenario() {
    return hostedScenario;
}

export function setHostedScenario(scenario: string) {
    if (hostedScenario) {
        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * Error constructor names can only be a string literals.
         *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
        throw new Error('Hosted scenario has already been set to ' + hostedScenario);
    }

    hostedScenario = scenario;
}
