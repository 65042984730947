import { default as joinPath, ensureLeadingSlash, ensureTrailingSlash } from './joinPath';
import { getHostLocation } from './hostLocation';
import getRootVdirName from './getRootVdirName';

export default function getFullPathForAuthRedirect(rootPath: string): string {
    let scopedPath = rootPath;
    const windowUrlPath = getHostLocation()?.pathname;

    if (windowUrlPath) {
        // Normalize by trimming the slashes from start and end of windowUrlPath after separated from vDir.
        const vDir = getRootVdirName(true) || '';
        const pathSegments = windowUrlPath.split('/').filter(segment => segment);

        // Split the vDir by '/' to handle 'hosted/' cases
        /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
         * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
         *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
        vDir.split('/').forEach(vDirSegment => {
            const vDirIndex = pathSegments.indexOf(vDirSegment);
            if (vDirIndex > -1) {
                pathSegments.splice(vDirIndex, 1);
            }
        });
        const postVdirPath = pathSegments.join('/');
        scopedPath = joinPath(scopedPath, postVdirPath);
    }
    return ensureLeadingSlash(ensureTrailingSlash(scopedPath));
}

export function trimSlashes(str: string) {
    return str.replace(/^\/+|\/+$/g, '');
}
