const emeaRegions = [
    'CHE',
    'EUR',
    'DEU',
    'GBR',
    'FRA',
    'ZAF',
    'ARE',
    'SWE',
    'NOR',
    'QAT',
    'ISR',
    'POL',
    'ESP',
    'ITA',
    'ROU',
    'AUT',
];

// "EMEA" is a shorthand designation meaning Europe, the Middle East and Africa.
// It includes Forests that are located in the countries from these regions.
export function isEMEARegion(forest: string | undefined): boolean {
    // In some cases Forest is not populated, and it affects setting a correct data boundry for user.
    // Fallback to EMEA reagion if forest is not set
    // TODO: https://outlookweb.visualstudio.com/DefaultCollection/Outlook%20Web/_workitems/edit/214518
    if (!forest) {
        return true;
    }

    const machineRegion: string = forest?.length > 3 ? forest.substring(0, 3) : forest;

    return emeaRegions.indexOf(machineRegion.toUpperCase()) >= 0;
}
