import type { MailboxInfo } from 'owa-client-types';
import { isSameCoprincipalAccountMailboxAndIndexer } from 'owa-client-types';
import getAccountSourceListStore from '../store/accountSourceListStore';

/**
 * DEPRECATED: Use getMailboxInfoByAccountKey() instead
 *
 * Returns the coprincipal account based on the indexer value for the mailbox
 * @param indexer The indexer value for the account
 * @returns MailboxInfo if a account is the specified indexer if found or undefined
 */
export default function getMailboxInfoByIndexerValue(indexer: string): MailboxInfo | undefined {
    return getAccountSourceListStore().sources.filter(account =>
        isSameCoprincipalAccountMailboxAndIndexer(account.mailboxInfo, indexer)
    )[0]?.mailboxInfo;
}
