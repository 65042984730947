import assert from 'owa-assert/lib/assert';
import type { MailboxInfo } from 'owa-client-types';
import getAccountSourceListStore from '../store/accountSourceListStore';
import getSelectedAccount from './getSelectedAccount';

export default function getSelectedAccountMailboxInfo(): MailboxInfo {
    const selectedAccount = getSelectedAccount();

    assert(
        selectedAccount,
        'No account is selected as the global settings account',
        `cnt=${getAccountSourceListStore().sources.length} ss=${typeof getAccountSourceListStore()
            .globalSettingsSourceId} sa=${typeof selectedAccount}`
    );

    assert(
        selectedAccount.mailboxInfo,
        'Global settings account lacks MailboxInfo',
        `cnt=${getAccountSourceListStore().sources.length} mi=${typeof selectedAccount.mailboxInfo}`
    );

    return selectedAccount.mailboxInfo;
}
