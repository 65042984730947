import type { MailboxInfo } from 'owa-client-types';
import { BootState } from '../store/schema/BootState';
import { filterToMailboxInfoForAccountSources } from './filterToMailboxInfoForAccountSources';
import getCoprincipalAccountSourcesByBootState from './getCoprincipalAccountSourcesByBootState';

/**
 * Get MailboxInfo for the accounts that support settings
 * @returns MailboxInfos for the accounts that support settings
 */
export default function getMailboxInfoForSettingsAccounts(): MailboxInfo[] {
    return filterToMailboxInfoForAccountSources(
        getCoprincipalAccountSourcesByBootState(BootState.StartupComplete).filter(
            accountSource => accountSource.contracts.supportsSettings
        )
    );
}
