import { logUsage } from '../analytics/analytics';

/**
 * The serialize function will replace the existing value with an object that contains
 * the issue type (so we know how to deserialize it) and the serialized value.
 */
export function serialize(obj: any): any {
    try {
        return JSON.parse(JSON.stringify(obj, customStringify));
    } catch (e) {
        logUsage?.('ObjectSerializationFailed', { message: e.message, stack: e.stack });

        return null;
    }
}

function customStringify(_key: string, value: any) {
    if (value instanceof Error) {
        return stringifyError(value);
    } else if (typeof value?.then === 'function') {
        return undefined;
    }

    return value;
}

function stringifyError(value: any) {
    const error: {
        [key: string]: any;
    } = {};
    const errorKeys = Object.getOwnPropertyNames(value);

    for (const name of errorKeys) {
        const isNumber = typeof value[name] === 'number';
        const isString = typeof value[name] === 'string';
        if (value[name] || isNumber) {
            error[name] = isString || isNumber ? value[name] : JSON.stringify(value[name]);
        }
    }

    return error;
}
