import type { PerformanceDatapointType } from 'owa-analytics-types';
import { getNextPaint } from '../utils/getNextPaint';

export function addTimingsAndEndDatapointOnRender(
    perfDatapoint: PerformanceDatapointType,
    logOnly?: boolean,
    customStartTime?: number
) {
    addComponentStartRenderingTiming(perfDatapoint, customStartTime);
    addComponentEndRenderingTiming(perfDatapoint, logOnly, undefined);
}

export function addComponentStartRenderingTiming(
    perfDatapoint: PerformanceDatapointType,
    customTime?: number
) {
    perfDatapoint.addToPredefinedWaterfall('Render_S', true, customTime);
}

export function addComponentEndRenderingTiming(
    perfDatapoint: PerformanceDatapointType,
    logOnly?: boolean,
    callback?: () => void
) {
    perfDatapoint.addCallbackResolvedAfterNextPaint(() => {
        perfDatapoint.addToPredefinedWaterfall('Render_E', true);
    });

    if (!logOnly) {
        perfDatapoint.setEndPending();
    }

    if (callback) {
        getNextPaint(() => callback());
    }
}
