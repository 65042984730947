import type { CustomDataMap } from 'owa-analytics-types';
import type { AddonName } from './types/AddonName';
import { trace } from 'owa-trace';

let eventQueue: Array<{
    name: string;
    data?: CustomDataMap;
    error?: Error;
    originalData?: CustomDataMap;
}> = [];

/**
 * This package cannot read owa-analytics so we need to add a reference to the
 * logging function we want to use.
 */
export let logGreyError: (name: string, error?: Error, data?: CustomDataMap) => void;

/**
 * Each thread should indicate when they completed registering the addons.
 */
export function setGreyErrorFunc(
    logGreyErrorFunction: (name: string, error?: Error, data?: CustomDataMap) => void
) {
    logGreyError = logGreyErrorFunction;

    if (eventQueue.length > 0) {
        processEventQueue();
    }
}

export function logTraceAndEvent(
    addon: AddonName,
    traceMessage: string,
    eventName: string,
    customData?: CustomDataMap
) {
    trace.warn(`[Addon] ${traceMessage}`, 'analytics');

    /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
     * Error constructor names can only be a string literals.
     *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
    const error = new Error(eventName);

    const data = { addon, ...customData };

    if (logGreyError) {
        /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
         * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
         *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
        logGreyError(eventName, error, data);
    } else {
        eventQueue.push({ name: eventName, data, error });
    }
}

function processEventQueue() {
    trace.warn(`[Addon] Processing event queue. ${eventQueue.length} events.`, 'analytics');

    for (const { name, error, data } of eventQueue) {
        /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
         * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
         *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
        logGreyError(name, error, data);
    }

    eventQueue = [];
}
