import type { MailboxInfo } from 'owa-client-types';
import getCoprincipalAccountForMailboxInfo from './getCoprincipalAccountForMailboxInfo';

/**
 * Returns the Coprincipal MailboxInfo associated with the supplied MailboxInfo. This may be the MailboxInfo
 * passed in, in the case where the MailboxInfo is a Coprincipal MailboxInfo, or it could be the MailboxInfo
 * that the resource MailboxInfo is associated with.
 * The lookup is done by indexer value.
 * @param mailboxInfo Identifies a mailbox source
 * @returns MailboxInfo of the associated Coprincipal account, or undefined
 */
export default function getCoprincipalMailboxInfoForMailboxInfo(
    mailboxInfo: MailboxInfo
): MailboxInfo | undefined {
    return getCoprincipalAccountForMailboxInfo(mailboxInfo)?.mailboxInfo;
}
