let pageTimingSupported: boolean;
export function isPageTimingSupported(): boolean {
    if (pageTimingSupported == undefined) {
        pageTimingSupported = typeof self !== 'undefined' && self.performance?.timing != null;
    }

    return pageTimingSupported;
}

let resourceTimingSupported: boolean;
export function isResourceTimingSupported(): boolean {
    if (resourceTimingSupported == undefined) {
        resourceTimingSupported =
            typeof self !== 'undefined' && self.performance?.getEntriesByName != null;
    }

    return resourceTimingSupported;
}
