import { getQueryStringParameter } from 'owa-querystring';
import { getEnhancedHostSessionId } from './enhancedHost';
import { newGuid } from './newGuid';

let sessionId: string;

export function getSessionId(): string {
    if (!sessionId) {
        sessionId = getEnhancedHostSessionId() || getQueryStringParameter('sessionId') || newGuid();
    }
    return sessionId;
}

export function overrideSessionId(value: string) {
    sessionId = value;
}
