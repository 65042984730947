import { trace } from 'owa-trace';
import type { PerformanceDatapointType, LogUsageFunction } from 'owa-analytics-types';

export let logUsage: LogUsageFunction | undefined;
export let PerformanceDatapoint: PerformanceDatapointType | undefined;

export function setAnalyticsFunctions(
    _logUsage?: LogUsageFunction,
    _PerformanceDatapoint?: PerformanceDatapointType
) {
    const loggingDisabled = !logUsage && !PerformanceDatapoint;

    if (_logUsage && !logUsage) {
        logUsage = _logUsage;
    }
    if (_PerformanceDatapoint && !logUsage) {
        PerformanceDatapoint = _PerformanceDatapoint;
    }

    if (loggingDisabled) {
        trace.info(
            `PostMessageWrapperEnabled. logUsage set: ${!!logUsage}. PerformanceDatapoint set: ${!!PerformanceDatapoint}`,
            'analytics'
        );
    }
}
