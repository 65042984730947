import type { AccountSource } from '../store/schema/AccountSourceList';
import type { BootState } from '../store/schema/BootState';
import { accountRankTypeChecker } from '../store/schema/AccountSourceList';
import getAccountSourceListStore from '../store/accountSourceListStore';

/**
 * Obtains the list of account sources that are coprincipal accounts and which have a matching boot-state
 * @returns List of accounts
 */
export default function getCoprincipalAccountSourcesByBootState(
    bootState: BootState
): AccountSource[] {
    return getAccountSourceListStore().sources.filter(
        accountSource =>
            accountRankTypeChecker.isCoprincipal(accountSource) &&
            accountSource.bootState === bootState
    );
}
