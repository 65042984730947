import { getWindowData } from 'owa-window-data';
import { isRunningOnWorker } from 'owa-config';

export function getWorkerSafeUrl(url: RequestInfo) {
    if (isRunningOnWorker()) {
        // running on a worker -- need to make sure all urls are absolute
        const origin = getWindowData().location.origin;
        if (typeof url === 'string') {
            url = new URL(url, origin).toString();
        } else {
            url = {
                ...url,
                url: new URL(url.url, origin).toString(),
            };
        }
    }

    return url;
}
