import type { CoprincipalAccount } from 'owa-account-storage-types';

/**
 * Accounts are created from data persisted between sessions. This data is considered as
 * external data in the account source list store.
 */
export enum ExternalDataType {
    None,
    CoprincipalAccount,
}

/**
 * External data associated with accounts will be stored in a structure derived from
 * this type
 */
export interface ExternalData {
    type: ExternalDataType;
}

/**
 * The CoprincipalAccountExternalData structure contains data that is based off of the
 * CoprincipalAccount storage data
 */
export interface CoprincipalAccountExternalData extends ExternalData {
    type: ExternalDataType.CoprincipalAccount;
    account: CoprincipalAccount;
}

/**
 * Determines if the external data is a coprincipal account data
 * @param data Specifies the external data to be checked
 * @returns True if it is CoprincipalAccountExternalData false otherwise
 */
export function isCoprincipalAccountExternalData(
    data: ExternalData
): data is CoprincipalAccountExternalData {
    return data.type === ExternalDataType.CoprincipalAccount;
}
