import type { MailboxInfo } from 'owa-client-types';
import { getMailAccountSources } from './getMailAccountSources';
import { filterToMailboxInfoForAccountSources } from './filterToMailboxInfoForAccountSources';

/**
 * Get MailboxInfo for the accounts syncing mail
 * @returns MailboxInfos for the accounts that are syncing mail
 */
export default function getMailboxInfoForMailAccounts(): MailboxInfo[] {
    return filterToMailboxInfoForAccountSources(getMailAccountSources());
}
