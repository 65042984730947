export type DatetimeBootstrapOptions = {
    waitForDateTimeStore: boolean;
    shouldInitializeTimeZoneAnonymously: boolean;
};

const bootstrapOptions: DatetimeBootstrapOptions = {
    waitForDateTimeStore: false,
    shouldInitializeTimeZoneAnonymously: false,
};

export function setDatetimeBootstrapOptions(o: Partial<DatetimeBootstrapOptions>) {
    bootstrapOptions.waitForDateTimeStore = !!o.waitForDateTimeStore;
    bootstrapOptions.shouldInitializeTimeZoneAnonymously = !!o.shouldInitializeTimeZoneAnonymously;
}

export function getDatetimeBootstrapOptions() {
    return bootstrapOptions;
}
