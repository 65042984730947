import { getConfig } from './config';
import type { IdType } from './RequestOptions';
import type { MailboxInfo } from 'owa-client-types';

type SimpleHeaders = {
    [key: string]: string;
};

const idTypeKey = 'IdType';

export function addIdTypePreference(
    headers: Headers | SimpleHeaders,
    idType: IdType,
    mailboxInfo?: MailboxInfo
) {
    const config = getConfig();
    if (idType == 'ImmutableId') {
        if (config.isFeatureEnabled?.('fwk-immutable-ids', mailboxInfo)) {
            tryAddIdTypePreference(headers, idType);
        }
    } else {
        tryAddIdTypePreference(headers, idType);
    }
}

function tryAddIdTypePreference(headers: Headers | SimpleHeaders, idTypeValue: IdType) {
    const existingPrefer = getCurrentPreferHeaderValue(headers);
    // Only add the header if there is no existing headers or if the existing one is not IdType
    if (!existingPrefer || existingPrefer.indexOf(idTypeKey) < 0) {
        const idTypeKeyValue = `${idTypeKey}="${idTypeValue}"`;
        const prefer = buildPreferHeader(idTypeKeyValue, existingPrefer);
        if (isHeaders(headers)) {
            headers.set('prefer', prefer);
        } else {
            headers['prefer'] = prefer;
        }
    }
}

function buildPreferHeader(newPreferEntry: string, existingPrefer: string | null) {
    return existingPrefer ? `${existingPrefer}, ${newPreferEntry}` : newPreferEntry;
}

function getCurrentPreferHeaderValue(headers: Headers | SimpleHeaders) {
    return isHeaders(headers) ? headers.get('prefer') : headers['prefer'];
}

function isHeaders(headers: Headers | SimpleHeaders): headers is Headers {
    return !!headers.get && !!headers.set;
}
