/**
 * Account Key is a string that uniquely identifies the account a mailbox is tied to.
 *
 * We use this type to ensure that we are not passing around arbitrary strings as account keys.
 * Most specifically, userIdentity is a string that is used as an account key in many places,
 * and we want to make sure that we are not passing around userIdentity as an account key.
 */
type AccountKey = string & {
    __brand: 'MailboxInfo Account Key';
};

export const defaultAccountKey: AccountKey = 'default_emptyAccountKey' as AccountKey;

export default AccountKey;
