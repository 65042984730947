import { getCdnUrl } from 'owa-config';
import { type HttpStatusCode } from 'owa-http-status-codes';

const maxRetries = 3;
const defaultTimeout = 60000;
const cacheFetchPromises: {
    [url: string]: Promise<string>;
} = {};

export async function fetchThemeBackgroundImage(imageUrl: string): Promise<string | null> {
    const resourceUrl = resolveImageUrl(imageUrl);

    const timeoutPromise = (timeout: number): Promise<Response> =>
        new Promise((_, reject) =>
            setTimeout(() => reject(new Error('Request timed out')), timeout)
        );

    const fetchWithTimeout = (url: string, timeout: number): Promise<Response> =>
        Promise.race([fetch(url), timeoutPromise(timeout)]);

    const attemptFetch = async (): Promise<string> => {
        let retryCount = 0;
        while (retryCount < maxRetries) {
            try {
                retryCount++;
                const response = await fetchWithTimeout(resourceUrl, defaultTimeout);
                if (response?.status && [200].indexOf(response.status) !== -1) {
                    const blob = await response.blob();
                    const objectURL = URL.createObjectURL(blob);
                    return objectURL;
                }
            } catch (error) {
                if (retryCount >= maxRetries) {
                    throw error;
                }
            }
        }
        throw new Error('Fetch theme background image: Operation stopped or max retries reached');
    };

    function resolveImageUrl(url: string) {
        return `${getCdnUrl()}${url}`;
    }

    let fetchPromise = cacheFetchPromises[imageUrl];
    if (!fetchPromise) {
        fetchPromise = attemptFetch();

        // if the promise is rejected, then let's do a new fetch
        // if the promise is rejected on the second attempt, then let's remove it from the cache
        cacheFetchPromises[imageUrl] = fetchPromise = fetchPromise
            .catch(() => attemptFetch())
            .catch(error => {
                delete cacheFetchPromises[imageUrl];
                throw error;
            });
    }

    return fetchPromise;
}
