import type { CustomDataMap } from 'owa-analytics-types';
import { logStartGreyError } from 'owa-analytics-start';

/**
 * Defines the data that is collected with each step
 */
interface StepData {
    sequence: number;
    data?: CustomDataMap;
}

/**
 * To make sure we do not have duplicate keys we append a suffix to make
 * keys unique. This is the limit after which we will log a failure and
 * not look for additional suffixes.
 */
const maxSuffixLimit = 25;

/**
 * We log the number of steps that we have been captured using the stepCountName value
 */
const stepCountName = 'steps';

/**
 * For code that takes multiple steps to accomplish a task it can be useful for telemetry
 * to report the steps that were taken either until a failure happened or the code successfully
 * completed. The StepsCustomData class allows for code to track those steps and then return them
 * as a CustomDataMap, via the getCustomDataMapForSteps method, that can be used in telemetry.
 */
export default class StepsCustomData {
    // Tracks the steps that have been taken
    private steps: Map<string, StepData>;
    private suffixLimitReached: Set<string>;

    constructor() {
        this.steps = new Map<string, StepData>();
        this.suffixLimitReached = new Set<string>();
    }

    /**
     * Helper method to build a unique key name based on a name and a suffix
     * @param name Name of the step
     * @param suffix Suffix used to make unique keys names when the value already exists
     * @returns Suffixed (if needed) name
     */
    private getKeyName(name: string, suffix: number): string {
        return suffix ? `${name}_${suffix}` : name;
    }

    /**
     * Adds a step and optionally the data associated with that step
     * @param name Name of the step
     * @param data Data assocaited with the step
     */
    public addStep(name: string, data?: CustomDataMap): void {
        if (this.suffixLimitReached.has(name)) {
            // we previously reached the limit
            return;
        }

        const nextSequence = this.steps.size;

        // Require a suffix when the name matches the stepCountName
        const startingSuffix = name == stepCountName ? 1 : 0;
        for (let suffix: number = startingSuffix; suffix < maxSuffixLimit; ++suffix) {
            if (!this.steps.has(this.getKeyName(name, suffix))) {
                this.steps.set(this.getKeyName(name, suffix), { sequence: nextSequence, data });
                return;
            }
        }

        // We have exceeded the maximum step limit we want to log this and
        // then put it in the list of the steps that were exceeded.
        const error = new Error('StepsSuffixLimitReached');
        /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
         * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
         *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
        logStartGreyError(error.message, error, {
            _n: name,
            ...this.getCustomDataMapForSteps(),
        });

        this.suffixLimitReached.add(name);
        return;
    }

    /**
     * Gets the custom data for the recorded steps
     * @returns CustomDataMap for the recorded steps
     */
    public getCustomDataMapForSteps(): CustomDataMap {
        const miscData: CustomDataMap = {};
        miscData[stepCountName] = this.steps.size;
        /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
         * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
         *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
        this.steps.forEach((value: StepData, key: string) => {
            miscData[key] = value.sequence;
            if (value.data) {
                Object.keys(value.data).forEach((dataKey: string) => {
                    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
                     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
                     *	> Forbidden non-null assertion. */
                    miscData[`${key}_${dataKey}`] = value.data![dataKey];
                });
            }
        });

        return miscData;
    }
}
