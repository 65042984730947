import type { TraceErrorObject } from 'owa-trace';
import type { RequestOptions } from './RequestOptions';
import type MailboxRequestOptions from './MailboxRequestOptions';
import { getConfig } from './config';
import { getWorkerSafeUrl } from './getWorkerSafeUrl';

export default function throttledFetch(
    url: RequestInfo,
    init: RequestOptions | MailboxRequestOptions | Promise<RequestOptions | MailboxRequestOptions>
): Promise<Response> {
    // check if it is a promise to see if we have to await for the request options to come back
    if ((<Promise<RequestOptions | MailboxRequestOptions>>init).then) {
        return (<Promise<RequestOptions | MailboxRequestOptions>>init).then(i =>
            internalFetch(url, i)
        );
    }

    return internalFetch(url, <RequestOptions | MailboxRequestOptions>init);
}

function internalFetch(
    url: RequestInfo,
    init: RequestOptions | MailboxRequestOptions
): Promise<Response> {
    url = getWorkerSafeUrl(url);

    const config = getConfig();
    if (config.disableAllRequests) {
        const error: TraceErrorObject = new Error(
            'Service request blocked because disableAllRequests is enabled.'
        );
        error.additionalInfo = { url: typeof url === 'string' ? url : url?.url };
        error.fetchErrorType = 'RequestNotComplete';
        error.retriable = false;
        // Simulate worst case behavior where network requests take a long time before failing
        return new Promise<Response>((_resolve, reject) => {
            setTimeout(() => reject(error), 30000);
        });
    }

    return new Promise<Response>((resolve, reject) => {
        const timeoutMS = init.timeoutMS || getConfig().timeoutMS || -1;
        const fetchOptions = <RequestInit>init;

        let timerHandle: number = 0;
        if (timeoutMS > 0) {
            let controller: AbortController | undefined;
            if ('AbortController' in self) {
                // We need an abort controller to abort the fetch request on tiemout
                controller = new AbortController();
                fetchOptions.signal = controller.signal;
            }

            timerHandle = self.setTimeout(() => {
                if (controller) {
                    controller.abort();
                } else {
                    /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
                     * Error constructor names can only be a string literals.
                     *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
                    const error: TraceErrorObject = new Error(
                        `Service request would have timed out after ${timeoutMS}ms if AbortController is available.`
                    );
                    error.additionalInfo = { url: typeof url === 'string' ? url : url?.url };
                    error.fetchErrorType = 'RequestTimeout';
                    reject(error);
                }
            }, timeoutMS);
        }

        fetch(url, fetchOptions)
            .then(response => {
                self.clearTimeout(timerHandle);
                resolve(response);
            })
            .catch(err => {
                const error: TraceErrorObject = err;

                error.fetchErrorType = 'RequestNotComplete';
                error.retriable = true;

                if (error.name === 'AbortError') {
                    Object.defineProperty(error, 'message', {
                        value: `Service request timed out after ${timeoutMS}ms.`,
                    });
                    error.fetchErrorType = 'RequestTimeout';
                } else if (error.message) {
                    Object.defineProperty(error, 'message', {
                        value: `${error.message}`,
                    });
                }
                error.additionalInfo = { url: typeof url === 'string' ? url : url?.url };

                self.clearTimeout(timerHandle);
                reject(error);
            });
    });
}
