import { trace } from 'owa-trace';
import type {
    CustomWaterfallRange,
    PerformanceDatapointType,
    PredefinedCheckpoint,
} from 'owa-analytics-types';

export function addTimingToWaterfall(
    dp: PerformanceDatapointType | null,
    isPredefined: boolean,
    checkpoint: PredefinedCheckpoint,
    index?: CustomWaterfallRange,
    name?: string,
    discardIfDefined?: boolean,
    actionName?: string
) {
    if (dp) {
        if (isPredefined) {
            dp.addToPredefinedWaterfall(
                checkpoint,
                discardIfDefined,
                undefined /* customTime */,
                actionName
            );
            return;
        } else if (index && name) {
            dp.addToCustomWaterfall(index, `${checkpoint}_${name}`, discardIfDefined);
            return;
        } else {
            errorAddingTimings(dp.getEventName(), checkpoint, isPredefined);
        }
    }
}

function errorAddingTimings(
    eventName: string,
    checkpoint: PredefinedCheckpoint,
    isPredefined: boolean
) {
    trace.warn(
        `AddTimingToWaterfallFailed dp: ${eventName}. checkpoint: ${checkpoint}. isPredefined: ${isPredefined}`
    );
}
