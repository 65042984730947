// MobX objects cannot safely be passed to postMessage, so we sanitize them to convert them to
// plain JavaScript objects first
export function sanitize<T>(value: T): Sanitized<T> {
    if (!value) {
        return value as Sanitized<T>;
    } else {
        return JSON.parse(JSON.stringify(value));
    }
}

const IsSanitized = Symbol('isSanitized');

export type Sanitized<T> = T extends undefined | null
    ? T
    : T & {
          readonly [IsSanitized]: never;
      };
