import { configure, spy } from 'mobx';
import { isBootFeatureEnabled } from 'owa-metatags';
import { setDelayedReaction } from 'owa-analytics-shared';
import { addToTimingMap } from 'owa-performance/lib/utils/timingMap';
import { schedule } from 'owa-task-queue/lib/schedule';

export function configureMobX() {
    configure({
        enforceActions: 'observed',
        useProxies: isBootFeatureEnabled('mobxproxies') ? 'always' : 'never',
        reactionScheduler,
    });

    if (process.env.NODE_ENV == 'dev') {
        spy(event => {
            if (event.type == 'action') {
                const stack = new Error('action').stack;

                // We're only interested in actions that occur during a component render
                if (stack && stack.includes('renderWithHooks')) {
                    breadcrumb = stack;
                }
            }
        });
    }
}

let delayReactionsSource: string | null = null;
function reactionScheduler(f: () => void) {
    if (delayReactionsSource) {
        // Schedule the reaction to run in a separate task to avoid blocking data requests,
        // but otherwise as soon as possible.
        const delayedReaction = schedule(
            () => {
                if (delayReactionsSource) {
                    addToTimingMap('reactionScheduler', delayReactionsSource);
                }
                // Since we are now running in a separate task, we can allow reactions to run synchronously again.
                delayReactionsSource = null;
                f();
            },
            { priority: 'user-blocking' }
        );
        setDelayedReaction(delayedReaction);
    } else {
        f();
    }
}

/**
 * A common pattern is for a user action to result in both satchel store changes and kick off a data request.
 * Mobx reactions to the store changes run synchronously and cause synchronous render updates. Since our data
 * request - whether graphql queries or direct calls to the service - always take at least one hop through the
 * microtask queue (due to await or .then). So in practice the data request isn't able to leave the main thread
 * until after the render completes.
 *
 * Delaying mobx reactions allows the data request to leave the main thread before the render happens. It's hard
 * and messy in general to plumb back knowledge of when the data request has actually left the main thread. So
 * instead we delay mobx reactions to a future macrotask - so we are sure the microtask queue has drained and the
 * data request has left the main thread. We try to schedule a task that will run as soon as possible, but there
 * will be cases where other tasks may run before the reactions.
 *
 * See https://javascript.info/event-loop for more context on macrotasks and microtasks.
 */
export function delayMobxReactionsForCurrentTask(source: string) {
    delayReactionsSource = source;
}

let breadcrumb: string | undefined;
export function getBreadcrumb() {
    return breadcrumb;
}
