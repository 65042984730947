/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 Suppressed as a legitimate use case for getting the native host version */
import { getClientId, getSessionId, getClientVersion, getNativeHostVersion } from 'owa-config';

export function createDiv(styles: { [style: string]: string }) {
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    const div = document.createElement('div')!;
    applyStyles(div, styles);
    return div;
}

export function applyStyles(
    elem: HTMLElement,
    styles: {
        [style: string]: string;
    }
) {
    for (const s of Object.keys(styles)) {
        elem.style.setProperty(s, styles[s]);
    }
}

export function getMetadataDetails() {
    const details: string[] = [
        `UTC Date: ${new Date().toISOString()}`,
        'Client Id: ' + getClientId(),
        'Session Id: ' + getSessionId(),
        'Client Version: ' + getClientVersion(),
    ];

    const nativeVersion = getNativeHostVersion();
    if (nativeVersion) {
        details.push('Native Host Version: ' + nativeVersion);
    }

    return details;
}
