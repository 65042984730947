import { logStartUsage } from 'owa-analytics-start';

// This is a workaround for bug #155070.  It is possible that an extension modifies the DOM so
// that, when React tries to act on the child it is no longer there, which results in a component
// error.  Based on the solution mentioned here:
// https://github.com/facebook/react/issues/11538#issuecomment-417504600
export function patchNode() {
    if (typeof Node === 'function' && Node.prototype) {
        const originalRemoveChild = Node.prototype.removeChild;
        Node.prototype.removeChild = function patchRemoveChild(child) {
            // We still want to keep track of how often this is happening, just in case
            if (child.parentNode !== this) {
                logStartUsage('REMOVE_CHILD_ERROR');
                return child;
            }

            return originalRemoveChild.apply(this, arguments as any) as any;
        };

        const originalInsertBefore = Node.prototype.insertBefore;
        Node.prototype.insertBefore = function patchInsertBefore(newNode, referenceNode) {
            // We still want to keep track of how often this is happening, just in case
            if (referenceNode && referenceNode.parentNode !== this) {
                logStartUsage('INSERT_BEFORE_ERROR');
                return newNode;
            }

            return originalInsertBefore.apply(this, arguments as any) as any;
        };
    }
}
