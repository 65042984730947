import { getGlobalSettingsAccountIndexer } from 'owa-account-source-list-store';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import type OwaUserConfiguration from 'owa-service/lib/contract/OwaUserConfiguration';

// There are only 2 service requests that contain OwaUserConfiguration
// GetOwaUserConfiguration and StartupData
type ServicesContainOwaUserConfiguration = 'GetOwaUserConfiguration' | 'StartupData';

type ConfigRequestInfo = {
    action: ServicesContainOwaUserConfiguration;
    cid: string | null;
    policySettings: {
        Pae?: boolean; // personalAccountsEnabled
        Aae?: boolean; // additionalAccountsEnabled
        Csae?: boolean; // changeSettingsAccountEnabled
        Aoad?: string[]; // allowedOrganizationAccountDomains
        Src?: string; // source
        SrcHistory?: string; // sourceHistory
    };
    mbguid?: string; //mailboxGuid
    tDomain?: string; // tenantDomain
    orgDomain?: string; // organizationDomain
    eprt?: string; //exchangePrincipalRecipientType
    lrt?: string; //logonRecipientType
    mrt?: string; //mailboxRecipientType
    rlTime: string; //responseLogTimeIso
    bbTime: string | null; //backendBeginTime
    beTime: string | null; //backendEndTime
};

const lastConfigLoadingInfo: Map<string, ConfigRequestInfo[]> = new Map();

export function logOwaUserConfigurationCallInfo(
    requestActionName: ServicesContainOwaUserConfiguration,
    correlationId: string | null,
    backendBeginTime: string | null,
    backendEndTime: string | null,
    mailboxInfo?: MailboxInfo,
    owaUserConfig?: OwaUserConfiguration
) {
    const loadingTime = Date.now();

    const indexer = mailboxInfo
        ? getIndexerValueForMailboxInfo(mailboxInfo)
        : getGlobalSettingsAccountIndexer(false /** throwIfNotInitialized */);

    if (!lastConfigLoadingInfo.has(indexer)) {
        lastConfigLoadingInfo.set(indexer, []);
    }

    lastConfigLoadingInfo.get(indexer)?.push({
        action: requestActionName,
        cid: correlationId,
        policySettings: {
            Pae: owaUserConfig?.PolicySettings?.PersonalAccountsEnabled,
            Aae: owaUserConfig?.PolicySettings?.AdditionalAccountsEnabled,
            Csae: owaUserConfig?.PolicySettings?.ChangeSettingsAccountEnabled,
            Aoad: owaUserConfig?.PolicySettings?.AllowedOrganizationAccountDomains,
        },
        mbguid: owaUserConfig?.SessionSettings?.MailboxGuid,
        tDomain: owaUserConfig?.SessionSettings?.TenantDomain,
        orgDomain: owaUserConfig?.SessionSettings?.OrganizationDomain,
        eprt: owaUserConfig?.SessionSettings?.ExchangePrincipalRecipientType,
        lrt: owaUserConfig?.SessionSettings?.LogonRecipientType,
        mrt: owaUserConfig?.SessionSettings?.MailboxRecipientType,
        rlTime: new Date(loadingTime).toISOString(),
        bbTime: backendBeginTime,
        beTime: backendEndTime,
    });
}

export function getOwaUserConfigurationLoadingTimes(
    mailboxInfo?: MailboxInfo
): ConfigRequestInfo[] | undefined {
    const indexer = mailboxInfo
        ? getIndexerValueForMailboxInfo(mailboxInfo)
        : getGlobalSettingsAccountIndexer(false /** throwIfNotInitialized */);

    return lastConfigLoadingInfo.get(indexer);
}
