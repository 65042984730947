import type { PerformanceDatapointType, PredefinedCheckpoint } from 'owa-analytics-types';

export const DataRequestPredefinedCheckpoints: PredefinedCheckpoint[] = [
    'GQL_Link_S',
    'GQL_Link_E',
    'GQL_Bridge_S',
    'GQL_Bridge_E',
    'GQL_Bridge_Error',
    'Data_S',
    'Data_E',
    'Network_S',
    'Network_E',
];

export function copyPredefinedCheckpoints(
    source: PerformanceDatapointType,
    destination: PerformanceDatapointType,
    checkpoints: PredefinedCheckpoint[],
    includeDataSource: boolean = true
) {
    for (const checkpoint of checkpoints) {
        copyPredefinedCheckpoint(source, destination, checkpoint, includeDataSource);
    }
}

/**
 * Copies the predefined checkpoints and their associated timings from the source to the
 * destination datapoint, accounting for the different start times.
 *
 * @param source
 * @param destination
 * @param checkpoint
 * @param includeDataSource
 */
function copyPredefinedCheckpoint(
    source: PerformanceDatapointType,
    destination: PerformanceDatapointType,
    checkpoint: PredefinedCheckpoint,
    includeDataSource: boolean
) {
    if (destination.hasEnded) {
        return;
    }

    const waterfallCustomData = source.getWaterfallColumnData(checkpoint);
    const waterfallCustomDataTime = parseInt(
        waterfallCustomData ? waterfallCustomData.toString().split('|')?.pop() || '' : ''
    );

    const sourceStartTime = source.getStartTime();
    const destinationStartTime = destination.getStartTime();

    if (!!waterfallCustomDataTime && sourceStartTime && destinationStartTime) {
        const targetCustomValue = sourceStartTime + waterfallCustomDataTime - destinationStartTime;

        destination.addToPredefinedWaterfall(
            checkpoint,
            true /* discardIfDefined */,
            targetCustomValue
        );
    }

    const sourceDataSource = source.getDataSource();

    if (includeDataSource && sourceDataSource) {
        destination.addDataSource(sourceDataSource);
    }
}
