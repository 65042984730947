import type { IdentityInfo } from 'owa-auth-types';
import getGlobalSettingsAccount from './getGlobalSettingsAccount';

/**
 * Used to obtain the IdentityInfo of the account where global settings are stored
 * @returns IdentityInfo corresponding to the global settings account
 */
export default function getGlobalSettingsAccountIdentityInfo(): IdentityInfo {
    const globalAccount = getGlobalSettingsAccount();

    return {
        providerType: globalAccount.sourceType,
        userPrincipalName: globalAccount.mailboxInfo.userIdentity,
    };
}
