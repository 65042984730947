import getScopedPath from 'owa-url/lib/getScopedPath';
import joinPath, { ensureLeadingSlash } from 'owa-url/lib/joinPath';
import { getOwaCanaryCookie } from 'owa-service/lib/canary';
import { getConfig } from 'owa-service/lib/config';
import { getApp, getOwsPath } from 'owa-config';

export async function postLanguageTimeZone(
    timeZone: string,
    language: string,
    emailAddress?: string,
    authToken?: string
): Promise<Response> {
    const scopedPath = getScopedPath(getOwsPath());
    const languagePostEndpoint = ensureLeadingSlash(
        `${joinPath(scopedPath, 'lang.owa')}?app=${getApp()}`
    );
    const languagePostHeaders = new Headers();
    const languagePostBody = `localeName=${language}&tzid=${timeZone}&saveLanguageAndTimezone=1`;
    const serviceConfig = getConfig();

    languagePostHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
    languagePostHeaders.append('X-OWA-CANARY', getOwaCanaryCookie());

    if (emailAddress) {
        languagePostHeaders.append('x-anchormailbox', emailAddress);
    }

    if (!authToken && !!serviceConfig && serviceConfig.getAuthToken) {
        authToken = await serviceConfig.getAuthToken();
    }

    if (authToken) {
        languagePostHeaders.append('Authorization', authToken);
    }

    return fetch(languagePostEndpoint, {
        method: 'POST',
        credentials: 'include',
        headers: languagePostHeaders,
        body: languagePostBody,
    });
}
