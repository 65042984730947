import { wrap } from 'comlink';
import type { DataWorkerClient } from 'owa-data-worker-utils';
import { loadDataWorker } from 'owa-data-worker-loader';
import { patchComlink } from 'owa-comlink-patch';

type WrappedWorker = {
    worker: Worker;
    client: DataWorkerClient;
};

let wrappedWorker: WrappedWorker;

export function getWrappedWorker(): WrappedWorker | undefined {
    if (!wrappedWorker) {
        patchComlink();
        const worker = loadDataWorker();
        if (worker) {
            const client = wrap<DataWorkerClient>(worker) as DataWorkerClient;
            wrappedWorker = {
                worker,
                client,
            };
        }
    }

    return wrappedWorker;
}
