import type { ApolloError } from '@apollo/client';
import type { GraphQLError } from 'graphql';
import type { TraceErrorObject } from './TraceErrorObject';

export function tryGetTraceObjectErrorFromApolloError(
    errorIn: Error | undefined
): TraceErrorObject | Error | undefined {
    if (!errorIn) {
        return undefined;
    }

    // We do not use partial match to make sure we do not over-categorize the errors into the server bucket.
    const apolloErrorServerTypeCodes = ['GraphqlServerError'];
    const apolloErrorNetworkTypeCodes = ['GraphqlNetworkError'];

    // We don't currently call into GraphQL APIs directly, so we don't get any network errors.
    // So look through the GraphQLErrors to find one, if not found, return the original error.
    const error = getGraphQLOrOriginalErrorToUse(errorIn);
    if (error) {
        const traceError = error as TraceErrorObject;
        if (error.path) {
            traceError.gqlPath = error.path.join('.');
        }
        error.extensions &&
            Object.keys(error.extensions).forEach(key => {
                if (error.extensions[key] != undefined) {
                    (error as any)[key] = error.extensions[key];
                }

                if (key == 'code') {
                    const categoryCode = error.extensions[key];
                    if (categoryCode) {
                        traceError.responseCode = categoryCode;
                        if (apolloErrorServerTypeCodes.indexOf(categoryCode) > -1) {
                            traceError.fetchErrorType = 'ServerFailure';
                        }

                        if (apolloErrorNetworkTypeCodes.indexOf(categoryCode) > -1) {
                            traceError.fetchErrorType = 'RequestNotComplete';
                        }
                    }
                } else if (key == 'InnerMessage') {
                    traceError.innerMessage = error.extensions[key];
                } else if (key === 'queryStack') {
                    traceError.queryStack = error.extensions[key];
                }
            });
        // Copy any classification or diagnostics from the original error.
        if (error.originalError) {
            const innerError = error.originalError as any;
            /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
             * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
             *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
            Object.keys(innerError).forEach(key => {
                if (innerError[key]) {
                    (error as any)[key] = innerError[key];
                }
            });
        }
        return error;
    } else {
        // If we couldn't find any graphQLError, then use the input error.
        return errorIn;
    }
}

function getGraphQLOrOriginalErrorToUse(error: Error): GraphQLError | undefined {
    // Custom information added to TraceObjectError thrown in our resolvers are reported in
    // the extensions property of the GraphQLError (this is idiomatically
    // how extended information is reported in GraphQL).
    // So look for a GraphQLError with an extensions property.
    const apolloError = error as ApolloError;
    if (apolloError.graphQLErrors) {
        // Not using a forEach here because we can't early return from it.
        for (const graphQLError of apolloError.graphQLErrors) {
            if (graphQLError.extensions && Object.keys(graphQLError.extensions).length > 0) {
                return graphQLError;
            }
        }
    }

    // If we couldn't find a graphQL error with an extensions object, use the first graphQLError or the originalError.
    return (
        apolloError.graphQLErrors?.[0] || ((error as GraphQLError).originalError as GraphQLError)
    );
}
