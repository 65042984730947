import type { MailboxInfo } from 'owa-client-types';
import { type HttpStatusCode } from 'owa-http-status-codes';
import type { HeadersWithoutIterator, RequestOptions } from 'owa-service/lib/RequestOptions';
import { makeServiceRequest } from 'owa-service/lib/ServiceRequest';
import type { SessionData } from 'owa-service/lib/types/SessionData';
import type { BootError, FetchDataErrorHandler } from 'owa-shared-start-types';
import { getStartupDataEndpoint } from './getStartupDataEndpoint';
import { registerCreateServiceResponseCallback } from 'owa-service/lib/fetchWithRetry';
import { handleServiceResponseCallbackForPolicySettingsLog } from 'owa-account-user-configuration-monitor/lib/handleServiceResponseCallbackForPolicySettingsLog';
import isRetriableAuthError from 'owa-service/lib/isRetriableAuthError';

export function overrideIsRetriableStatus(status: number): boolean {
    return (
        status == 0 ||
        // 204 is returned during JIT with OwaInvalidUserLanguageException
        status == 204 ||
        status == 404 ||
        status == 408 ||
        status == 449 ||
        // explicitly add 507 which is returned when consumer mailbox is not provisioned
        status == 507 ||
        status >= 500 ||
        isRetriableAuthError(status)
    );
}

let hasReigsteredServiceResponseCallbackForPolicySettingsLogs = false;
function registerServiceResponseCallbackForPolicySettingsLogs() {
    if (!hasReigsteredServiceResponseCallbackForPolicySettingsLogs) {
        hasReigsteredServiceResponseCallbackForPolicySettingsLogs = true;
        registerCreateServiceResponseCallback(
            async (responsePromise, actionName, _url, _attemptCount, optionsPromise) => {
                handleServiceResponseCallbackForPolicySettingsLog(
                    await responsePromise.then(r => r.clone()),
                    actionName,
                    optionsPromise
                );
            }
        );
    }
}

export function fetchData(
    mailboxInfo: MailboxInfo | undefined,
    headers: HeadersWithoutIterator,
    errorHandler: FetchDataErrorHandler,
    postProcessFunction: (json: SessionData) => SessionData,
    processHeaders: (headers: HeadersWithoutIterator) => void,
    updateDiagnosticsOnReponseFunction: (response: Response) => void
): Promise<SessionData> {
    const endpoint = getStartupDataEndpoint();

    registerServiceResponseCallbackForPolicySettingsLogs();

    const options: RequestOptions = {
        endpoint,
        headers,
        returnFullResponseOnSuccess: true,
        authNeededOnUnAuthorized: false,
        mailboxInfo,
        shouldRetry: async (status: number, res: Response) => {
            // we want to retry if the status is not a 200
            if (overrideIsRetriableStatus(status)) {
                return true;
            }
            // or if the status is 200 but the response can not be parsed into json
            if (status == 200 && !(await canParseJson(res))) {
                return true;
            }

            return false;
        },
        onBeforeRetry: response => errorHandler.onBeforeRetry(endpoint, response),
        retryCount: 3,
        scenarioId: 'accountPolicy',
    };

    return makeServiceRequest<Response>('StartupData', undefined, options)
        .then(
            (response: Response) => {
                // Before validating the response allow collecting diagnostic for the response
                updateDiagnosticsOnReponseFunction(response);
                if (!response) {
                    throw new Error('NoResponse');
                }
                // it is important that we check for not a status of 200 instead of !response.ok since
                // OwaInvalidUserLanguageException returns with a status of 204
                if (response.status != 200) {
                    throw errorHandler.createStatusErrorMessage(response);
                }

                processHeaders(response.headers);

                return response.json().catch(e => {
                    const invalidJsonError: BootError = new Error('InvalidJson');
                    const errorType = typeof e;
                    invalidJsonError.additionalInfo = {
                        error: errorType == 'string' ? e : e?.message || errorType,
                        online: self?.navigator?.onLine,
                    };
                    throw invalidJsonError;
                });
            },
            response => {
                if (response instanceof Error) {
                    throw response;
                } else {
                    throw errorHandler.createStatusErrorMessage(response);
                }
            }
        )
        .then(postProcessFunction)
        .catch(e => Promise.reject(errorHandler.createBootError(e, 'StartupData', endpoint, 0)));
}

async function canParseJson(response: Response): Promise<boolean> {
    try {
        await response.clone().json();
        return true;
    } catch {
        return false;
    }
}
