export function equalityCheck(
    x: any,
    y: any,
    xParentName: string = 'BASE',
    matchValue: boolean = true,
    matchArrayOrder: boolean = true
): {
    result: boolean;
    diff: string;
} {
    const xType = typeof x;
    const yType = typeof y;
    if (xType === yType) {
        if (xType === 'object') {
            if (Array.isArray(x) && Array.isArray(y)) {
                if (x.length !== y.length) {
                    return {
                        result: false,
                        diff: `${xParentName} array length ${x.length}::${y.length}`,
                    };
                }
                const xSorted = [...x];
                const ySorted = [...y];
                if (!matchArrayOrder) {
                    xSorted.sort((a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b)));
                    ySorted.sort((a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b)));
                }
                for (let i = 0; i < x.length; i++) {
                    const check = equalityCheck(
                        xSorted[i],
                        ySorted[i],
                        xParentName,
                        matchValue,
                        matchArrayOrder
                    );
                    if (!check.result) {
                        return check;
                    }
                }
                return {
                    result: true,
                    diff: `${xParentName} same array`,
                };
            }
            const xKeys = Object.keys(x);
            const yKeys = Object.keys(y);
            if (xKeys.length !== yKeys.length) {
                return {
                    result: false,
                    diff: `${xParentName} key length [${xKeys.toString()}]::[${yKeys.toString()}]`,
                };
            }
            // Check that all keys in x are found in y and that their values satisfy equalityCheck
            for (const key of xKeys) {
                if (y[key] == null) {
                    return {
                        result: x[key] == null,
                        diff: `${xParentName} ${key} ${x[key] ? 'missing' : ''}`,
                    };
                }
                const check = equalityCheck(
                    x[key],
                    y[key],
                    `${xParentName}.${key}`,
                    matchValue,
                    matchArrayOrder
                );
                if (!check.result) {
                    return check;
                }
            }
            return { result: true, diff: `${xParentName} same object` };
        }
        return {
            result: matchValue ? x === y : true,
            diff: `${xParentName} value <PII>`,
        };
    }
    return { result: false, diff: `${xParentName} type ${xType}::${yType}` };
}
