import { getApp } from 'owa-config';
import { redirectToOwa } from './redirectToOwa';
import { redirect, redirectTo, AUTH_REDIRECT_KEY, MSAL_AUTH_REDIRECT_KEY } from './redirect';
import type { BootError, BootResult } from 'owa-shared-start-types';
import type ErrorDiagnostics from './interfaces/ErrorDiagnostics';
import handleErrorLocally from './handleErrorLocally';
import { isMsalFlowEnabled } from 'owa-msaljs/lib/isMsalFlowEnabled';
import { setAuthTiming } from 'owa-auth-timings';
import { getAadOrMsaRedirectUri } from 'owa-msaljs/lib/utils/getAadOrMsaRedirectUri';

export function handleBootError(
    bootResult: BootResult,
    error: BootError,
    errorDiagnostics: ErrorDiagnostics,
    onFatalBootError?: () => void,
    skipAuthRedirect = false
): void {
    // Check if we need retry before render error page
    let didRedirect: boolean = false;
    if (bootResult == 'langtz') {
        redirectToOwa(getApp());
        return;
    } else if (bootResult == 'errorfe' && error.response?.url) {
        redirectTo(error.response.url);
        return;
    } else if (bootResult == 'auth' && !skipAuthRedirect) {
        setAuthTiming('bootred');
        didRedirect = isMsalFlowEnabled()
            ? redirect(location, MSAL_AUTH_REDIRECT_KEY, 'true')
            : redirect(
                  location,
                  AUTH_REDIRECT_KEY,
                  'true',
                  undefined,
                  true,
                  error?.response?.headers
              );
    } else if (bootResult == 'nlp') {
        didRedirect = redirect(location, 'nlp', '0');
    } else if (bootResult == 'aadmsaredir') {
        redirectTo(getAadOrMsaRedirectUri());
        return;
    }

    if (!didRedirect) {
        // Await this because once we redirect further async stuff won't happen
        onFatalBootError?.();
        handleErrorLocally(bootResult, errorDiagnostics, error);
    }
}
