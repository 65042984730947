export function getBackFilledErrors() {
    let errors: string = '';
    if (window.owaBackfilledErrors && window.owaBackfilledErrors.length > 0) {
        errors =
            'Back Filled Errors: ' +
            window.owaBackfilledErrors.map(formatBackfilledErrors).join('|');
    } else {
        errors = 'Back Filled Errors: None';
    }

    return errors;
}

function formatBackfilledErrors(a: IArguments) {
    let res = 'null';
    if (a) {
        // The first argument should be the error message
        res = a[0];
        if (typeof a.callee == 'function') {
            res += ':' + a.callee();
        }
    }
    return res;
}
