import type { IExtendedConfiguration, ITelemetryPlugin } from '@microsoft/1ds-core-js';
import type { IChannelConfiguration } from '@microsoft/1ds-post-js';
import { AppInsightsCore } from '@microsoft/1ds-core-js';
import { PostChannel } from '@microsoft/1ds-post-js';
import { getCollectorUrl } from 'owa-analytics-utils';
import { getForest } from 'owa-config/lib/envDiagnostics';

export function initializeOneDSCore(
    ariaTenantToken: string,
    dontLogInEU?: boolean,
    additionalExtensions?: ITelemetryPlugin[],
    /* eslint-disable-next-line owa-custom-rules/no-optional-any-parameter -- (https://aka.ms/OWALintWiki)
     * DO NOT COPY-PASTE! This code should be fixed by any developer touching this code
     *	> Optional function parameters should not have type "any". This can hide undefined/null references otherwise detectable by the transpiler. */
    additionalExtensionConfig?: any, // 1DS uses any for extensionConfig
    extentionsCallback?: () => void
) {
    if (ariaTenantToken) {
        try {
            const appInsightsCore = new AppInsightsCore();
            const postChannel: PostChannel = new PostChannel();
            const postChannelConfig: IChannelConfiguration = {};

            const extensions: ITelemetryPlugin[] = [postChannel];
            if (additionalExtensions) {
                extensions.push(...additionalExtensions);
            }

            const coreConfig: IExtendedConfiguration = {
                instrumentationKey: ariaTenantToken,
                endpointUrl: getCollectorUrl(getForest(), dontLogInEU),
                extensions,
                extensionConfig: {
                    [postChannel.identifier]: postChannelConfig,
                    ...additionalExtensionConfig,
                },
                enableWParam: true, // Set the "w" parameters so the 1ds collector can parse the web content when it comes from the web worker
            };

            // Initialize SDK
            appInsightsCore.initialize(coreConfig, []);

            extentionsCallback?.();

            return appInsightsCore;
        } catch {
            // ignore instrumentation errors
        }
    }

    return;
}
