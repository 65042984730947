import type ErrorDiagnostics from './interfaces/ErrorDiagnostics';
import type { BootError, BootResult } from 'owa-shared-start-types';
import { isBootFeatureEnabled } from 'owa-metatags';
import { getItem, itemExists, localStorageExists } from 'owa-local-storage';
import { isSupportReachable } from './utils/isSupportReachable';
import { createDiv, applyStyles, getMetadataDetails } from './errorPageHelpers';
import { getBootFailureCount } from './bootErrorCounter';
import { getBackFilledErrors } from './utils/backFilledErrorsUtils';

const blueColor = '#0078D4';
const greyColor = '#333';
const fewerDetailsString = 'Fewer Details';
const semiboldFont =
    "'Segoe UI WestEuropean', 'Segoe UI Semibold', 'Segoe WP Semibold', 'Segoe UI', 'Segoe WP', Tahoma, Arial, sans-serif'";

let isLocalErrorPageAdded = false;

export default function handleErrorLocally(
    bootResult: BootResult,
    errorDiagnostics: ErrorDiagnostics,
    bootError: BootError,
    clearCacheHandler?: () => Promise<boolean>,
    launchSupport?: () => void
) {
    const container = createDiv({
        position: 'absolute',
        left: '340px',
        top: '40px',
    });

    // grab the logo from the loading screen. We will reuse this svg
    const logo = document.getElementById('loadingLogo');
    if (logo) {
        logo.querySelectorAll('*').forEach(item => {
            const logoElement = item as HTMLElement;
            const elementStyle = getComputedStyle(logoElement);

            // if the logo element has an animation then
            // the animation-duration is set to 0 to make it static
            if (elementStyle.animation) {
                applyStyles(logoElement, {
                    'animation-duration': '0s',
                });
            }
        });

        logo.remove();

        const logoStyles: {
            [style: string]: string;
        } = {
            position: 'initial',
            width: '56px',
            height: '56px',
            'margin-left': '-28px',
            transform: 'scale(0.25)',
        };
        applyStyles(logo, logoStyles);
        container.appendChild(logo);
    }

    // dismiss the loading screen so we can show the error page
    const loadingScreen = document.getElementById('loadingScreen');
    if (loadingScreen) {
        loadingScreen.remove();
    }

    if (bootError.source === 'UpdateFailed') {
        addUpdateFailedPage(container);
    } else if (isBootFeatureEnabled('supportonboot') && launchSupport && isSupportEnabled()) {
        isSupportReachable().then(isReachable => {
            if (!isReachable) {
                addLocalErrorPage(
                    container,
                    bootResult,
                    errorDiagnostics,
                    bootError,
                    clearCacheHandler
                );

                // Remove the support iframe if support isn't reachable
                const supportIframe = document.getElementById('supportOnBootError');
                if (supportIframe) {
                    supportIframe.remove();
                }
            }
        });
        try {
            launchSupport();
            return;
        } catch {
            addLocalErrorPage(
                container,
                bootResult,
                errorDiagnostics,
                bootError,
                clearCacheHandler
            );
        }
    } else {
        addLocalErrorPage(container, bootResult, errorDiagnostics, bootError, clearCacheHandler);
    }
}

function addLocalErrorPage(
    container: HTMLDivElement,
    bootResult: BootResult,
    errorDiagnostics: ErrorDiagnostics,
    bootError: BootError,
    clearCacheHandler?: () => Promise<boolean>
) {
    if (isLocalErrorPageAdded) {
        return;
    }

    isLocalErrorPageAdded = true;
    addDiv(
        container,
        { fontSize: '40px', color: blueColor, 'margin-top': '28px' },
        'Something went wrong.'
    );
    addDiv(
        container,
        {
            fontSize: '16px',
            'font-family': semiboldFont,
            margin: '20px 0',
            'font-weight': '600',
            color: greyColor,
        },
        'Please try the recommended action below.'
    );

    const span = document.createElement('span');
    applyStyles(span, {
        margin: '0 4px',
        'font-size': '14px',
        'line-height': '19px',
    });

    let buttonText = 'Refresh the application';
    const bootFailiureCount = getBootFailureCount();
    if (bootFailiureCount == 2) {
        buttonText = 'Reload the application';
    } else if (bootFailiureCount == 3) {
        buttonText = 'Relaunch the application';
    } else if (bootFailiureCount >= 4) {
        buttonText = 'Repair the application';
    }

    span.innerText = buttonText;
    const refresh = document.createElement('button');
    applyStyles(refresh, {
        color: 'white',
        'background-color': blueColor,
        cursor: 'pointer',
        padding: '0 16px',
        'min-width': '80px',
        'border-radius': '2px',
        'min-height': '32px',
        border: '1px solid ' + blueColor,
        'font-weight': '600',
        'font-family': semiboldFont,
        'line-height': '19px',
    });
    refresh.appendChild(span);
    container.appendChild(refresh);

    const detailsDiv = addDiv(
        container,
        {
            fontSize: '14px',
            color: blueColor,
            cursor: 'pointer',
            'font-family': semiboldFont,
            'line-height': '19px',
            'font-weight': '600',
            margin: '20px 0',
        },
        fewerDetailsString
    );

    let details = getMetadataDetails();
    details.push('BootResult: ' + bootResult);
    if (bootError.request) {
        details.push('Failed resource: ' + bootError.request);
    }

    details.push(getBackFilledErrors());
    details = details.concat(
        (Object.keys(errorDiagnostics) as (keyof ErrorDiagnostics)[])
            .filter(k => errorDiagnostics[k])
            .map(k => k + ': ' + errorDiagnostics[k])
    );

    const errorContainer = document.createElement('pre');
    errorContainer.innerText = details.join('\n');
    applyStyles(errorContainer, {
        color: greyColor,
        'user-select': 'all',
    });

    container.appendChild(errorContainer);

    // Add click listeners
    /* eslint-disable-next-line owa-custom-rules/no-deprecated-dom-events -- (https://aka.ms/OWALintWiki)
     * Baseline. DO NOT COPY AND PASTE!
     *	> 'click' should be wrapped for performance. Please use owa-event-listener instead. */
    detailsDiv.addEventListener('click', () => {
        const isFewerDetails = detailsDiv.innerText == fewerDetailsString;
        detailsDiv.innerText = isFewerDetails ? 'More Details' : fewerDetailsString;
        errorContainer.style.display = isFewerDetails ? 'none' : '';
    });

    /* eslint-disable-next-line owa-custom-rules/no-deprecated-dom-events -- (https://aka.ms/OWALintWiki)
     * Baseline. DO NOT COPY AND PASTE!
     *	> 'click' should be wrapped for performance. Please use owa-event-listener instead. */
    refresh.addEventListener('click', () => {
        if (!clearCacheHandler?.()) {
            self.location.reload();
        }
    });

    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    document.getElementById('app')!.appendChild(container);
}

function addUpdateFailedPage(container: HTMLDivElement) {
    const title = 'Update to keep using Outlook';
    const message = 'Visit the Microsoft Store to install the latest version of Outlook.';
    const button = 'Update now';

    addDiv(
        container,
        {
            fontSize: '20px',
            'font-family': semiboldFont,
            margin: '20px 0',
            'font-weight': '600',
            color: greyColor,
        },
        title
    );

    addDiv(container, { fontSize: '16px', color: greyColor, 'margin-top': '28px' }, message);

    const span = document.createElement('span');
    applyStyles(span, {
        margin: '0 4px',
        'font-size': '14px',
        'line-height': '19px',
    });

    span.innerText = button;
    const visitStore = document.createElement('button');

    applyStyles(visitStore, {
        color: 'white',
        'background-color': blueColor,
        cursor: 'pointer',
        padding: '0 16px',
        'min-width': '80px',
        'border-radius': '2px',
        'min-height': '32px',
        border: '1px solid ' + blueColor,
        'font-weight': '600',
        'font-family': semiboldFont,
        'line-height': '19px',
    });

    /* eslint-disable-next-line owa-custom-rules/no-deprecated-dom-events -- (https://aka.ms/OWALintWiki)
     * Baseline. DO NOT COPY AND PASTE!
     *	> 'click' should be wrapped for performance. Please use owa-event-listener instead. */
    visitStore.addEventListener('click', () => {
        const monarchStoreUpdateUri = 'ms-windows-store://pdp/?ProductId=9NRX63209R7B';
        setTimeout(() => window.open(monarchStoreUpdateUri, '_self'), 0);
    });

    visitStore.appendChild(span);

    container.appendChild(visitStore);

    const appElement = document.getElementById('app');
    if (appElement) {
        appElement.appendChild(container);
    }
}

function addDiv(
    container: HTMLDivElement,
    styles: {
        [style: string]: string;
    },
    text?: string
) {
    const div = createDiv(styles);
    if (text) {
        div.innerText = text;
    }
    container.appendChild(div);
    return div;
}

function isSupportEnabled(): boolean {
    const ocpsStoreKey = 'OcpsPolicyStore';
    if (localStorageExists(window) && itemExists(window, ocpsStoreKey)) {
        const ocpsPolicyStore = getItem(window, ocpsStoreKey);
        if (ocpsPolicyStore) {
            const policyStoreParsed = JSON.parse(ocpsPolicyStore);
            if (policyStoreParsed && policyStoreParsed.policies) {
                let result = true;
                Object.entries(policyStoreParsed.policies)?.forEach((account: any) => {
                    const accountPolicyConfig = account.length >= 1 ? account[1] : null;
                    if (
                        accountPolicyConfig &&
                        accountPolicyConfig['contactSupportEnabled'] == '0'
                    ) {
                        // return false if even 1 account
                        // has support policy disabled
                        result = false;
                    }
                });

                return result;
            }
        }
    }

    // store does not exist or policy does not exist or all policies enabled. Enable support by default
    return true;
}
