export function createErrorWithoutStackTraceLimit(errorName: string): Error {
    const defaultLimit = Error.stackTraceLimit;
    Error.stackTraceLimit = 1000;
    /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
     * Error constructor names can only be a string literals.
     *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
    const error = new Error(errorName);
    Error.stackTraceLimit = defaultLimit;
    return error;
}
