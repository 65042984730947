import type { CustomDataMap, DatapointOptions } from 'owa-analytics-types';
import {
    logStartCoreGreyError,
    logStartCoreUsage,
    logStartGreyError,
    logStartUsage,
} from 'owa-analytics-start';
import type { AnalyticsCoreEventNames } from 'owa-analytics-events';

/**
 * Specifies the type of function that will be used for logging
 */
type LogUsageFunc = (eventName: string, data?: CustomDataMap, options?: DatapointOptions) => void;

type LogGreyErrorFunc = (
    eventName: string,
    error?: Error,
    data?: CustomDataMap,
    options?: DatapointOptions
) => void;

let overrideLogUsage: LogUsageFunc | undefined;
let overrideLogGrayError: LogGreyErrorFunc | undefined;

export function setAccountLogUsageOverrides(
    logUsage?: LogUsageFunc,
    logGreyError?: LogGreyErrorFunc
) {
    overrideLogUsage = logUsage;
    overrideLogGrayError = logGreyError;
}

/**
 * The account source list store is used for account management in both OOBE and the mail
 * application. To make sure the logging comes through in both OOBE and the mail/calendar
 * apps. The logUsageFromAccounts provides this support by allowing the logging method
 * to be switched based on whether the logging is happening in OOBE or not.
 * @param eventName Name of the event to log
 * @param data data associated with the event
 */
export function logUsageFromAccounts(
    eventName: string,
    data?: CustomDataMap,
    options?: DatapointOptions
) {
    const logFunc = overrideLogUsage || logStartUsage;
    logFunc(eventName, data, options);
}

/**
 * The account source list store is used for account management in both OOBE and the mail
 * application. To make sure the logging comes through in both OOBE and the mail/calendar
 * apps. The logUsageFromAccounts provides this support by allowing the logging method
 * to be switched based on whether the logging is happening in OOBE or not.
 * @param eventName Name of the event to log
 * @param data data associated with the event
 */
export function logCoreUsageFromAccounts(
    eventName: AnalyticsCoreEventNames,
    data?: CustomDataMap,
    options?: DatapointOptions
) {
    const logFunc = overrideLogUsage || logStartCoreUsage;
    logFunc(eventName, data, options);
}

/**
 * The account source list store is used for account management in both OOBE and the mail
 * application. To make sure the logging comes through in both OOBE and the mail/calendar
 * apps. The logGreyErrorFromAccounts provides this support by allowing the logging method
 * to be switched based on whether the logging is happening in OOBE or not.
 * @param eventName Name of the event to log
 * @param data data associated with the event
 */
export function logGreyErrorFromAccounts(
    eventName: string,
    error?: Error,
    data?: CustomDataMap,
    options?: DatapointOptions
) {
    const logFunc = overrideLogGrayError || logStartGreyError;
    logFunc(eventName, error, data, options);
}

/**
 * The account source list store is used for account management in both OOBE and the mail
 * application. To make sure the logging comes through in both OOBE and the mail/calendar
 * apps. The logGreyErrorFromAccounts provides this support by allowing the logging method
 * to be switched based on whether the logging is happening in OOBE or not.
 * @param eventName Name of the event to log
 * @param data data associated with the event
 */
export function logCoreGreyErrorFromAccounts(
    eventName: AnalyticsCoreEventNames,
    error?: Error,
    data?: CustomDataMap,
    options?: DatapointOptions
) {
    const logFunc = overrideLogGrayError || logStartCoreGreyError;
    logFunc(eventName, error, data, options);
}
