import getBrowserInfo, { type Browser } from './utils/getBrowserInfo';
import {
    type OperatingSystem,
    getOs,
    getOsInfoWithoutHints,
    getOsInfoWithHints,
} from './utils/getOsInfo';

export function isMac(): boolean {
    return getOs() == 'Mac OS X';
}

export function isWindows(): boolean {
    return getOs() === 'Windows';
}

export function isLinux(): boolean {
    return getOs() === 'Linux';
}

export function isWinXp(): boolean {
    const osInfo = getOsInfoWithoutHints();
    return osInfo.os === 'Windows' && osInfo.osVersion === 'XP';
}

export function isWin10(): boolean {
    const osInfo = getOsInfoWithoutHints();
    return osInfo.os === 'Windows' && osInfo.osVersion === '10';
}

export async function isWin11(): Promise<boolean> {
    const osInfo = await getOsInfoWithHints();
    return osInfo.os === 'Windows' && osInfo.osVersion === '11';
}

export function isChromiumOs(): boolean {
    return getOs() == 'Chromium OS';
}

export function isAndroidOs(): boolean {
    return getOs() == 'Android';
}

export function isIOs(): boolean {
    return getOs() == 'iOS';
}

export function isBrowserChrome(): boolean {
    return getBrowserInfo().browser === 'Chrome';
}

export function isBrowserFirefox(): boolean {
    return getBrowserInfo().browser === 'Firefox';
}

export function isBrowserEDGECHROMIUM(): boolean {
    return getBrowserInfo().browser === 'Edge Anaheim';
}

export function isBrowserSafari(): boolean {
    return getBrowserInfo().browser === 'Safari';
}

export function isBrowserOpera(): boolean {
    return getBrowserInfo().browser === 'Opera';
}

export function getBrowserVersion(): number[] | null {
    return getBrowserInfo().browserVersion;
}
