import type { SessionData } from 'owa-service/lib/types/SessionData';
import { logStartUsage } from 'owa-analytics-start';

/**
 * If we boot with local session data and later receive session data from the service,
 * we want to allow the update to update with data from the slow arriving network session data.
 */

export const NETWORK_SESSION_DATA_TIMEOUT = 30000;
export let slowNetworkSessionDataStash: SlowNetworkSessionData | null | undefined = undefined;
export let pendingResolve:
    | ((slowNetworkSessionData: SlowNetworkSessionData | null) => void)
    | undefined = undefined;
export let pendingReject: ((reason: any) => void) | undefined = undefined;

let getDataTimer: number | undefined = undefined;
let setDataTimer: number | undefined = undefined;

export type SlowNetworkSessionData = {
    networkSD: SessionData;
    offlineSD: SessionData;
};

function resolvePendingPromise(sessionData: SlowNetworkSessionData | null) {
    if (pendingResolve) {
        pendingResolve(sessionData);
        pendingResolve = undefined;
        pendingReject = undefined;
        clearTimeout(getDataTimer);
        return true;
    }

    return false;
}

function rejectPendingPromise(reason: any) {
    if (pendingReject) {
        pendingReject(reason);
    }

    pendingReject = undefined;
    pendingResolve = undefined;
}

/**
 * Returns a promise which resolves with the slower network session data if it loses the network race.
 * Otherwise resolves with null.
 */
export function getSlowNetworkSessionData(): Promise<SlowNetworkSessionData | null> {
    let result: Promise<SlowNetworkSessionData | null>;
    if (slowNetworkSessionDataStash !== undefined) {
        result = Promise.resolve(slowNetworkSessionDataStash);
        slowNetworkSessionDataStash = undefined;
        clearTimeout(setDataTimer);
    } else {
        result = new Promise<SlowNetworkSessionData | null>((resolve, reject) => {
            pendingResolve = resolve;
            pendingReject = reject;
        });

        getDataTimer = self.setTimeout(() => {
            const error = new Error('Error in getSlowNetworkSessionData');
            error.message = 'SlowNetworkSDDataTimeout';
            rejectPendingPromise(error);
            logStartUsage('SlowNetworkSDDataTimeout');
        }, NETWORK_SESSION_DATA_TIMEOUT);
    }

    return result;
}

export function setSlowNetworkSessionData(slowNetworkSessionData: SlowNetworkSessionData | null) {
    if (!resolvePendingPromise(slowNetworkSessionData)) {
        slowNetworkSessionDataStash = slowNetworkSessionData;
        setDataTimer = self.setTimeout(() => {
            slowNetworkSessionDataStash = null;
            logStartUsage('SlowNetworkSDCallerTimeout');
        }, NETWORK_SESSION_DATA_TIMEOUT);
    }
}

export function resetForTest() {
    slowNetworkSessionDataStash = undefined;
    pendingResolve = undefined;
    pendingReject = undefined;
}
