import type { MailboxInfo } from 'owa-client-types';
import type { M365UserMailboxAccountSource } from '../store/schema/AccountSourceList';
import { accountSourceDataTypeChecker } from '../store/schema/AccountSourceList';
import getCoprincipalAccountForMailboxInfo from './getCoprincipalAccountForMailboxInfo';

function findMatchingSharedMailboxInfo(
    account: M365UserMailboxAccountSource,
    mailboxSmtpAddress: string
): MailboxInfo | undefined {
    const lowerCaseSmtpAddress = mailboxSmtpAddress.toLowerCase();
    const matchingSharedFolder = account.sharedSources.sources.filter(
        sharedSource =>
            lowerCaseSmtpAddress === sharedSource.mailboxInfo.mailboxSmtpAddress.toLowerCase()
    );
    return matchingSharedFolder[0]?.mailboxInfo;
}

/**
 * Returns the MailboxInfo for the shared mailbox in the specified account
 * @param mailboxInfo Mailbox associated with the coprincipal account that contains the shared mailbox
 * @param mailboxSmtpAddress SMTP address of the shared mailbox
 * @returns MailboxInfo for the shared mailbox from the account source list store
 */
export function getSharedMailboxInfo(
    mailboxInfo: MailboxInfo,
    mailboxSmtpAddress: string
): MailboxInfo | undefined {
    const account = getCoprincipalAccountForMailboxInfo(mailboxInfo);
    if (accountSourceDataTypeChecker.isM365UserMailbox(account)) {
        return findMatchingSharedMailboxInfo(account, mailboxSmtpAddress);
    }

    return undefined;
}
