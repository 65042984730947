import getAuthenticationUrlOperation from './operation/getAuthenticationUrlOperation';
import type AuthenticationUrlResponseCode from './contract/AuthenticationUrlResponseCode';
import { isRunningOnWorker } from 'owa-config';
import type RequestOptions from './RequestOptions';
import type MailboxRequestOptions from './MailboxRequestOptions';

const WAIT_TIME_AFTER_ATTEMPT: number = 82800000; // 23 Hours in Milliseconds.
const ONEMINUTE: number = 60000; // 1 Minute in Milliseconds.
const BACKGROUND_AUTH: string = 'backgroundauth';

let iframe: HTMLIFrameElement | null = null;
let lastAttemptTime: number | null = null;

export async function tryBackgroundAuth(requestOptions: RequestOptions | MailboxRequestOptions) {
    if (iframe != null) {
        return;
    }

    if (isRunningOnWorker()) {
        return;
    }

    if (lastAttemptTime != null && Date.now() - lastAttemptTime < WAIT_TIME_AFTER_ATTEMPT) {
        return;
    } else {
        lastAttemptTime = Date.now();
    }

    iframe = self.document.createElement('iframe');
    iframe.setAttribute('id', BACKGROUND_AUTH);
    iframe.style.display = 'none';

    getAuthenticationUrlOperation({}, requestOptions)
        .then(response => {
            if (
                response.ResultCode != 0 ||
                response.AuthenticationUrl == null ||
                response.AuthenticationUrl == ''
            ) {
                iframe = null;
                return;
            }
            if (iframe) {
                self.document.body.appendChild(iframe);
                self.addEventListener('message', handleIframeResponse, false);
                iframe.src = response.AuthenticationUrl;
            }
            setupBackgroundAuthTimeOut();
        })
        .catch(function () {
            iframe = null;
        });
}

function setupBackgroundAuthTimeOut() {
    // Run the time out function after one minute.
    setTimeout(function () {
        if (iframe != null) {
            cleanUpIframe();
        }
    }, ONEMINUTE);
}

function handleIframeResponse(e: MessageEvent) {
    /* eslint-disable-next-line no-restricted-globals  -- (https://aka.ms/OWALintWiki)
     * Baseline, should investigate if file should be in th worker or we can convert to getWindowData
     *	> Unexpected use of 'window'. Do not include "window" in code included in the worker build. Use getWindowData or self instead */
    if (e.origin == window.location.origin && e.data && e.data.split) {
        var action = e.data.split(':')[0];
        if (action == 'backgroundAuthResponse') {
            cleanUpIframe();
        }
    }
}

function cleanUpIframe() {
    if (iframe) {
        /* eslint-disable-next-line no-restricted-globals  -- (https://aka.ms/OWALintWiki)
         * Baseline, should investigate if file should be in th worker or we can convert to getWindowData
         *	> Unexpected use of 'window'. Do not include "window" in code included in the worker build. Use getWindowData or self instead */
        window.document.body.removeChild(iframe);
        iframe = null;
        /* eslint-disable-next-line no-restricted-globals  -- (https://aka.ms/OWALintWiki)
         * Baseline, should investigate if file should be in th worker or we can convert to getWindowData
         *	> Unexpected use of 'window'. Do not include "window" in code included in the worker build. Use getWindowData or self instead */
        window.removeEventListener('message', handleIframeResponse);
    }
}
