import type { MailboxInfo } from 'owa-client-types';
import { isDisallowedLicensingCall } from './isDisallowedLicensingCall';
import { errorThatWillCauseAlertAndThrow, type TraceErrorObject } from 'owa-trace';
import { scrubForPii } from 'owa-config';

/**
 * Checkes to make sure this is not a disallowed licensing call, and if it is, raises an alert
 * and throws an exception.
 * @param mailboxInfo Specifies the source that will be connected to
 * @param action Specifies the action being performed
 * @param requestUrl Optionally specifies the URL of the request
 */
export function disallowedLicensingCallCheck(
    mailboxInfo?: MailboxInfo,
    action?: string,
    requestUrl?: string
) {
    if (isDisallowedLicensingCall(mailboxInfo, action, requestUrl)) {
        const diagnosticInfo = {
            mailboxType: mailboxInfo?.type,
            mailboxProvider: mailboxInfo?.mailboxProvider,
            action,
            requestUrl: !!requestUrl ? scrubForPii(requestUrl) : undefined,
        };

        // Why am I seeing this error?
        // Licensing accounts differ from normal mailboxes in that they may not have a mailbox associated with
        // the user principal name. To make sure engineers are aware of this limitation we maintain a list
        // of allowedLicensingActions and allowedLicensingRequests in the isDisallowedLicensingCall.ts file.
        // If the call you are making is not in the allowed list and you will see this error you can resolve the
        // issue by adding the action or requestUrl to the allowed list. Understand that this will eventually
        const error: TraceErrorObject = new Error('DisallowedLicensingCall');
        error.additionalInfo = diagnosticInfo;
        errorThatWillCauseAlertAndThrow(error);
    }
}
