import { LazyBootModule, LazyAction } from 'owa-bundling-light';

const lazyModule = new LazyBootModule(
    () => import(/* webpackChunkName: "AppBootLocalDataAccountSupport" */ './index')
);

export const lazyAddLocalDataAdditionalAccountProxy = new LazyAction(
    lazyModule,
    m => m.lazyAddLocalDataAdditionalAccount.importAndExecute
);

export const lazyDoCleanupForRemovedLocalDataAccountProxy = new LazyAction(
    lazyModule,
    m => m.lazyDoCleanupForRemovedLocalDataAccount.importAndExecute
);

export const lazyCheckPrerequisitesProxy = new LazyAction(
    lazyModule,
    m => m.lazyCheckPrerequisites.importAndExecute
);
