type ReturnTypePromise<TFunc extends (...args: any[]) => any> = ReturnType<TFunc> extends Promise<
    infer R
>
    ? R
    : ReturnType<TFunc>;

export function wrapFunction<TFunc extends (...args: any[]) => any>(
    func: TFunc,
    before: () => void,
    after: () => void
): (...args: Parameters<TFunc>) => ReturnType<TFunc> {
    return function markedFunction(...args) {
        before();
        let result = func(...args);
        if (result?.then) {
            result = result.then((promiseResult: ReturnTypePromise<TFunc>) => {
                after();
                return promiseResult;
            });
        } else {
            after();
        }
        return result;
    };
}
