import type { CustomWaterfallRange } from './DatapointTypes';

export enum WaterfallMappings {
    // Network request wrapping
    NETWORK_REQUEST_STARTING = 0 as CustomWaterfallRange,
    NETWORK_REQUEST_ENDED = 1 as CustomWaterfallRange,
    DATA_SOURCE = 2 as CustomWaterfallRange,
    // GQL Wrapping
    DATA_LOADING = 0 as CustomWaterfallRange,
    DATA_LOADED = 1 as CustomWaterfallRange,
    GQL_BRIDGE_QUERY = 2 as CustomWaterfallRange,
    GQL_BRIDGE_RESULT = 3 as CustomWaterfallRange,
    GQL_BRIDGE_ERROR = 4 as CustomWaterfallRange,
    // LazyLoading wrapping
    CODE_LOADING = 0 as CustomWaterfallRange,
    CODE_LOADED = 1 as CustomWaterfallRange,
}
