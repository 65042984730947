import type MailboxInfo from './MailboxInfo';
import type { default as CoprincipalMailboxInfo } from './CoprincipalMailboxInfo';
import type { default as ResourceMailboxInfo } from './ResourceMailboxInfo';
import { CoprincipalMailboxRank, ResourceMailboxRank } from './MailboxRank';

export const mailboxRankChecker = {
    isCoprincipal: (mailboxInfo?: MailboxInfo): mailboxInfo is CoprincipalMailboxInfo =>
        mailboxInfo?.mailboxRank === CoprincipalMailboxRank,
    isResource: (mailboxInfo?: MailboxInfo): mailboxInfo is ResourceMailboxInfo =>
        mailboxInfo?.mailboxRank === ResourceMailboxRank,
};
