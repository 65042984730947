import type { MailboxInfo } from 'owa-client-types';
import { BootState } from '../store/schema/BootState';
import { filterToMailboxInfoForAccountSources } from './filterToMailboxInfoForAccountSources';
import getCoprincipalAccountSourcesByBootState from './getCoprincipalAccountSourcesByBootState';

/**
 * Returns MailboxInfo for all accounts
 * @returns MailboxInfos for the accounts that support settings
 */
export default function getAllCoprincipalMailboxInfos(): MailboxInfo[] {
    return filterToMailboxInfoForAccountSources(
        getCoprincipalAccountSourcesByBootState(BootState.StartupComplete)
    );
}
