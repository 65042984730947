import { isEMEARegion } from 'owa-config/lib/isEMEARegion';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Deprecating getPhysicalRing.ts
 *	> 'getVariantEnvironment' import from 'owa-metatags' is restricted. This value is resolved in ECS as a filter. Please create a feature flight if possible. */
import { getVariantEnvironment, getOneDsUrl, OneDsCollectorUrlSuffix } from 'owa-metatags';

const EUDB_COLLECTOR_ENDPOINT = 'https://eu-office.events.data.microsoft.com/';
const GCC_HIGH_COLLECTOR_ENDPOINT = 'https://tb.events.data.microsoft.com/';
const DOD_COLLECTOR_ENDPOINT = 'https://pf.events.data.microsoft.com/';

export function getCollectorUrl(
    forest: string | undefined,
    dontLogInEU?: boolean
): string | undefined {
    // Try to use "x-owa-forest" response header to decide if user is in EUDB data residency
    if (isEMEARegion(forest) && !dontLogInEU) {
        return EUDB_COLLECTOR_ENDPOINT + OneDsCollectorUrlSuffix;
    }

    // Try to get collector endpoint from "ApplicationConfig.AriaUrl" ECS setting
    const url = getOneDsUrl();
    if (url) {
        return url;
    }

    // Fall-back to hardcoded values for special environments
    const environment = getVariantEnvironment();
    if (environment === 'GccHigh') {
        return GCC_HIGH_COLLECTOR_ENDPOINT + OneDsCollectorUrlSuffix;
    } else if (environment === 'DoD') {
        return DOD_COLLECTOR_ENDPOINT + OneDsCollectorUrlSuffix;
    }

    // Use default collector endpoint that is provided by 1DS SDK
    return undefined;
}
