/**
 * Returns a promise that would wait for a given time before resolving
 *
 * @param time time in milliseconds to delay fulfillment
 */
export default function sleep(time: number) {
    return new Promise<undefined>(resolve => {
        setTimeout(resolve, time);
    });
}
