import type MailboxInfo from './MailboxInfo';

export default function isMailboxInfo(mailboxInfo: any): mailboxInfo is MailboxInfo {
    const requiredProperties: (keyof MailboxInfo)[] = [
        'type',
        'userIdentity',
        'mailboxSmtpAddress',
    ];
    return requiredProperties.every(property => mailboxInfo?.hasOwnProperty?.(property));
}
