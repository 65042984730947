/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline, please do not copy and paste this justification
 *	> 'owa-config/lib/universalCookies' import is restricted from being used. The long term plan is to deprecate the cookie so we should only be using this sparingly */
import { getCookieString, parseCookieValue } from 'owa-config/lib/universalCookies';

// the canary value has a minimum life time of 1 min so to be safe we
// will reset every minute which suffice in fixing the performance problem
const OneMinuteInMs = 1000 * 60;
const DefaultCookieValue = 'X-OWA-CANARY_cookie_is_null_or_empty';

let owaCanaryCookie: string | undefined;
let owaCanaryDebugCookie: string | undefined;
let timeoutId: number | undefined;

export function getOwaCanaryCookieValue(): string | null {
    const cookieString = getCookieString();
    const targetServer = parseCookieValue(cookieString, 'targetServer');
    let canaryCookie = parseCookieValue(
        cookieString,
        getOwaCanaryCookieName('X-OWA-CANARY', targetServer)
    );
    const canaryCookieProd = parseCookieValue(
        cookieString,
        getOwaCanaryCookieName('X-OWA-CANARY-PRD', targetServer)
    );
    const canaryCookieBlackForest = parseCookieValue(
        cookieString,
        getOwaCanaryCookieName('X-OWA-CANARY-BF', targetServer)
    );

    // we only cache the canary when we find one
    if (!canaryCookie || canaryCookie.length == 0) {
        return null;
    }

    if (canaryCookieProd && canaryCookieProd.length > 0) {
        canaryCookie += ',' + canaryCookieProd;
    }

    if (canaryCookieBlackForest && canaryCookieBlackForest.length > 0) {
        canaryCookie += ',' + canaryCookieBlackForest;
    }

    return canaryCookie;
}

export function getOwaCanaryCookie(useCacheValue?: boolean): string {
    // get a new value if we don't have one
    if (useCacheValue) {
        if (!owaCanaryCookie) {
            owaCanaryCookie = getOwaCanaryCookieValue() || DefaultCookieValue;
            self.clearTimeout(timeoutId);
            timeoutId = self.setTimeout(resetOwaCanaryCookie, OneMinuteInMs);
        }
        return owaCanaryCookie;
    }

    return getOwaCanaryCookieValue() || DefaultCookieValue;
}

export function getOwaCanaryDebugCookie() {
    if (!owaCanaryDebugCookie) {
        const cookieString = getCookieString();
        owaCanaryDebugCookie = parseCookieValue(
            cookieString,
            getOwaCanaryCookieName(
                'X-OWA-CANARY-DEBUG',
                parseCookieValue(cookieString, 'targetServer')
            )
        );
    }
    return owaCanaryDebugCookie;
}

export function resetOwaCanaryCookie() {
    owaCanaryCookie = undefined;
    owaCanaryDebugCookie = undefined;
}

function getOwaCanaryCookieName(prefix: string, serverRedirect: string | undefined): string {
    return prefix + (serverRedirect ? '_' + serverRedirect.toLocaleLowerCase() : '');
}
