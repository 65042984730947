import { isBootFeatureEnabled } from 'owa-metatags/lib/isBootFeatureEnabled';
import { isHostAppFeatureEnabled } from 'owa-hostapp-feature-flags';
import { getOrigin } from 'owa-url/lib/getOrigin';
import { getHostLocation } from 'owa-url/lib/hostLocation';
import getRootVdirName from 'owa-url/lib/getRootVdirName';

/**
 * Checks if the request is for Consumer account and whether token fetch from MSAL is enabled.
 **/
export function isMsalEnabledForConsumerDomain(): boolean {
    return (
        isBootFeatureEnabled('auth-msaljs-consumer') &&
        isHostAppFeatureEnabled('msalTokenFetch') &&
        isConsumerDomain()
    );
}

/**
 * Checks if the request is for a consumer domain
 */
export function isConsumerDomain(): boolean {
    return getOrigin().toLowerCase().includes('live') && !isIndexedPathForShadowMailbox();
}

// Returns true if url specified is an indexed path (e.g. /mail/1/) for accessing connected Shawdow Mailbox (Gmail)
export function isIndexedPathForShadowMailbox(): boolean {
    const path = getHostLocation()?.pathname;
    if (path) {
        const postVdirPath = path.split('/' + getRootVdirName() + '/')[1];
        if (postVdirPath) {
            const postVdirSegment = postVdirPath.split('/')[0];
            if (postVdirSegment && parseInt(postVdirSegment) == 1) {
                return true;
            }
        }
    }
    return false;
}
