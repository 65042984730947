import type MailboxInfo from './MailboxInfo';

export type TryLookupIndexerFunc = (mailboxInfo: MailboxInfo) => string | undefined;

let tryLookupIndexerFunc: TryLookupIndexerFunc | undefined = undefined;

/**
 * Allow the unit tests to reset the tryLookupIndexerFunc
 */
export function resetTryLookupIndexerFunc() {
    tryLookupIndexerFunc = undefined;
}

/**
 * Sets the callback for looking up the indexer based on the MailboxInfo
 * @param callback Callback that looks up the indexer for mailboxInfo
 */
export function setTryLookupIndexerFunc(callback: TryLookupIndexerFunc) {
    tryLookupIndexerFunc = callback;
}

/**
 * Determins if the tryLookupIndexerFunc is set
 * @returns true if set, false other wise
 */
export function isTryLookupIndexerFuncSet() {
    return !!tryLookupIndexerFunc;
}

/**
 * Attempts to lookup the indxer value
 * @param mailboxInfo MailboxInfo for which an indexer value is to be returned
 * @returns Indexer value or undefined
 */
export function tryLookupIndexer(mailboxInfo: MailboxInfo): string | undefined {
    return !!tryLookupIndexerFunc ? tryLookupIndexerFunc(mailboxInfo) : undefined;
}
