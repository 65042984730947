import { owaNetCoreEqualityCheck } from './utils/owaNetCoreEqualityCheck';

export const owaNetCoreComparator: {
    [key: string]: (owaResponse: any, netcoreResponse: any, actionName: string) => any;
} = {};

for (const item of getCompareDefinitions()) {
    for (const action of item.actionNames) {
        owaNetCoreComparator[action] = item.compareFn;
    }
}

interface CompareDefinition {
    compareFn: (owaResponse: any, netcoreResponse: any, actionName: string) => any;
    actionNames: Set<string>;
}

function getCompareDefinitions(): CompareDefinition[] {
    return [
        {
            compareFn: (owaResponse: any, netcoreResponse: any, actionName: string) =>
                owaNetCoreEqualityCheck(
                    owaResponse,
                    netcoreResponse,
                    actionName,
                    false /* matchValue */,
                    true /* matchArrayOrder */
                ),
            actionNames: new Set([
                'GetAccessTokenForResource',
                'GetAccessTokenforDataProvider',
                'GetAccessTokenforResource',
                'GetAttachmentDownloadToken',
                'GetClientAccessToken',
            ]),
        },
        {
            compareFn: (owaResponse: any, netcoreResponse: any, actionName: string) =>
                owaNetCoreEqualityCheck(
                    owaResponse,
                    netcoreResponse,
                    actionName,
                    true /* matchValue */,
                    true /* matchArrayOrder */
                ),
            actionNames: new Set([
                `FetchDataProviderResource`,
                `FindBookingCustomers`,
                `FindCategoryDetails`,
                `FindConversation`,
                `FindFolder`,
                `FindItem`,
                `FindOutlookQuickSteps`,
                `FindPeople`,
                `FindUnifiedGroups`,
                'GetAccountInformation',
                'GetActiveRetentionPolicyTags',
                'GetAllAttachmentDataProviders',
                'GetAllClientExtensionsNotifications',
                'GetAllMeetingTypeExtensions',
                'GetAllowedOptions',
                'GetAttachment',
                'GetAttachmentDataProviderCreator',
                'GetAttachmentDataProviderItems',
                'GetAttachmentDataProviderUploadFolderProps',
                'GetAttachmentItems',
                'GetAttachmentLabel',
                'GetAttachmentPdfPreview',
                'GetAttachmentPreviews',
                'GetAttachmentTextPreview',
                'GetAuthenticationUrl',
                'GetAvailableRetentionPolicyTags',
                'GetBirthdayCalendarView',
                'GetBirthdayEvent',
                'GetBookingAdminSettings',
                'GetBookingCalendarPublishing',
                'GetBookingCustomQuestionsCommand',
                'GetBookingMailboxDiagnostics',
                'GetBookingMailboxes',
                'GetBookingService',
                'GetBookingServices',
                'GetBookingStaff',
                'GetBookingStatistics',
                'GetBposShellInfoNavBarData',
                'GetBposShellInfoNavBarDataForBookings',
                'GetBusinessInformation',
                'GetCASMailbox2',
                'GetCalendarEvent',
                'GetCalendarFeeds',
                'GetCalendarFolderConfiguration',
                'GetCalendarFolders',
                'GetCalendarProcessing',
                'GetCalendarSharingPermissions',
                'GetCalendarView',
                'GetCertsInfo',
                'GetComplianceConfiguration',
                'GetConsumerMailbox',
                'GetConversationItems',
                'GetDlpPolicyTips',
                'GetEncryptionCerts',
                'GetExtensibilityContext',
                'GetFileItems',
                'GetFolder',
                'GetFolderChangeDigest',
                'GetFolderMruConfiguration',
                'GetFullSearchHistoryFromRest',
                'GetGroupInfo',
                'GetHolidayCalendars',
                'GetInboxRule',
                'GetItem',
                'GetLinkPreview',
                'GetMailTips',
                'GetMailboxAutoReplyConfiguration',
                'GetMailboxByIdentity',
                'GetMailboxCalendarConfiguration',
                'GetMailboxJunkEmailConfiguration',
                'GetMailboxMessageConfiguration',
                'GetMailboxPermission',
                'GetMailboxRegionalConfiguration',
                'GetMeetingTypeExtension',
                'GetMessageClassification',
                'GetMime',
                'GetMobileDeviceStatistics',
                'GetMrsConnectedAccounts',
                'GetNewAttachmentDataProviderCreationInfo',
                'GetNotesForPersona',
                'GetOrganizationHierarchyForPersona',
                'GetOtherMailboxConfiguration',
                'GetOutlookDotComMigrationDiagnostics',
                'GetOwaNonBootUserConfiguration',
                'GetOwaUserConfiguration',
                'GetPeopleFilters',
                'GetPeopleIKnowGraphCommand',
                'GetPersona',
                'GetPersonalListsMapping',
                'GetPersonalSchedulingPage',
                'GetReactFlightsAndSettings',
                'GetRegionalConfiguration',
                'GetReminders',
                'GetRoomIcon',
                'GetSharePointTextFileContent',
                'GetSharingInformation',
                'GetSharingPermissionInfo',
                'GetSharingPermissions',
                'GetStaffAvailability',
                'GetSuggestedUnifiedGroups',
                'GetSweepRules',
                'GetTimeZone',
                'GetTimeZoneOffsets',
                'GetUnifiedGroupDetails',
                'GetUnifiedGroupMembers',
                'GetUnifiedGroupMembershipRequests',
                'GetUnifiedGroupsSettings',
                'GetUserAvailabilityInternal',
                'GetUserConfiguration',
                'GetUserRetentionPolicyTags',
                'GetUserUnifiedGroups',
                'GetUserVoiceData',
                'GetWacAttachmentInfo',
                'UserBootSettings',
                'StartupData',
            ]),
        },
    ];
}
