type Filter<T, U> = T extends U ? T : never;
type EnabledHints = Filter<
    NavigatorHighEntropyValueHints,
    'architecture' | 'bitness' | 'platformVersion' | 'model'
>;

const enabledHints: {
    [_P in EnabledHints]: 1;
} = {
    architecture: 1,
    bitness: 1,
    platformVersion: 1,
    model: 1,
};

let cachedHighEntropyValues: Record<EnabledHints, string> | null = null;
let needToFetchValues = true;

export async function getHighEntropyValues() {
    // we only want to call this once even if the value is null. This is becuase an error
    // could be thrown and we don't want to keep causing errors
    if (needToFetchValues) {
        needToFetchValues = false;
        if (self.navigator.userAgentData?.getHighEntropyValues) {
            try {
                cachedHighEntropyValues = await self.navigator.userAgentData.getHighEntropyValues(
                    Object.keys(enabledHints) as EnabledHints[]
                );
            } catch {
                // return null if an error is thrown
            }
        }
    }
    return cachedHighEntropyValues;
}
