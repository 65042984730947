import type {
    LocationBootstrapSettings,
    LocalStorageBootstrapSettings,
    WindowBootstrapSettings,
    CoreWindowBootstrapSettings,
    CoreWindowRequestSettings,
} from 'owa-worker-types';
import { getMetatags } from 'owa-metatags';
import { getBrowserWidth, getBrowserHeight } from 'owa-config';
import { getLocalStorage as getLocalStorageItem } from 'owa-local-storage';

export function buildRequestWindowData(): CoreWindowRequestSettings {
    let cookie = '';
    // Firefox might block the access to cookies which causes an error.
    try {
        cookie = self.document?.cookie;
    } catch (e) {
        // no-op
    }

    return {
        ...buildCoreWindowData(),
        cookie,
    };
}

export function buildWindowData(includeLocalStorage: boolean = true): WindowBootstrapSettings {
    // fetching local storage has shown latencies in some long tail cases, so it's excluded
    // for precompileSchema, which is an optimization that runs in parallel with render
    const localStorageData: LocalStorageBootstrapSettings = includeLocalStorage
        ? getLocalStorage()
        : {};

    let cookie = '';
    // Firefox might block the access to cookies which causes an error.
    try {
        cookie = self.document?.cookie;
    } catch (e) {
        // no-op
    }

    return {
        ...buildCoreWindowData(),
        innerHeight: getBrowserHeight(),
        innerWidth: getBrowserWidth(),
        isPwa: self.matchMedia?.('(display-mode: standalone)')?.matches,
        cookie,
        localStorage: localStorageData,
    };
}

export function buildCoreWindowData(): CoreWindowBootstrapSettings {
    const locationData: LocationBootstrapSettings = {
        search: self.location.search,
        host: self.location.host,
        protocol: self.location.protocol,
        hostname: self.location.hostname,
        href: self.location.href,
        pathname: self.location.pathname,
        hash: self.location.hash,
        port: self.location.port,
        origin: self.location.origin,
    };
    return {
        location: locationData,
        metatags: getMetatags(),
        origin: self.origin,
    };
}

function getLocalStorage() {
    try {
        return {
            ...getLocalStorageItem(self),
        };
    } catch {
        return {};
    }
}
