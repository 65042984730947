import type { MetatagName } from './schema/MetatagName';

export type MetatagValues = {
    [key in MetatagName]?: string | undefined;
};

let savedMetatags: MetatagValues | undefined;
export default function findMetatag(tagName: MetatagName): string | undefined {
    if (!savedMetatags) {
        savedMetatags = getMetatags();
    }

    return savedMetatags[tagName];
}

export function getMetatags(): MetatagValues {
    if (!savedMetatags) {
        savedMetatags = calculateMetatags();
    }
    return savedMetatags;
}

function calculateMetatags(): MetatagValues {
    /* eslint-disable-next-line no-restricted-globals  -- (https://aka.ms/OWALintWiki) Only window has document */
    const tags = Array.from(window.document.getElementsByTagName('meta'));
    const result: MetatagValues = {};
    for (const tag of tags) {
        result[tag.name as MetatagName] = tag.content;
    }
    return result;
}

export function overrideGetMetatags(newMetatags: MetatagValues) {
    savedMetatags = newMetatags;
}
