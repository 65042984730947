import type MailboxInfo from 'owa-client-types/lib/MailboxInfo';
import { logStartGreyError } from 'owa-analytics-start/lib/logStartUsage';

// Do not log from then 10 entries for a given client
const maxLoggingCount = 10;
let loggingCount = 0;

/**
 * To help determine why we have MailboxInfo's without diagnostic data we need to check
 * the mailbox info when the request is made. This will help to track down the callers
 * that are not setting the diagnostic data for the MailboxInfo
 * @param mailboxInfo MailboxInfo to be checked for diagnostic data
 */
export function checkMailboxInfoForDiagnosticData(mailboxInfo?: MailboxInfo, callerStack?: string) {
    if (!!mailboxInfo && !mailboxInfo.diagnosticData && loggingCount < maxLoggingCount) {
        loggingCount++;
        logStartGreyError(
            'MailboxInfoDiagnosticDataMissing',
            new Error('MailboxInfoDiagnosticDataMissing'),
            { type: mailboxInfo.type, callerStack }
        );
    }
}
