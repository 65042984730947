import type { OverrideTenants } from 'owa-analytics-types';
import { hasQueryStringParameter } from 'owa-querystring';

// Defaut tenant for Outlook Web Aria project
const DEFAULT_TENANT_TOKEN =
    '56468f6991c348029c6bba403b444607-7f5d6cd1-7fbe-4ab1-be03-3b2b6aeb3eb4-7696';

// Override map for events that need to logged to a different tenant than the current app
const overrideToTenantMap = new Map<OverrideTenants, string>();
overrideToTenantMap.set('OutlookWeb', DEFAULT_TENANT_TOKEN);
overrideToTenantMap.set(
    'CLP',
    '20ed4f5863d145bb9b9811c0f016da47-0f867f8d-a0be-44dc-b557-6da8178006f7-7559'
);

// Map of app to tenants
const appToTenantMap = new Map<string, string>();
appToTenantMap.set(
    'BookWithMe',
    'ae3a308c9d714a9bafbae96e11115bc2-cf5099e3-5467-4f24-98eb-fef77b0802fa-6900'
);
appToTenantMap.set(
    'FindTime',
    '199fda8f7c2e423291001798b2f173c5-fcb15ea6-c8e1-430e-bfed-450cc70fcd67-6876'
);
appToTenantMap.set(
    'Eventify',
    '64c370c14bad4d92915f904803e88197-5dd29cf4-d011-45e0-b2e5-aade29021e96-6966'
);
appToTenantMap.set(
    'Meet',
    '199fda8f7c2e423291001798b2f173c5-fcb15ea6-c8e1-430e-bfed-450cc70fcd67-6876'
);
appToTenantMap.set(
    'People',
    '90c31e9f702c4564b120167247d3cd9f-d894a4e5-72f4-4221-ab00-94e1359dfb60-7137'
);

export function getAriaTenantToken(app: string | null, tenantOverride?: OverrideTenants): string {
    // Only enable the devTenant query parameter when in running in ori (dev mode)
    if (process.env.IS_OWA_DEV_ESBUILD && hasQueryStringParameter('devTenant')) {
        return '2c49c59dcdcc44e9a65954c57406468e-15905d48-20d4-4216-b6ea-82a88fb1f45a-7480';
    }

    // If an event has a tenant override, use that
    if (tenantOverride && overrideToTenantMap.has(tenantOverride)) {
        return overrideToTenantMap.get(tenantOverride) as string;
    }

    // Check if an app has a different tenant
    if (app && appToTenantMap.has(app)) {
        return appToTenantMap.get(app) as string;
    }

    // Everything else is logged to the Outlook Web tenant
    return DEFAULT_TENANT_TOKEN;
}
