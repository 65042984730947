import { getCdnUrl } from 'owa-config/lib/bootstrapOptions';

const fonts: {
    [key: string]: {
        weight: string;
        locals: string[];
    };
} = {
    'segoeui-light': { weight: '100', locals: ['Segoe UI Light', 'Segoe WP Light'] },
    'segoeui-semilight': { weight: '200', locals: ['Segoe UI Semilight', 'Segoe WP Semilight'] },
    'segoeui-regular': { weight: '400', locals: ['Segoe UI', 'Segoe WP'] },
    'segoeui-semibold': { weight: '600', locals: ['Segoe UI Semibold', 'Segoe WP Semibold'] },
};

export function preloadFonts() {
    return Promise.allSettled(
        Object.entries(fonts).map(
            ([woffName, info]: [
                string,
                {
                    weight: string;
                    locals: string[];
                }
            ]) => {
                const localRefs = info.locals.map(local => `local("${local}"), `).join('');
                const font = new FontFace(
                    'Segoe UI WestEuropean',
                    `${localRefs}url(${getCdnUrl()}assets/mail/fonts/v1/fonts/${woffName}.woff)`,
                    { weight: info.weight, style: 'normal' }
                );

                // typescript does not have the definition for add method
                (document.fonts as any).add(font);

                // Load the font
                return font.load();
            }
        )
    );
}
