import { NATIVE } from './types/HostApp';
import { getOpxHostApp } from './getOpxHostData';
import { getHostHub } from './getHostHub';
import { isRunningInMetaOSHub } from './isRunningInMetaOSHub';
import { getNativeHostVersion } from './enhancedHost';

export function getHostValue() {
    // There are a few cases in which the nativeVersion query string parameter can be removed
    // when the native-host application accidently reloads without it. getNativeHostVersion
    // has a cached enhanced host in case of this
    if (getNativeHostVersion()) {
        return NATIVE;
    }

    if (isRunningInMetaOSHub()) {
        return getHostHub();
    }

    return getOpxHostApp();
}
