import type { MailboxInfo } from 'owa-client-types';
import type { M365UserMailboxAccountSource } from '../store/schema/AccountSourceList';
import { accountSourceDataTypeChecker } from '../store/schema/AccountSourceList';
import getCoprincipalAccountForMailboxInfo from './getCoprincipalAccountForMailboxInfo';

function findMatchingArchiveMailboxInfo(
    account: M365UserMailboxAccountSource,
    auxiliaryMailboxGuid: string
): MailboxInfo | undefined {
    const lowercaseGuid = auxiliaryMailboxGuid.toLowerCase();
    const matchingArchives = account.archiveSources.sources.filter(
        archiveSource =>
            lowercaseGuid === archiveSource.mailboxInfo.auxiliaryMailboxGuid?.toLowerCase()
    );

    return matchingArchives[0]?.mailboxInfo;
}

export function getArchiveMailboxInfo(
    mailboxInfo: MailboxInfo,
    auxiliaryMailboxGuid: string
): MailboxInfo | undefined {
    const account = getCoprincipalAccountForMailboxInfo(mailboxInfo);
    if (accountSourceDataTypeChecker.isM365UserMailbox(account)) {
        return findMatchingArchiveMailboxInfo(account, auxiliaryMailboxGuid);
    }

    return undefined;
}
