// Track delayed mobx reactions to allow for callers to wait until delayed reactions are complete.
let delayedReaction: Promise<unknown> | null = null;

export function setDelayedReaction(value: Promise<unknown>) {
    delayedReaction = value;
}

export function getDelayedReaction() {
    return delayedReaction;
}
