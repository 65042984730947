import { getHostLocation } from './hostLocation';

export default function getRootVdirName(
    preserveHostedPath?: boolean,
    overrideHostPath?: string
): string | null {
    const pathname = getHostLocation()?.pathname;
    if (pathname) {
        const subPaths = pathname.split('/').filter(part => !!part);
        const vdir = subPaths[0]?.toLowerCase() ?? null;
        if (vdir === 'hosted') {
            if (preserveHostedPath && subPaths[1]) {
                // Preserve hosted endpoint such that the path is hosted/{AppName}
                return [subPaths[0], subPaths[1]].join('/').toLowerCase();
            } else if (subPaths[1]) {
                return subPaths[1].toLowerCase();
            } else {
                return null;
            }
        } else if (vdir === 'host' && overrideHostPath) {
            return overrideHostPath;
        } else {
            return vdir;
        }
    }
    return null;
}
