import { getClientVersion } from './bootstrapOptions';
import doesBuildMatch from './utils/doesBuildMatch';

export default function doesClientBuildMatch(matchStrings: string[] | undefined): boolean {
    if (!matchStrings?.length) {
        return false;
    }

    const currentBuild = getClientVersion();
    return doesBuildMatch(currentBuild, matchStrings);
}
