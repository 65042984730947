import isRetriableAuthError from './isRetriableAuthError';
import type HttpStatusCode from 'owa-http-status-codes/lib/HttpStatusCode';

export function isRetriableStatusCode(status: number): boolean {
    return (
        status == 0 ||
        status == 408 ||
        status == 449 ||
        status == 500 ||
        status == 501 ||
        status == 502 ||
        status == 503 ||
        status == 504 ||
        isRetriableAuthError(status)
    );
}
