let fault: Error | undefined;

export function getDataWorkerFault() {
    return fault;
}

export function getIsDataWorkerFaulted() {
    return !!fault;
}

export function setIsDataWorkerFaulted(e: Error | undefined) {
    fault = e;
}
