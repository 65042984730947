import type { BaseRequestOptions } from 'owa-analytics-types/lib/types/BaseRequestOptions';
import type OwaUserConfiguration from 'owa-service/lib/contract/OwaUserConfiguration';
import type { SessionData } from 'owa-service/lib/types/SessionData';
import { logOwaUserConfigurationCallInfo } from './owaUserConfigurationLoadingLogs';

export async function handleServiceResponseCallbackForPolicySettingsLog(
    response: Response,
    actionName: string,
    optionsPromise: Promise<BaseRequestOptions>
) {
    const options: BaseRequestOptions = await optionsPromise.then(o => o);

    // There are some requests that are not related to account policy.
    // We are not logging the response of those requests
    if (
        (options as any).scenarioId === 'accountPolicy' &&
        (actionName === 'GetOwaUserConfiguration' || actionName === 'StartupData')
    ) {
        // const response = await responsePromise.then(r => r.clone());

        const owasUserConfig =
            actionName === 'GetOwaUserConfiguration'
                ? ((await response.json()) as OwaUserConfiguration)
                : ((await response.json()) as SessionData)?.owaUserConfig;

        logOwaUserConfigurationCallInfo(
            actionName as any,
            response.headers.get('X-OWA-CorrelationId'),
            response.headers.get('X-Backend-Begin'),
            response.headers.get('X-Backend-End'),
            options.mailboxInfo,
            owasUserConfig
        );
    }
}
