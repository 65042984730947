import findMetatag from './findMetatag';

// Boot features
//    'acct-disableUserIdentityCheck' -- disable the logging of userIdentities that are not in the account source list store
//    `acct-reflowAndDefaultTelemetry` -- Indicates that the telemetry for reflow methods that should not be called is enabled
//    `acctErrStorageSetV2` -- When enabled the owa-account-storage package will throw an exception if invalid data is set
//    `acctNoFallback` -- Disables the fallback to global settings account in place of the selected account
//    `acctPersistentIdIndexerV2` -- when enabled, the indexer value comes from the persistence id of the account
//    `acctManualAccountType` -- when enabled, allows the manual selected of the type of account being added
//    `acctStartDataV25` - Flight for rolling out the new OWA User Configuration start data feature Monarch
//    `acctStartDataOWAV2` - Flight for rolling out the new OWA User Configuration start data feature OWA
//    `acct-otherMicrosoftAccount` - Flight for rolling out ability to add licenced subscription accounts in oobe
//    `fwk-consoleErrors` -- Surface console errors by showing a dialog
//    `fwk-cssPerf` -- Enables an alternate set of CSS rules that should be more performant during style recalculation
//    `fwk-persistOIDInUrl` -- Persists the OID(user unique identifier) in the root URL
//    `supportonboot` -- launches the support flow if boot fails
//    `appservicequery` -- when enabled, fetch metaOs apps from app service api
//    `accountsapi` -- when enabled the Monarch multi-account UX functionality is enabled in non monarch applications
//    `offlineWeb` -- enables offline in the browser, rather than just in monarch
//    `dev-bootOfflineFolders` -- enables offline folder resolvers
//    `dev-bootOffline` -- boot as if offline disabling service requests until user re-enables in diagnostics
//    `monPromptOnSilentFailure` -- enables NativeHost to fail on getTokenSilently and call getTokenInteractively when interaction is required from user
//    `mobxproxies` -- configures MobX to use proxies: https://mobx.js.org/configuration.html#proxy-support
//    `shellmultiorigin` -- Enable EUDB vs. WWDB domain branching for OPX shell.
//    `auth-msaljs-consumer` -- Enables the use of MSAL to get auth token for consumer accounts
//    `detectcmpfnleaks` -- Enables wrapper around computedFn to detect leaks
//    `disablecmpfn` -- Disables wrapping functions in computedFn to measure the impact
//    `jsselfprofiler` -- This flag lets us know when the JS self profiler is enabled
//    `bookwithme-c2B2MigrationAnonymous` -- Enables the anonymous user flow for C2B2 migration
//    `auth-cacheTokenForMetaOsHub` -- Enable token caching for MetaOS integrations. Token caching involves decoding of bearer token to extract expiry & PUID
//    `auth-disable-onAuthFailedForMetaOsHub` -- Rip cord for teams bug fix that implments onAuthFailed to remove the owa token from the cache when a service request fails with a 401
//    `auth-useAuthTokenClaimsForMetaOsHub` -- Enable use of claims in the token to be used for auth for MetaOS integrations.
//    `cal-widgets-upn-validation` -- Enables UPN validation for widgets login, and prevents the creation of the OPX frame with invalid routingHints
//    `cal-reload-pause` -- Reloads the calendar app when the app is cached and pauses loading of resources
//    `cal-store-NavBarData` -- Flight to retrieve NavBarData from SessionData which is used for M365 Licensing in hosted calendar
//    `auth-cloudCache-basicAuthPasswordLookup` -- Attempt to get the password for a cloud cache account using basic auth (IMAP/POP, etc.)
//    `acctpopCCInOobe` -- when enabled, we're allowing Pop accounts to be added through OOBE (i.e: migration, as first acct..)
//    `auth-msaljs-business` -- Enables full client side authentication for business account using MSAL.js library.
//    `auth-msaljs-hosted` -- Enables use of NAA in MetaOS-hosted 1P modules using MSAL.js library.
//    `auth-msaljs-opx` -- Enables use of NAA in OPXes using MSAL.js library.
//    `auth-msaljs-newsletters` -- Enables authentication using MSAL.js library in Newsletters/Addison.
//    `auth-msaljs-places` -- Enables authentication using MSAL.js library in Places.
//    `auth-msaljs-eventify` -- Enables authentication using MSAL.js library in Eventify.
//    `auth-msaljs-bookings` -- Enables authentication using MSAL.js library in Bookings.
//    `auth-msaljs-findtime` -- Enables authentication using MSAL.js library in FindTime.
//    `auth-msaljs-meet` -- Enables authentication using MSAL.js library in Meet.
//    `auth-msaljs-disableCaeCapabilities` -- Disables CAE capabilities in MSAL.js library for local dev testing only.
//    `auth-codeChallenge` -- Enables PKCE flow for Google cloud cache accounts.
//    `acct-mbxpkg-boot` -- used to control the usage of the owa-mailbox-package within the application
//    `fwk-loc-single-v2`  -- Use a single observable for all localized strings
//    `acct-roaming` -- Enables the roaming feature for accounts
//    `cal-widgets-disableWidgets` -- Prevents the loading of the Windows Widgets and shows a deprecation message
//    `msplaces-hosted-localsessiondata` -- Enables the use of local session data in hosted places
//    `fwk-netcore-api ` -- Enables routing traffic from /owa/ to /owanetcore/
export type BootFeature =
    | 'acct-disableUserIdentityCheck'
    | 'acct-reflowAndDefaultTelemetry'
    | 'acctErrStorageSetV2'
    | 'acctNoFallback'
    | 'acctPersistentIdIndexerV2'
    | 'acctManualAccountType'
    | 'acctStartDataV25'
    | 'acctStartDataOWAV2'
    | 'acct-otherMicrosoftAccount'
    | 'fwk-consoleErrors'
    | 'fwk-cssPerf'
    | 'fwk-persistOIDInUrl'
    | 'supportonboot'
    | 'appservicequery'
    | 'pieUpdateIdentityAliasesMap'
    | 'accountsapi'
    | 'offlineWeb'
    | 'dev-bootOfflineFolders'
    | 'dev-bootOffline'
    | 'monPromptOnSilentFailure'
    | 'mobxproxies'
    | 'shellmultiorigin'
    | 'emergencyDisableBpos'
    | 'auth-msaljs-consumer'
    | 'detectcmpfnleaks'
    | 'disablecmpfn'
    | 'jsselfprofiler'
    | 'bookwithme-c2B2MigrationAnonymous'
    | 'auth-cacheTokenForMetaOsHub'
    | 'auth-disable-onAuthFailedForMetaOsHub'
    | 'auth-useAuthTokenClaimsForMetaOsHub'
    | 'cal-widgets-upn-validation'
    | 'cal-reload-pause'
    | 'cal-store-NavBarData'
    | 'auth-cloudCache-basicAuthPasswordLookup'
    | 'acctpopCCInOobe'
    | 'auth-msaljs-business'
    | 'auth-msaljs-hosted'
    | 'auth-msaljs-opx'
    | 'auth-msaljs-newsletters'
    | 'auth-msaljs-places'
    | 'auth-msaljs-places-sessionStorage'
    | 'auth-msaljs-eventify'
    | 'auth-msaljs-bookings'
    | 'auth-msaljs-findtime'
    | 'auth-msaljs-meet'
    | 'auth-msaljs-disableCaeCapabilities'
    | 'auth-msaljs-sessionStoreAsCacheLocation'
    | 'auth-codeChallenge'
    | 'acct-mbxpkg-boot'
    | 'disableConcurrency'
    | 'workerAsyncLoad'
    | 'fwk-loc-single-v2'
    | 'acct-roaming'
    | 'cal-widgets-disableWidgets'
    | 'msplaces-hosted-localsessiondata'
    | 'fwk-netcore-api';

// these are specifically for features that need to be decided before userbootsettings comes back.
let bootFlights: string[] | undefined;
let bootFlightsLowercase: Set<string> | undefined;
let bootFlightsWithAllocationIds: string[] | undefined;
export function isBootFeatureEnabled(feature: BootFeature) {
    return getBootFlightsLowercase().has(feature.toLowerCase());
}

export function getBootFlights(): string[] {
    if (!bootFlights) {
        const meta = findMetatag('bootFlights');
        bootFlights = meta ? meta.split(',').map(m => m.split(':')[0]) : [];
    }
    return bootFlights;
}

export function getBootFlightsWithAllocationIds(): string[] {
    if (!bootFlightsWithAllocationIds) {
        const meta = findMetatag('bootFlights');

        bootFlightsWithAllocationIds = meta ? meta.split(',') : [];
    }
    return bootFlightsWithAllocationIds;
}

export function test_reset() {
    bootFlights = undefined;
    bootFlightsLowercase = undefined;
}

function getBootFlightsLowercase(): Set<string> {
    if (!bootFlightsLowercase) {
        bootFlightsLowercase = new Set(getBootFlights().map(key => key.toLowerCase()));
    }
    return bootFlightsLowercase;
}
