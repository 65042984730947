import getAccountSourceListStore from '../store/accountSourceListStore';
import { setCachedCoprincipalUserIdentities } from 'owa-account-user-identities/lib/cachedCoprincipalUserIdentities';

export function updateCoprincipalUserIdentities() {
    setCachedCoprincipalUserIdentities(
        new Set<string>(
            getAccountSourceListStore().sources.map(account => account.mailboxInfo.userIdentity)
        )
    );
}
