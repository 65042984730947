import { Module } from 'owa-workloads/lib/store/schema/Module';
import { isRunningInMetaOSHub } from 'owa-config';
import getSessionData from 'owa-shared-start/lib/getSessionData';

export const overrideStartConfig = (mod: string) => {
    return mod == Module.People && isRunningInMetaOSHub()
        ? {
              overrideBootPromises: () => getSessionData(),
              loadBpos: false,
              disableFeature: {
                  offlineSessionData: true,
              },
          }
        : {};
};
