import { getNativeHostVersion } from './enhancedHost';
import doesBuildMatch from './utils/doesBuildMatch';

export default function doesNativeBuildMatch(matchStrings: string[] | undefined): boolean {
    const currentBuild = getNativeHostVersion();

    if (!currentBuild || !matchStrings?.length) {
        return false;
    }

    return doesBuildMatch(currentBuild, matchStrings);
}
