import { createBootReliabilityAriaEvent } from './createBootReliabilityAriaEvent';
import { postSignal } from './ariaUtils';
import { getServerErrorDiagnostics } from './getServerErrorDiagnostics';
import { scrubForPii, getApp } from 'owa-config';
import calculateBootResult from './calculateBootResult';
import { getStartupDataRetryStrategy } from './getStartupDataRetryStrategy';
import type RetryStrategy from './interfaces/RetryStrategy';
import { appendMiscData } from './miscData';
import sleep from 'owa-sleep';
import createBootError from './createBootError';
import { createStatusErrorMessage } from './createStatusErrorMessage';
import type ErrorDiagnostics from './interfaces/ErrorDiagnostics';
import type { BootResult } from 'owa-shared-start-types';

export async function onBeforeRetry(endpoint: string, response: Response) {
    const error = createBootError(
        createStatusErrorMessage(response),
        'StartupData',
        scrubForPii(response.url)
    );
    const errorDiagnostics = getServerErrorDiagnostics(error);
    const retryStrategy: RetryStrategy | null = getStartupDataRetryStrategy(
        calculateBootResult(error, errorDiagnostics)
    );
    if (retryStrategy) {
        await logRetry('serverRetry', error, errorDiagnostics);
        if (retryStrategy.waitFor) {
            // timeout the waitFor by 10 seconds and ignore errors
            await Promise.race([sleep(10000), retryStrategy.waitFor.catch(() => {})]);
        }

        if (retryStrategy.timeout) {
            await sleep(retryStrategy.timeout);
        }

        return {
            endpoint: `${endpoint}?bO=${retryStrategy.strategy}&app=${getApp()}`,
        };
    }

    return;
}

export function logRetry(eventName: BootResult, error: any, errorDiagnostics?: ErrorDiagnostics) {
    appendMiscData('StartupDidRetry', 't');
    return postSignal(
        createBootReliabilityAriaEvent(
            eventName,
            errorDiagnostics || getServerErrorDiagnostics(error),
            undefined,
            error
        )
    );
}
