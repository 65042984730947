import assert from 'owa-assert/lib/assert';
import { mutatorAction } from 'satcheljs';
import getAccountBySourceId from '../selectors/getAccountBySourceId';
import getAccountSourceListStore from '../store/accountSourceListStore';

/**
 * Update the displayName of the specified Coprincipal account source in the store
 */
export default mutatorAction(
    'UPDATE_COPRINCIPAL_ACCOUNT_DISPLAY_NAME',
    function (sourceId: string, displayName: string): void {
        assert(getAccountBySourceId(sourceId), 'Cannot update account not in store');
        const store = getAccountSourceListStore();
        /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
         * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
         *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
        store.sources.forEach(source => {
            if (source.sourceId === sourceId) {
                source.displayName = displayName;
            }
        });
    }
);
