// Specifies the source of the account information
export enum AccountSourceType {
    Office365 = 'Office365',
    OutlookDotCom = 'Outlook',
    Google = 'Google',
    ICloud = 'ICloud',
    Yahoo = 'Yahoo',
    IMAP = 'IMAP',
    POP3 = 'POP3',
    PstFile = 'PstFile',
    Other = 'Other',
}

/* eslint-disable-next-line owa-custom-rules/no-optional-any-parameter -- (https://aka.ms/OWALintWiki)
 * DO NOT COPY-PASTE! This code should be fixed by any developer touching this code
 *	> Optional function parameters should not have type "any". This can hide undefined/null references otherwise detectable by the transpiler. */
function isString(value?: any): value is string {
    return typeof value === 'string';
}

/* eslint-disable-next-line owa-custom-rules/no-optional-any-parameter -- (https://aka.ms/OWALintWiki)
 * DO NOT COPY-PASTE! This code should be fixed by any developer touching this code
 *	> Optional function parameters should not have type "any". This can hide undefined/null references otherwise detectable by the transpiler. */
export function isAccountSourceType(value?: any): value is AccountSourceType {
    return isString(value) && value in AccountSourceType;
}

export const providerInfo = {
    OFFICE365: AccountSourceType.Office365,
    OUTLOOKDOTCOM: AccountSourceType.OutlookDotCom,
    GOOGLE: AccountSourceType.Google,
    YAHOO: AccountSourceType.Yahoo,
    ICLOUD: AccountSourceType.ICloud,
    IMAP: AccountSourceType.IMAP,
    POP3: AccountSourceType.POP3,
    OTHER: AccountSourceType.Other,
};

export interface AccountSourceProtocolData {
    protocol: string;
    hostname: string;
    port: number;
    encryption: string;
    username: string;
    secret: string;
    validated?: boolean;
}

// The keys that are used to store the account source protocol data in local storage, this list
// must not include the secret (which can be a password) or in memory state
type AccountSourceProtocolMetadataKeys =
    | 'protocol'
    | 'hostname'
    | 'port'
    | 'encryption'
    | 'username';

/**
 * The fields for the account source protocol that can be stored in local storage
 */
type AccountSourceProtocolMetadataFields = Pick<
    AccountSourceProtocolData,
    AccountSourceProtocolMetadataKeys
>;

/**
 * The data for the account source protocol that can be stored in local storage
 */
export interface AccountSourceProtocolMetadata extends AccountSourceProtocolMetadataFields {
    needsPassword?: boolean;
}

// The recommended default protocol settings for IMAP and SMTP
export const DefaultProtocolDataValues = {
    IMAP_port: 993,
    IMAP_encryption: 'ssl',
    POP3_port: 995,
    POP3_encryption: 'ssl',
    SMTP_port: 587,
    SMTP_encryption: 'ssl',
};

type SupportedProtocolAccountTypes = AccountSourceType.IMAP | AccountSourceType.POP3;
type ProtocolOption = Record<SupportedProtocolAccountTypes, string>;
export const protocolInfo: ProtocolOption = {
    [AccountSourceType.IMAP]: 'imap',
    [AccountSourceType.POP3]: 'pop3',
};
