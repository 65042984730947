import { isResourceTimingSupported } from './performanceTiming';
import type {
    LogUsageFunction,
    ServiceResponseCallback,
    LogGreyErrorFunction,
    LogCoreGreyErrorFunction,
    LogCoreUsageFunction,
} from 'owa-analytics-types';
import {
    initLogUsageFunc,
    initCoreLogUsageFunc,
    initLogGreyErrorFunc,
    initCoreLogGreyErrorFunc,
} from './logStartUsage';

const defaultResourceTimingBufferSize: number = 500;
const cachedNetworkRequests: any[] = [];

let shouldCache = true;

export function addAnalyticsCallbacks(
    registerOwsCreateServiceResponseCallback: (cb: ServiceResponseCallback) => void,
    registerOwsPrimeCreateServiceResponseCallback: (cb: ServiceResponseCallback) => void
) {
    registerOwsCreateServiceResponseCallback(trackServiceRequest);
    registerOwsPrimeCreateServiceResponseCallback(trackServiceRequest);
    if (isResourceTimingSupported()) {
        if (self.performance.setResourceTimingBufferSize) {
            self.performance.setResourceTimingBufferSize(defaultResourceTimingBufferSize);
        } else if (self.performance.webkitSetResourceTimingBufferSize) {
            self.performance.webkitSetResourceTimingBufferSize(defaultResourceTimingBufferSize);
        }
    }
}

export function getCachedInfo(
    logUsageFunc: LogUsageFunction,
    logCoreUsageFunc: LogCoreUsageFunction,
    logGreyErrorFunc: LogGreyErrorFunction,
    logCoreGreyErrorFunc: LogCoreGreyErrorFunction
) {
    const cachedStartUsage = initLogUsageFunc(logUsageFunc);
    const cachedCoreStartUsage = initCoreLogUsageFunc(logCoreUsageFunc);
    const cachedStartGreyError = initLogGreyErrorFunc(logGreyErrorFunc);
    const cachedCoreStartGreyError = initCoreLogGreyErrorFunc(logCoreGreyErrorFunc);
    return {
        network: cachedNetworkRequests,
        usage: cachedStartUsage,
        coreUsage: cachedCoreStartUsage,
        greyError: cachedStartGreyError,
        coreGreyError: cachedCoreStartGreyError,
    };
}

export function stopCachingInfo() {
    shouldCache = false;
}

function trackServiceRequest() {
    if (shouldCache) {
        cachedNetworkRequests.push(arguments);
    }
}

interface IPerformance {
    webkitSetResourceTimingBufferSize: any;
    webkitClearResourceTimings: any;
}

declare global {
    /* eslint-disable-next-line @typescript-eslint/no-empty-interface  -- (https://aka.ms/OWALintWiki)
     * This is overriding an existing interface named `Performance`
     */
    interface Performance extends IPerformance {}
}
