let plt: number | undefined;
let bootCompleted: number | number;

export function setPltValues(newPlt: number | undefined, newBootCompleted: number) {
    plt = newPlt;
    bootCompleted = newBootCompleted;
}

// PLT can be undefined if the user was prompted for auth which would make the value invalid
export function getPlt() {
    return plt;
}

// this is the value of boot completed which will always be defined even if the user was
// prompted for auth
export function getBootCompleted() {
    return bootCompleted;
}
