import { logStartUsage } from 'owa-analytics-start/lib/logStartUsage';
import { trace } from 'owa-trace';
import { equalityCheck } from './equalityCheck';

export function owaNetCoreEqualityCheck(
    owaResponse: any,
    netcoreResponse: any,
    actionName: string,
    matchValue?: boolean,
    matchArrayOrder?: boolean
) {
    const check = equalityCheck(
        owaResponse,
        netcoreResponse,
        actionName,
        matchValue,
        matchArrayOrder
    );
    if (check.result) {
        return netcoreResponse;
    }
    trace.warn(`[NetCoreDiff] ${actionName} ${check.diff}`);
    logStartUsage('NetCoreDiff', { actionName, diff: check.diff });
    return owaResponse;
}
