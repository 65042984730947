import type { MailboxInfo } from 'owa-client-types';
import isGlobalSettingsMailbox from './isGlobalSettingsMailbox';
import getCoprincipalMailboxInfoForMailboxInfo from './getCoprincipalMailboxInfoForMailboxInfo';

/**
 * Checks whether the coprincipal mailbox associated with the supplied mailbox
 * is the same as the global mailbox.
 * @param mailboxInfo Identifies a mailbox
 */
export default function isCoprincipalGlobalSettingsMailbox(mailboxInfo: MailboxInfo): boolean {
    const coprincipalMailboxInfo: MailboxInfo | undefined =
        getCoprincipalMailboxInfoForMailboxInfo(mailboxInfo);

    return !!coprincipalMailboxInfo && isGlobalSettingsMailbox(coprincipalMailboxInfo);
}
