import { getCalendarViewType } from './utils/getCalendarViewType';
import { getMessageCount } from './utils/getMessageCount';
import type { SessionDataParams } from './types/SessionDataParams';

const OpxRoutesWithCalendarFoldersOptimization = ['/myday', '/calendarwidget', '/insights'];

const MessageCountHeader = 'X-Message-Count';
const FolderCountHeader = 'X-Folder-Count';
const CalendarFoldersHeader = 'X-Calendar-Folders';
const CalendarViewHeader = 'X-Calendar-View';
const CalculateRangeFromViewHeader = 'X-Calculate-Range-From-ViewType';

export function addSessionDataHeaders(
    headers: Headers,
    pathname: string,
    sessionDataParams: SessionDataParams
) {
    // The JS Experiment is a bit map
    // 1 - Turn on feature flags. Every app will want this. So on by default
    // 2 - unused
    // 4 - Deprecate Jsmvvm features - Only on when enabled
    headers.append('X-Js-Experiment', '5');

    const { initialFolders, windowHeight } = sessionDataParams;

    if (!pathname || pathname.toLowerCase().startsWith('/mail')) {
        if (windowHeight) {
            // if the reading pane position is undefined or 0, then we will assume it is off
            // so we will fetch more messages

            headers.append(MessageCountHeader, `${getMessageCount(windowHeight)}`);
        }
        if (initialFolders) {
            headers.append(FolderCountHeader, initialFolders);
        }
    } else {
        headers.append(FolderCountHeader, '0');
        headers.append(MessageCountHeader, '0');

        pathname = pathname.toLowerCase();
        if (pathname.startsWith('/calendar') || pathname.startsWith('/hosted/calendar')) {
            // Request the calendar folders as part of the startup data for the Calendar boot performance optimization.
            headers.append(CalendarFoldersHeader, '1');

            // Request the calendar view events based on the view type.
            headers.append(CalculateRangeFromViewHeader, '1');

            // Request the calendar view events to improve TTI in the Calendar module
            headers.append(CalendarViewHeader, getCalendarViewType(pathname));
        }
        if (
            pathname.startsWith('/opx') &&
            OpxRoutesWithCalendarFoldersOptimization.some(route => pathname.includes(route))
        ) {
            // a subset of OPX routes also request calendar folders as a performance optimization
            headers.append(CalendarFoldersHeader, '1');
        }
    }
}
