import { fetchData } from './fetchData';
import { getBrowserHeight } from 'owa-config';
import { getBootFlightsWithAllocationIds } from 'owa-metatags';
import type { SessionData } from 'owa-service/lib/types/SessionData';
import type { HeadersWithoutIterator } from 'owa-service/lib/RequestOptions';
import { addSessionDataHeaders } from './addSessionDataHeaders';
import type { MailboxInfo } from 'owa-client-types';
import type { FetchDataErrorHandler } from 'owa-shared-start-types';
import { canSetHeaderISO8859Safe } from './canSetHeaderISO8859Safe';
import { getInitialFoldersCount } from './utils/getInitialFoldersCount';

// calls to startupdata/sessiondata should be paired with a call to validateCache.  validateCache is executed during post boot but
// should only be invoked if startupdata/sessiondata has been called.
export let isSessionDataFetchedInvoked = false;

/**
 * Calls StartupData and does the necessary processing on the response
 * @param mailboxInfo Identifies the coprincipal account for which Startup data is obtained, this
 *                    will be undefined when StartupData is being called in OWA.
 * @param headers Headers to be passed into request for StartupData
 * @param errorHandler Object that handles errors and telemetry for the StartupData call
 * @param postProcessFunction Function that will be called with the returned SessionData
 * @param processHeadersFunction Function that will be called to process the response headers
 * @param updateDiagnosticsOnReponseFunction Functions that will be called to collect diagnostic data for the response
 * @returns A promise that returns the SessionData obtained from the StartupData call.
 */
export function getAndProcessSessionData(
    mailboxInfo: MailboxInfo | undefined,
    headers: Headers,
    errorHandler: FetchDataErrorHandler,
    postProcessFunction: (json: SessionData) => SessionData,
    processHeadersFunction: (headers: HeadersWithoutIterator) => void,
    updateDiagnosticsOnReponseFunction: (response: Response) => void
): Promise<SessionData> {
    // Send the boot flights via the session data to allow the server to log it
    isSessionDataFetchedInvoked = true;
    const bootFlights = getBootFlightsWithAllocationIds();
    if (bootFlights.length > 0) {
        const bootFlightsValue = bootFlights.join(',');
        if (canSetHeaderISO8859Safe('X-OWA-bootflights', bootFlightsValue)) {
            headers.set('X-OWA-bootflights', bootFlightsValue);
        }
    }

    addSessionDataHeaders(headers, window.location.pathname, {
        windowHeight: getBrowserHeight(),
        initialFolders: getInitialFoldersCount(),
    });

    return fetchData(
        mailboxInfo,
        headers,
        errorHandler,
        postProcessFunction,
        processHeadersFunction,
        updateDiagnosticsOnReponseFunction
    );
}
