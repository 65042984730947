import type { RequestOptions } from './RequestOptions';
import type MailboxRequestOptions from './MailboxRequestOptions';

/**
 * Returns true if the request is explicit logon request, false otherwise.
 */
export default function isExplicitLogonRequest(
    requestOptions: RequestOptions | MailboxRequestOptions | undefined
) {
    return requestOptions?.headers && requestOptions.headers.get('X-OWA-ExplicitLogonUser') != null;
}
