import type { MailboxInfo } from 'owa-client-types';
import {
    getCalendarAccountSources,
    getAllCalendarAccountSources,
} from './getCalendarAccountSources';
import { filterToMailboxInfoForAccountSources } from './filterToMailboxInfoForAccountSources';

/**
 * Get MailboxInfo for the accounts syncing calendar
 * @returns MailboxInfos for the accounts that are syncing calendar
 */
export function getMailboxInfoForCalendarAccounts(): MailboxInfo[] {
    return filterToMailboxInfoForAccountSources(getCalendarAccountSources());
}

/**
 * Get MailboxInfo for all accounts regardless of the boot state
 * @returns MailboxInfos for all accounts
 */
export function getMailboxInfoForAllCalendarAccounts(): MailboxInfo[] {
    return filterToMailboxInfoForAccountSources(getAllCalendarAccountSources());
}
