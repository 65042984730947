import { mutatorAction } from 'satcheljs';
import type {
    AccountSource,
    LoadState,
    AdditionalAccountInfo,
    M365UserMailboxAccountSource,
} from '../store/schema/AccountSourceList';
import setM365UserMailboxAdditionalSources from '../utils/setM365UserMailboxAdditionalSources';

function getGroupSources(account: M365UserMailboxAccountSource): AdditionalAccountInfo {
    return account.groupSources;
}

function updateGroupSources(
    account: M365UserMailboxAccountSource,
    loadState: LoadState,
    sources: AccountSource[]
) {
    account.groupSources.loadState = loadState;
    account.groupSources.sources = sources;
}

export default mutatorAction(
    'SET_GROUP_ACCOUNTS',
    function (coprincipalSourceId: string, groupSources: AdditionalAccountInfo): void {
        setM365UserMailboxAdditionalSources(
            'Group',
            coprincipalSourceId,
            groupSources,
            getGroupSources,
            updateGroupSources
        );
    }
);
