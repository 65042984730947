import { getWindowData } from 'owa-window-data';

const rdecode = /(%[0-9A-Z]{2})+/g;

export function getCookie(key: string): string | undefined {
    return parseCookieValue(getCookieString(), key);
}

export function getCookieString(): string | undefined {
    return getWindowData().cookie;
}

export function parseCookieValue(cookie: string | undefined, key: string): string | undefined {
    const cookies = cookie ? cookie.split('; ') : [];
    for (let ii = 0; ii < cookies.length; ii++) {
        const parts = cookies[ii].split('=');
        const name = parts[0].replace(rdecode, decodeURIComponent);
        if (name == key) {
            let value = parts.slice(1).join('=');
            if (value.charAt(0) === '"') {
                value = value.slice(1, -1);
            }
            return value.replace(rdecode, decodeURIComponent);
        }
    }

    return undefined;
}
