import getBposShellInfoNavBarDataOperation from 'owa-service/lib/operation/getBposShellInfoNavBarDataOperation';
import getBposShellInfoNavBarDataForBookingsOperation from 'owa-service/lib/operation/getBposShellInfoNavBarDataForBookingsOperation';
import type NavBarData from 'owa-service/lib/contract/NavBarData';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import type { MailboxInfo } from 'owa-client-types';
import { logStartUsage, logStartGreyError } from 'owa-analytics-start';
import { isBootFeatureEnabled } from 'owa-metatags';
import type RecursiveReadOnly from 'owa-service/lib/RecursiveReadOnly';

const navBarDataPromiseMap = new Map<string, Promise<RecursiveReadOnly<NavBarData> | null>>();
const navBarDataMap = new Map<string, RecursiveReadOnly<NavBarData> | null>();

export function getBposNavBarDataAsync(
    callingFunctionName: string,
    appName?: string,
    ignoreAuthError?: boolean,
    mailboxInfo?: MailboxInfo
): Promise<RecursiveReadOnly<NavBarData> | null> {
    if (isBootFeatureEnabled('emergencyDisableBpos')) {
        return Promise.resolve(null);
    }
    const key = getNavBarDataMapKey(mailboxInfo);
    if (!navBarDataPromiseMap.has(key)) {
        const operation =
            appName === 'Bookings'
                ? getBposShellInfoNavBarDataForBookingsOperation
                : getBposShellInfoNavBarDataOperation;

        const getOperationPromise = operation({
            authNeededOnUnAuthorized: !ignoreAuthError,
            mailboxInfo,
        })
            .then(data => {
                logStartUsage(
                    'getBposNavBarDataAsync',
                    {
                        owa_1: callingFunctionName,
                        owa_2: 'Fetch succeeded',
                    },
                    { ring: 'Dogfood' }
                );

                navBarDataMap.set(key, data);
                return data;
            })
            .catch(error => {
                // even if the call fails, there is the chance that the navBarData was set by session data
                // so we check for that here and return that value if it exists
                if (navBarDataMap.has(key)) {
                    return navBarDataMap.get(key) ?? null;
                }

                navBarDataPromiseMap.delete(key);

                logStartGreyError(
                    'getBposNavBarDataAsync',
                    error,
                    {
                        owa_1: callingFunctionName,
                        owa_2: 'Fetch failure',
                    },
                    { ring: 'Dogfood' }
                );

                return null;
            });
        navBarDataPromiseMap.set(key, getOperationPromise);
    }

    return navBarDataPromiseMap.get(key) ?? Promise.resolve(null);
}

export function getBposPromise(
    mailboxInfo?: MailboxInfo
): Promise<RecursiveReadOnly<NavBarData> | null> | undefined {
    const key = getNavBarDataMapKey(mailboxInfo);
    return navBarDataPromiseMap.get(key);
}

export type GetBposPromiseFunc = typeof getBposPromise;

export function getBposNavBarData(mailboxInfo?: MailboxInfo): RecursiveReadOnly<NavBarData> | null {
    const key = getNavBarDataMapKey(mailboxInfo);
    return navBarDataMap.get(key) ?? null;
}

// This function is used to set the navBarData if it is not already set. It is intended to be used
// once during startup.
export function trySetBposNavBarData(
    data: RecursiveReadOnly<NavBarData> | null,
    mailboxInfo?: MailboxInfo
) {
    const key = getNavBarDataMapKey(mailboxInfo);

    // we only set the navBarData if it is not already set
    if (navBarDataMap.has(key)) {
        return navBarDataMap.get(key) as NavBarData | null;
    }

    navBarDataMap.set(key, data);
    navBarDataPromiseMap.set(key, Promise.resolve(data));
    return data;
}

function getNavBarDataMapKey(mailboxInfo?: MailboxInfo): string {
    return mailboxInfo ? getIndexerValueForMailboxInfo(mailboxInfo) : 'default_global_account';
}

type PromiseOrValue<T> = Promise<T> | T;
/**
 * NavBarData is returned in session data now. We want to make sure no requests are made to get bpos,
 * unless something fails on the server and we don't get the payload back.
 * We issue multiple different calls to Bpos during the boot sequence. Creating a pending promise,
 * that would either resolve with the data from session data or with the actual call to Bpos can mitigate making unnecessary calls.
 */
export function createBposResolver(mailboxInfo?: MailboxInfo) {
    const key = getNavBarDataMapKey(mailboxInfo);

    let resolve: (result: PromiseOrValue<RecursiveReadOnly<NavBarData> | null>) => void;
    const promise = new Promise<RecursiveReadOnly<NavBarData> | null>(_resolve => {
        resolve = _resolve;
    });
    navBarDataPromiseMap.set(key, promise);

    // return a function that will be called when we have the data from session data
    // this will also resolve the promise that we have previously created so all the callers will get the data
    return (finalizer: () => PromiseOrValue<RecursiveReadOnly<NavBarData> | null>) => {
        navBarDataPromiseMap.delete(key);
        resolve(finalizer());
    };
}
