import type { IExtendedTelemetryItem } from '@microsoft/1ds-analytics-js';
import { getApp } from 'owa-config/lib/bootstrapOptions';

export function addAppToItem(item: IExtendedTelemetryItem) {
    if (!item.data) {
        item.data = {};
    }

    item.data.App = getApp();
}
