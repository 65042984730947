import type { SerializationIssue } from '../types/SerializationTypes';

export const SERIALIZATION_ERROR_NAME = 'SerializationError';
export const SERIALIZATION_ERROR_PREFIX = 'SERIALIZATION ERROR';

export function getSerializationError(
    error?: Error,
    issue?: SerializationIssue | null,
    retried?: boolean,
    retriedFailed?: boolean,
    count?: number
) {
    const type = issue?.type || 'Unkonwn Type';
    const eventName = issue?.eventName ? `datapoint '${issue.eventName}'` : 'object';
    const message = `Unable to serialize ${eventName}. ErrorType: ${type}. See the diagnostics column for details.`;

    return Object.assign(new Error(SERIALIZATION_ERROR_NAME), error, {
        name: SERIALIZATION_ERROR_NAME,
        message,
        stack: getErrorStack(message, error),
        diagnosticInfo: getDiagnosticInfo(issue, retried, retriedFailed, count),
    });
}

function getErrorStack(message: string, error?: Error) {
    const originalError = error?.stack ? `Original Error: \n${error?.stack}.` : '';

    return `${message} ${originalError}`;
}

function getDiagnosticInfo(
    issue?: SerializationIssue | null,
    retried?: boolean,
    retriedFailed?: boolean,
    count?: number
) {
    const retry = retriedFailed ? ' (retried but failed)' : '';
    const autoResolved = retried && !retriedFailed ? 'Yes (do not rely on this)' : `No${retry}`;
    const info = {
        issueType: issue?.type || 'Unkonwn Type',
        firstIssue: {
            key: issue?.key || 'Unkonwn Key',
            hintToSolveIssue: issue?.hint ? issue.hint : '',
        },
        eventName: issue?.eventName || 'No event name found',
        autoResolved,
        numberOfIssuesFound: count || 'Unkonwn Count (is the object too big?)',
    };

    return JSON.stringify(info);
}
