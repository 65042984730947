import { isHostAppFeatureEnabled } from 'owa-hostapp-feature-flags';
import { isHardwareConcurrencySupported } from 'owa-performance/lib/utils/isHardwareConcurrencySupported';

let headersAreEnumerable: boolean | undefined;

export function isDataWorkerPlatformSupported(): boolean {
    return (
        isHostAppFeatureEnabled('dataWorkerAllowed') &&
        webWorkersSupported() &&
        getHeadersAreEnumerable() &&
        isHardwareConcurrencySupported()
    );
}

/**
 *  The RequestOptions object on the graphql context contains a property, headers, which is defined
 *  to be type HeadersWithoutIterator.  The comment in owa-service indicates this is for legacy browsers
 *  that do not support iteration of the headers -- you can only request a header if you know its key.
 *
 *  The problem for the worker/worker-link, with that, is that the Headers object itself is not serializable.
 *  And, to fulfill expecations, we should proxy all headers over.  But, we don't know a priori which headers
 *  will be present.
 *
 *  We can either build up a list of headers that will be proxied or make header enumeration a requirment for
 *  using the data worker.  That requirement is certainly true for monarch.  Older browsers, in web, will still
 *  work, but will be stuck running the graph on the main renderer.
 */
function getHeadersAreEnumerable(): boolean {
    if (headersAreEnumerable === undefined) {
        try {
            const headers = new Headers();
            headers.set('x-test', '1');
            for (const h of headers) {
                h === h;
            }

            headersAreEnumerable = true;
        } catch {
            headersAreEnumerable = false;
        }
    }

    return headersAreEnumerable;
}

function webWorkersSupported(): boolean {
    return typeof Worker !== 'undefined';
}

export function resetStateForTests() {
    headersAreEnumerable = undefined;
}
