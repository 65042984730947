import { defaultAccountKey } from './AccountKey';
import type AccountKey from './AccountKey';

/**
 * This function will validate an account key.
 * @param accountKey The account key to validate
 * @returns false if the account key is empty, undefined, or the default account key
 */
export default function isValidAccountKey(
    accountKey: AccountKey | string | undefined | null
): boolean {
    return (
        accountKey !== undefined &&
        !!accountKey &&
        accountKey !== defaultAccountKey &&
        accountKey !== ''
    );
}
