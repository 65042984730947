import getAccountSourceListStore from '../store/accountSourceListStore';
import type { AccountSourceListData } from '../store/schema/AccountSourceList';

/**
 * This function is used to get the account source list store data
 */
export default function getStoreData(): AccountSourceListData {
    const store = getAccountSourceListStore();
    return {
        globalSettingsSourceId: store.globalSettingsSourceId,
        sources: store.sources,
        removedAccounts: store.removedAccounts,
        licensingIdentities: store.licensingIdentities,
    };
}
