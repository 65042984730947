import { getSessionId } from './getSessionId';

const MAX_CUSTOM_VALUE_LENGTH = 2048;
const emailRegex = new RegExp(/([a-zA-Z0-9+_\.-]+)(@|%40)([\da-zA-Z\.-]+)\.([a-zA-Z\.]{2,6})/);
const userNameInUrlRegex = new RegExp(/((user\/)[a-zA-Z]*\/)/g);
const branchNameRegex = new RegExp(/((\?branch=)[a-zA-Z]*\-)/g);
const searchQueryRegex = new RegExp(/(\?|&)query=([^&]*)/i);

export function scrubForPii(value: string | undefined | null, truncate = true): string | undefined {
    if (value) {
        let originalLength = -1;
        let substringLength = -1;
        try {
            originalLength = value.length;
            // If the value is too long, truncate it to a more reasonable length
            if (truncate && originalLength > MAX_CUSTOM_VALUE_LENGTH) {
                value = value.substring(0, MAX_CUSTOM_VALUE_LENGTH);
            }
            substringLength = value.length;

            // We want to scrub for html elements but we don't want to do a regex
            // as it can result in a performance problem
            if (value.indexOf('</div>') > -1 || value.indexOf('</span>') > -1) {
                value = 'DOM present';
            } else {
                value = scrubEmailPii(value, originalLength, substringLength);
                value = scrubSessionIdPii(value, originalLength, substringLength);
                value = scrubSearchQuery(value, originalLength, substringLength);
            }
        } catch (e) {
            value = scrubPiiErrorValue('Unknown', e, originalLength, substringLength);
        }
    }

    return value ?? undefined;
}

function scrubEmailPii(value: string, originalLength: number, substringLength: number) {
    // Try to replace email or replace complete value with error.
    try {
        // The global regex failed in Firefox version 106. We use the non-global regex instead.
        let emailValue = null;
        while ((emailValue = emailRegex.exec(value)) !== null) {
            value = value.replace(emailValue[0], 'EmailPii');
        }
    } catch (e) {
        value = scrubPiiErrorValue('Email', e, originalLength, substringLength);
    }

    return value;
}

function scrubSessionIdPii(value: string, originalLength: number, substringLength: number) {
    // Try to replace Session or replace complete value with error.
    try {
        value = value.split(getSessionId()).join('ReplacedSessionId');
    } catch (e) {
        value = scrubPiiErrorValue('SessionId', e, originalLength, substringLength);
    }

    return value;
}

function scrubPiiErrorValue(
    type: 'Unknown' | 'Email' | 'SessionId' | 'SearchQuery',
    originalError: Error,
    originalLength: number,
    substringLength: number
): string {
    return `ErrorScrubingPii_${type}. ${
        substringLength !== MAX_CUSTOM_VALUE_LENGTH &&
        `Original Value Length: ${originalLength}. Substring Value Length: ${substringLength}.`
    } Original Error: ${originalError?.message}. Callstack: ${originalError?.stack}`;
}

export function scrubFileNameForPii(value: string | undefined): string | undefined {
    if (value) {
        if (value.indexOf(':\\') > -1) {
            const path = value.split('\\');
            path[2] = 'LocalPathPii';
            value = path.join('\\');
        } else if (
            value.indexOf('/home/') == 0 ||
            value.indexOf('/Users/') == 0 ||
            value.indexOf('/Volumes/') == 0
        ) {
            const path = value.split('/');
            path[2] = 'LocalPathPii';
            value = path.join('/');
        }

        if (value.indexOf('/user/') >= 0) {
            value = value.replace(userNameInUrlRegex, 'user/UserPii/');
        }

        if (value.indexOf('?branch=') >= 0) {
            value = value.replace(branchNameRegex, '?branch=UserPii-');
        }
    }

    return value;
}

export function scrubSearchQuery(
    value: string,
    originalLength: number,
    substringLength: number
): string | undefined {
    try {
        // The global regex failed in Firefox version 106. We use the non-global regex instead.
        let searchQueryValue = null;
        while ((searchQueryValue = searchQueryRegex.exec(value)) !== null) {
            value = value.replace(searchQueryValue[0], searchQueryValue[1] + 'SearchQueryPii');
        }
    } catch (e) {
        value = scrubPiiErrorValue('SearchQuery', e, originalLength, substringLength);
    }

    return value;
}
