import { mutatorAction } from 'satcheljs';
import getAccountBySourceId from '../selectors/getAccountBySourceId';
import { accountRankTypeChecker } from '../store/schema/AccountSourceList';
import { AccountSourceType, type LicensingMailboxInfo } from 'owa-account-source-list-types';
import { getLicensingSourceId } from '../utils/getLicensingSourceId';
import type { MailboxProvider } from 'owa-client-types';

export default mutatorAction('addEasidAsLicensingMailboxInfo', function (sourceId: string): void {
    const accountSource = getAccountBySourceId(sourceId);

    if (accountRankTypeChecker.isCoprincipal(accountSource)) {
        const { mailboxSmtpAddress } = accountSource.mailboxInfo;

        // EASID accounts are always outlook.com
        const mailboxProvider: MailboxProvider = 'Outlook';
        const licenseSourceId = getLicensingSourceId(
            accountSource.sourceId,
            mailboxProvider,
            mailboxSmtpAddress
        );

        const easidMailboxInfo: LicensingMailboxInfo = {
            sourceId: licenseSourceId,
            type: 'UserPrincipalName',
            userIdentity: mailboxSmtpAddress,
            mailboxSmtpAddress,
            mailboxProvider,
            sourceType: AccountSourceType.OutlookDotCom,
            mailboxRank: 'Licensing',
            diagnosticData: 'EasidAsLicensingMailboxInfo',
        };

        accountSource.licensingMailboxInfo = easidMailboxInfo;
    }
});
