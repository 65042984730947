/**
 * The getTimeZoneAndLanguage isolates the access of the window object from the postLanguageTimeZone
 * function. This makes it possible to call the postLanguageTimeZone from the worker thread when
 * starting an additional account.
 * @returns Object containing the timeZone and language from the window
 */
export function getTimeZoneAndLanguage(): {
    timeZone: string;
    language: string;
} {
    let timeZone = 'UTC';

    if (window.Intl && typeof window.Intl === 'object') {
        const options = window.Intl.DateTimeFormat().resolvedOptions();
        if (options?.timeZone) {
            timeZone = options.timeZone;
        }
    }

    const language = window.navigator.language;
    return { timeZone, language };
}
