import type { AccountSource } from '../store/schema/AccountSourceList';
import getAccountAndCoprincipalSourceId from './getAccountAndCoprincipalSourceId';

/**
 * Finds the account associated with the specified sourceId
 * @param sourceId Specifies the sourceId to be looked up
 * @returns The specified account
 */
export default function getAccountBySourceId(sourceId: string): AccountSource | undefined {
    return getAccountAndCoprincipalSourceId(sourceId)?.source;
}
