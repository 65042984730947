import { getWindowData } from 'owa-window-data';

// we will cache the width of the page to avoid layouts
let cachedBrowserWidth: number | undefined;

export function getBrowserWidth(skipCache?: boolean): number {
    /**
     * The reason we are getting min of widths here is because we have seen cases
     * where the window.innerWidth is not ready (and the value is bigger than the actual window width)
     * by the time we want to calculate the available width.
     * So far documentElement.clientWidth is showing correct value, but we are not switching to relying totally
     * on it as this is the way the browser width is being calculated.
     */
    const windowData = getWindowData();
    const doc = windowData.document;
    if (!cachedBrowserWidth || skipCache) {
        /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
         * This is a helper function that will cache the value to reduce the number of forced re-layouts */
        const documentElementClientWidth = doc?.documentElement?.clientWidth;
        /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
         * This is a helper function that will cache the value to reduce the number of forced re-layouts */
        const windowInnerWidth = windowData.innerWidth;
        cachedBrowserWidth =
            windowInnerWidth && documentElementClientWidth
                ? Math.min(windowInnerWidth, documentElementClientWidth)
                : /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
                   * This is a helper function that will cache the value to reduce the number of forced re-layouts */
                  windowInnerWidth || documentElementClientWidth || doc?.body?.clientWidth;
    }
    return cachedBrowserWidth || 0;
}
