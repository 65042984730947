import type { MailboxInfo } from 'owa-client-types';
import getCoprincipalMailboxInfoForMailboxInfo from './getCoprincipalMailboxInfoForMailboxInfo';
import isSameMailboxInfo from './isSameMailboxInfo';

/**
 * Checks whether the supplied MailboxInfo represents the mailbox of a coprincipal account,
 * vs a resource account mailbox like an archive or a group. This can be thought of as checking
 * if the given mailbox is the primary mailbox of a top-level account.
 * @param mailboxInfo Identifies a mailbox
 * @returns true if the mailbox is a coprincipal account's mailbox, false otherwise
 */
export default function isCoprincipalMailbox(mailboxInfo: MailboxInfo): boolean {
    const coprincipalMailboxInfo = getCoprincipalMailboxInfoForMailboxInfo(mailboxInfo);
    if (!coprincipalMailboxInfo) {
        return false;
    }

    return isSameMailboxInfo(mailboxInfo, coprincipalMailboxInfo);
}
