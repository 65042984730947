import { SourceIdPartDelimiter } from './SourceIdPartDelimiter';

/**
 * Returns the coprincipal account based on the sourceId
 * @param sourceId SourceId of a coprincipal account or a resouce account
 * @returns String representing the indexer or undefined if no indexer was found
 */
export function coprincipalAccountIndexerFromSourceId(
    sourceId: string | null | undefined
): string | undefined {
    return !!sourceId ? sourceId.split(SourceIdPartDelimiter)[0] : undefined;
}
