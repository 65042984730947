import { isBootFeatureEnabled } from 'owa-metatags';

let isEnabled: boolean | undefined = undefined;

/**
 * Returns true if we should be using the persistence id portion of the sourceId as
 * the indexer value. In order for this to be true both the acctPersistenceSourceIdV2
 * flight (which causes the persistenceId to be built into the sourceId) and the
 * acctPersistentIdIndexer flight (which tells us we should use the indexer from the sourceId).
 * @returns True if we should use the persistenceId from sourceId for the indexer
 */
export function isPersistenceIdIndexerEnabled(): boolean {
    // we only once to check this once per session so we store it is a local value
    if (isEnabled == undefined) {
        isEnabled = isBootFeatureEnabled('acctPersistentIdIndexerV2');
    }

    return isEnabled;
}
