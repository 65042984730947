import type { AdditionalSessionPropsForMailboxInfo } from 'owa-account-source-list-types';
import setCoprincipalAdditionalMailboxProps from '../actions/setCoprincipalAdditionalMailboxProps';
import { interceptAndAlertOnMailboxInfoChanges } from './interceptAndAlertOnMailboxInfoChanges';

/**
 * Sets additional properties into the MailboxInfo based on the passed in session settings
 * @param account Account for which properties are to be set
 * @param sessionSettings Session settings which will contain the properties for the account
 */
export function setAdditionalMailboxPropsFromSessionSettings(
    sourceId: string,
    sessionSettingsProps: AdditionalSessionPropsForMailboxInfo | undefined
) {
    setCoprincipalAdditionalMailboxProps(sourceId, {
        auxiliaryMailboxGuid: sessionSettingsProps?.MailboxGuid,
        externalDirectoryObjectId: sessionSettingsProps?.ExternalDirectoryUserGuid,
        // The UserPuid is the puid of user identity used for authentication. If it is an explicit
        // logon, or in other words connecting to another mailbox, we should not set the UserPuid
        // because it is not the Puid of the mailbox being connected to by the account.
        PUID: sessionSettingsProps?.IsExplicitLogon ? undefined : sessionSettingsProps?.UserPuid,
        tenantId: sessionSettingsProps?.ExternalDirectoryTenantGuid,
    });
    interceptAndAlertOnMailboxInfoChanges(sourceId);
}
