import { action } from 'satcheljs';

import type { AccountSource } from '../store/schema/AccountSourceList';
import type { BootState } from '../store/schema/BootState';

export default action(
    'ON_COPRINCIPAL_BOOTSTATE_CHANGED',
    (updatedAccount: AccountSource, newBootState: BootState) => ({
        updatedAccount,
        newBootState,
    })
);
