import type { BootResult, ErrorSource } from 'owa-shared-start-types';
import { type ErrorRetryStrategy } from './interfaces/ErrorRetryStrategy';
import type RetryStrategy from './interfaces/RetryStrategy';
import { getQueryStringParameter } from 'owa-querystring';

export function getBootRetryStrategy(
    bootResult: BootResult,
    source: ErrorSource | undefined
): RetryStrategy | null {
    const currentRetryStrategy = getQueryStringParameter('bO');

    // we shouldn't try if we already have retried
    if (currentRetryStrategy !== undefined && currentRetryStrategy !== '4') {
        return null;
    }

    if (bootResult === 'ExpiredBuild' || bootResult === 'PoisonedBuild') {
        return currentRetryStrategy === '4' ? null : { strategy: '4' };
    }

    if (bootResult === 'fail') {
        return {
            strategy: source == 'InitLoc' ? '2' : '1',
        };
    }

    return null;
}
