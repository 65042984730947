import { hasQueryStringParameter } from 'owa-querystring';

const bootTimingToPasMetric: Record<string, string> = {
    sd: 'Startup Data (sd)',
    offsd: 'Offline Startup Data (offsd)',
    rbsp: 'Run Before Start Promise (rbsp)',
    mjs: 'Bootstrap Javascript Load (mjs)',
    sp: 'Session Promises (sp)',
    ren: 'Render (ren)',
    plt: 'Page Load Time (plt)',
    mi: 'Mail Index (mi)',
    in: 'Index (in)',
    CalendarAppLoadSucceeded: 'Calendar App Load Succeeded',
    // Places Related Timings
    PlacesTenantSettingsLoaded: 'Places Tenant Settings Loaded',
    PlacesAppLoadSucceeded: 'Places App Load Succeeded',
};

const isPerformanceSupported = self?.performance;

function pasMetricsEnabled(): boolean {
    return hasQueryStringParameter('pas');
}

/**
 * Add a metric to the window.__pageTimings for the PAS tool to read.
 * More information on the PAS tool can be found at aka.ms/PAS
 * @param name Name of the metric
 */
export function addPASKeyMetric(name: string, customDuration?: number): void {
    if ((!customDuration && !isPerformanceSupported) || !pasMetricsEnabled()) {
        // If we don't have the duration and performance is not supported, we can't add the metric
        // We also skip the metric if we don't have the pas metrics enabled
        return;
    }

    const metricName = bootTimingToPasMetric[name];
    // If it's not a known metric name don't add as a page timing
    if (!metricName) {
        return;
    }
    const duration = customDuration ?? performance.now();
    const timings = self._pageTimings ?? (self._pageTimings = {});

    if (timings[metricName] === undefined) {
        // Round the duration to limit storage bloat on the PAS service
        timings[metricName] = Math.round(duration);
    }
}
