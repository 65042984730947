import type { Capability } from 'owa-capabilities';
import type { MailboxInfo } from 'owa-client-types';
import { isHosted } from 'owa-config';

/**
 * This determines whether the app instance should fetch bpos data at boot.
 * We do NOT support bpos nav bar data for hosted scenarios - there isn't the need to render boot data that depends on it.
 * Please note - technically we could check for the user being a BPOS user, but at the time we check this (early in boot), we don't have the user's mailbox info yet.
 */
export const fetchBposDataOnBootCapability: Capability = {
    isEnabled: (_mailboxInfo?: MailboxInfo) => {
        return true;
    },
    isSupported: (_mailboxInfo?: MailboxInfo) => {
        return !isHosted();
    },
};
