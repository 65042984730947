import type { AccountSource } from '../store/schema/AccountSourceList';
import { accountSourceDataTypeChecker } from '../store/schema/AccountSourceList';

export function updateMailboxInfo(accountSource: AccountSource): AccountSource {
    if (accountSourceDataTypeChecker.isM365Mailbox(accountSource)) {
        return {
            ...accountSource,
            mailboxInfo: {
                ...accountSource.mailboxInfo,
                sourceId: accountSource.sourceId,
                mailboxRank: accountSource.mailboxRank,
            },
        };
    }

    return accountSource;
}
