import { LazyBootModule, LazyAction } from 'owa-bundling-light';

const lazyModule = new LazyBootModule(
    () => import(/* webpackChunkName: "AppBootMsal" */ './lazyFunctions')
);

export const lazyCreateMsalInstanceProxy = new LazyAction(
    lazyModule,
    m => m.lazyCreateMsalInstance.importAndExecute
);

export const lazyGetAuthTokenMsalProxy = new LazyAction(
    lazyModule,
    m => m.lazyGetAuthTokenMsal.importAndExecute
);

export const lazyGetAnchorMailboxProxy = new LazyAction(
    lazyModule,
    m => m.lazyGetAnchorMailbox.importAndExecute
);

export const lazyFetchAccessTokenFromMsalProxy = new LazyAction(
    lazyModule,
    m => m.lazyFetchAccessTokenFromMsal.importAndExecute
);

export const lazyAcquireAccessTokenMsalProxy = new LazyAction(
    lazyModule,
    m => m.lazyAcquireAccessTokenMsal.importAndExecute
);

export const lazyExecuteMsalNestedAppAuthRequestProxy = new LazyAction(
    lazyModule,
    m => m.lazyExecuteMsalNestedAppAuthRequest.importAndExecute
);

export const lazyOnActivityTimeoutErrorForMSALProxy = new LazyAction(
    lazyModule,
    m => m.lazyOnActivityTimeoutErrorForMSAL.importAndExecute
);
