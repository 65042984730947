import { getWrappedWorker } from '../util/getWrappedWorker';
import { buildWindowData } from 'owa-worker-shared';
import { logStartGreyError } from 'owa-analytics-start';

export function precompileSchema() {
    const worker = getWrappedWorker();
    if (worker?.client) {
        const windowData = buildWindowData(false /* includeLocalStorage */);
        worker.client.precompileSchema(windowData).catch(precompileError => {
            logStartGreyError('dataworker_precompile_err', precompileError);
        });
    }
}
