import { mutatorAction } from 'satcheljs';
import type {
    AccountSource,
    AdditionalAccountInfo,
    M365TeamsMailboxAccountSource,
    M365UserMailboxAccountSource,
    LoadState,
} from '../store/schema/AccountSourceList';
import setM365UserMailboxAdditionalSources from '../utils/setM365UserMailboxAdditionalSources';

function getTeamsSources(account: M365UserMailboxAccountSource): AdditionalAccountInfo {
    return account.teamsSources;
}

function updateTeamsSources(
    account: M365UserMailboxAccountSource,
    loadState: LoadState,
    sources: AccountSource[]
) {
    account.teamsSources.loadState = loadState;
    account.teamsSources.sources = sources;
}

export default mutatorAction(
    'SET_TEAMS_ACCOUNTS',
    function (
        coprincipalSourceId: string,
        loadState: LoadState,
        sources: M365TeamsMailboxAccountSource[] | undefined
    ): void {
        setM365UserMailboxAdditionalSources(
            'Teams',
            coprincipalSourceId,
            {
                coprincipalSourceId,
                loadState,
                sources: sources ? sources : [],
            },
            getTeamsSources,
            updateTeamsSources
        );
    }
);
