import { LazyBootModule, LazyAction, LazyImport } from 'owa-bundling-light';

const lazyModule = new LazyBootModule(
    () => import(/* webpackChunkName: "AppBootOutlookHeader" */ './lazyFunctions')
);

export const lazyToggleMaximizeProxy = new LazyAction(
    lazyModule,
    m => m.lazyToggleMaximize.importAndExecute
);

export const lazyCaptionButtonActionProxy = new LazyAction(
    lazyModule,
    m => m.lazyCaptionButtonAction.importAndExecute
);

export const lazyOneOutlookSuiteHeaderProxy = new LazyImport(
    lazyModule,
    m => m.lazyOneOutlookSuiteHeader.import
);
