import { getOsInfoWithHints, type OperatingSystem } from './getOsInfo';

export default async function getOsInfoFromClientHints() {
    const osInfo = await getOsInfoWithHints();
    let osVersion = '';

    if (osInfo.os == 'Windows') {
        if (osInfo.osVersion == '11') {
            osVersion = 'Windows 11 or later';
        } else if (osInfo.osVersion == '10') {
            osVersion = 'Windows 10';
        } else {
            osVersion = 'Before Windows 10';
        }
    } else {
        osVersion = 'Not running on Windows';
    }

    return osVersion;
}
